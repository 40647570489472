/* ###### 5.3 Datetimepicker ###### */

/* Amaze UI Datetimepicker */
.datetimepicker {
  box-shadow: none;
  border-radius: 0;
  border-width: 1px;
  border-color: $gray-300;
  min-width: 270px;
  padding: 0 10px 10px;

  &::before { display: none; }

  table {
    width: 100%;

    th.prev,
    th.next,
    th.switch {
      background-color: #fff;
      color: $body-color;
    }

    th.prev,
    th.next {
      position: relative;

      span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &::before {
          font-family: 'Ionicons';
          position: absolute;
          top: 50%;
          font-size: 16px;
          color: $gray-900;
          line-height: 0;
        }
      }

      @include hover-focus() {
        background-color: #fff;
        span::before { color: $blue; }
      }
    }

    th.prev span::before {
      content: '\f3cf';
      left: 0;
    }

    th.next span::before {
      content: '\f3d1';
      right: 0;
    }

    th.switch {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 1px;
      color: $gray-900;

      @include hover-focus() {
        background-color: #fff;
        color: $blue;
      }
    }

    td {
      font-size: $font-size-base;
      text-align: center;

      &.old,
      &.new { color: $gray-500; }

      &.active {
        &,
        &:hover,
        &:focus {
          background-color: $blue;
          color: #fff;
        }
      }
    }

    span.active {
      &,
      &:hover,
      &:focus {
        background-color: $blue;
        color: #fff;
      }
    }
  }

  .datetimepicker-days table {
    thead tr:first-child th { height: $az-height-base; }
    thead tr:last-child th {
      height: 25px;
      text-transform: uppercase;
      color: $gray-700;
      font-weight: 700;
      font-size: 11px;
    }
  }
}

/* jQuery Simple Datetimepicker */

.az-datetimepicker {
  box-shadow: none;
  border-radius: 0;
  border-color: $gray-300;
  margin-top: 2px;

  > .datepicker_header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .icon-home { display: none; }

    a:nth-child(2),
    a:last-child {
      display: block;
      position: relative;
      text-indent: -999999px;

      &::before {
        font-family: 'Ionicons';
        position: absolute;
        top: 50%;
        line-height: 0;
        text-indent: 0;
        font-size: 13px;
      }
    }

    a:nth-child(2)::before {
      content: '\f3cf';
      left: 0;
    }

    a:last-child::before {
      content: '\f3d1';
      right: 0;
    }

    span {
      font-family: $font-family-base;
      text-transform: uppercase;
    }
  }

  > .datepicker_inner_container {
    border-radius: 0;
    box-shadow: none;
    border-width: 0;
    display: flex;
    height: 225px;
    margin: 0;
    background-color: #fff;

    &::after { display: none; }

    > .datepicker_calendar {
      width: 230px;
      border-width: 0;
      border-radius: 0;
      font-family: $font-family-base;
      padding: 5px;
      float: none;
      margin: 0;

      > table {
        width: 100%;
        height: 100%;
      }

      th {
        font-size: 11px;
        text-transform: uppercase;
        color: $gray-900;
        font-weight: 700;
        text-align: center;
        padding: 8px 5px;
      }

      td {
        font-size: $font-size-base;
        font-weight: 400;
        text-align: center;
        padding: 5px;

        &.day_another_month { color: $gray-500; }
        &.hover {
          background-color: $gray-200;
          cursor: pointer;
        }

        &.active {
          background-color: $blue;
          color: #fff;
        }
      }
    }

    > .datepicker_timelist {
      float: none;
      width: 70px;
      height: 100% !important;
      border-left: 1px solid $gray-400;

      > div.timelist_item {
        padding: 5px 8px;
        margin: 0 0 0 1px;

        @include hover-focus() {
          background-color: $gray-200;
        }

        &.active {
          background-color: $blue;
          color: #fff;
        }
      }
    }
  }
}


/* Picker JS */

.picker-title {
  font-size: 15px;
  font-weight: 500;
}

.picker-cell__header {
  text-transform: uppercase;
  font-size: $font-size-base;
  font-weight: 700;
  letter-spacing: .4px;
}

.picker-item {
  font-family: 'Archivo', $font-family-base;
}

.picker-picked {
  font-weight: 500;
  color: $blue;
}
