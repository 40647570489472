.modal {
    @include modalbox;
    @include activetoggle;
}

.demo-static-toast-success {
    width: 300px;
    top: 73px;
    .toast-header{
        background: $az-active-status;
        padding: 8px 10px;
        border-radius: 4px 4px 0px 0px;
        h6 { 
            color: $az-white;
            font-size: $az-font-16;
            i{
                font-size: $az-font-18;
            }
        }
    }
    .toast-body {
        background: $az-active-status-bg;
    }
}

.demo-static-toast-danger {
    width: 300px;
    top: 73px;
    .toast-header{
        background: $az-inactive-status-bg;
        padding: 8px 10px;
        border-radius: 4px 4px 0px 0px;
        h6 { 
            color: $az-white;
            font-size: $az-font-16;
            i{
                font-size: $az-font-18;
            }
        }
    }
    .toast-body {
        background: $az-inactive-status-bg;
    }
}

.infomodal {
    .modal-dialog {
        max-width: 651px;
     .modal-content {
        .media-body {
          
         }
     }
    }
  }

.propertylistmodal {
   
    .modal-dialog {
        max-width: 900px;
        
        @media (max-width:1366px) {
            
            max-width: 800px;
        }
        @media (max-width:1280px) {

            max-width: 700px;

        }
        @media (max-width:1199px) {

            max-width: 700px;
            
        }
        @media (max-width:991px) {

            max-width: 600px;
            
        }
       
        .modal-content {
           
            .modal-body {
                padding: 0 !important;
                .carousel {
                    position: relative;
                    .carousel-inner { 
                        border-radius: 8px;
                        background: none !important;
                        .carousel-item{
                            img{ 
                                margin-bottom: 0;
                                opacity: 1;
                                border-radius: 8px 0 0;
                            }

                            .carousel-caption {
                                position: relative;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                padding: 18px 15px;
                                p{
                                    font-size: $az-font-16;
                                    letter-spacing: 0.32px;
                                    font-family: $az-heading-two;
                                    color: $az-light-gray;
                                    font-weight: normal;
                                    line-height: 20px;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .carousel-control-prev{
                        left: -9%;
                        display: inline-table;
                        width: auto;
                        height: auto;
                        top: 50%;
                        transform: translate(0, -50%);
                        .carousel-control-prev-icon {
                            background-image:url(../../images/left-slide-arrow.svg);
                            width: 35px;
                            height: 35px;
                        }

                        @media (max-width:767px) {
                              left: 3%;
                        }

                    }
                    .carousel-control-next {
                        right: -9%;
                        display: inline-table;
                        width: auto;
                        height: auto;
                        top: 50%;
                        transform: translate(0, -50%);
                        .carousel-control-next-icon{
                            background-image:url(../../images/right-slide-arrow.svg);
                            width: 35px;
                            height: 35px;
                        }

                        @media (max-width:767px) {
                            right: 3%;
                      }

                    }
                    .carousel-indicators {
                        display: none;
                    }
                }
            }
        }
    }
}