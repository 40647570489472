/* ###### Global Fonts ###### */

// @mixin headingBold {
//         font-family: $az-heading-one;
//         src: url(../fonts/OrpheusPro-Bold.woff);
// }

@mixin headingMainBold {
        font-family: $az-heading-one;
        // src: url(../fonts/Quattrocento-Bold.woff);
        src: url(../fonts/Roboto-Bold.woff);
}

@mixin headingMainRg {
        font-family: $az-heading-one-rg;
        // src: url(../fonts/Quattrocento.woff);
        src: url(../fonts/Roboto-Regular.woff);
}

@mixin headingRg {
        font-family: $az-heading-two;
        src: url(../fonts/Oswald-Regular.woff);
 }


@mixin headingMd {
        font-family: $az-heading-para;
        src: url(../fonts/Oswald-Medium.woff);
}

@mixin headingRgbd {
        font-family: $az-heading-para-light;
        src: url(../fonts/Oswald-Bold.woff);
}

@mixin headingRglight {
        font-family: $az-heading-para-xs-light;
        src: url(../fonts/Oswald-Light.woff);
}

@mixin headingthreeBold {
        font-family: $az-heading-three-bd;
        src: url(../fonts/PlayfairDisplay-Bold.woff);
}

@mixin headingthreeMd {
        font-family: $az-heading-three-md;
        src: url(../fonts/PlayfairDisplay-Medium.woff);
}

@mixin headingthreeRg {
        font-family: $az-heading-three-rg;
        src: url(../fonts/PlayfairDisplay-Regular.woff);
}