
/* ###### 5.7 Parsley ###### */

input.parsley-error,
textarea.parsley-error {
  @extend .is-invalid;

  &:focus { box-shadow: none !important; }
}

input.parsley-success,
textarea.parsley-success {
  @extend .is-valid;
  &:focus { box-shadow: none !important; }
}

.parsley-checkbox {
  &.parsley-error {
    display: inline-block;
    padding: 10px;
    border: 1px solid theme-color('danger');
  }

  &.parsley-success {
    display: inline-block;
    padding: 10px;
    border: 1px solid theme-color('success');
  }
}

.parsley-select {
  &.parsley-error {
    @extend .has-danger;
  }

  &.parsley-success {
    @extend .has-success;
  }
}


.parsley-errors-list {
  color: theme-color('danger');
  list-style: none;
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;

  li { margin: 5px 0 0; }
}

/***** CUSTOM STYLED ERROR MESSAGE *****/
.parsley-style-1 {
  .parsley-input {
    &.parsley-error {
      .form-control {
        background-color: lighten(theme-color('danger'), 45%);
        border-color: theme-color('danger');
        border-bottom-width: 0;
        @include border-bottom-radius(0);
      }
    }

    &.parsley-success {
      .form-control { border-color: theme-color('success'); }
    }
  }

  .parsley-checkbox {
    &.parsley-error {
      border: 1px solid theme-color('danger');
      background-color: lighten(theme-color('danger'), 45%);
      @include border-top-radius(2px);
    }
  }

  .parsley-select {
    &.parsley-error {
      .select2-container--default .select2-selection--single {
        background-color: lighten(theme-color('danger'), 45%);
        @include border-bottom-radius(0);
      }
    }
  }

  .parsley-errors-list.filled {
    background-color: theme-color('danger');
    color: #fff;
    padding: 7px 10px;
    @include border-bottom-radius(2px);

    li:first-child { margin-top: 0; }
  }
}
