/* ###### 4.8 Nav   ###### */

.az-nav {
  @include media-breakpoint-up(md) { align-items: center; }

  .nav-link {
    display: block;
    color: $gray-700;
    padding: 0;
    position: relative;
    line-height: normal;

    @include hover-focus() {
      color: $gray-900;
    }

    + .nav-link {
        padding-top: 12px;
        margin-top: 12px;
        border-top: 1px dotted $gray-500;

        @include media-breakpoint-up(md) {
          padding-top: 0;
          margin-top: 0;
          border-top: 0;
          padding-left: 15px;
          margin-left: 15px;
          border-left: 1px dotted $gray-500;
        }
    }

    &.active { color: $az-color-primary; }
  }
}

.az-nav-column {
  flex-direction: column;

  .nav-link {
    padding: 0;
    height: $az-height-base;
    color: $gray-900;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      font-size: 24px;
      line-height: 0;
      width: 24px;
      margin-right: 12px;
      text-align: center;
      @include transition($transition-base);

      &:not([class*=' tx-']) { color: $gray-600; }

      &.typcn { line-height: .9; }
    }

    span {
      font-weight: 400;
      font-size: 11px;
      color: $gray-500;
      margin-left: auto;
    }

    &:hover,
    &:focus {
      color: $gray-900;
      i:not([class*=' tx-']) { color: $gray-900; }
    }

    &.active {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        bottom: 6px;
        left: -28px;
        width: 3px;
        background-color: $indigo;
        @include border-radius();
        display: none;
      }

      &,
      &:hover,
      &:focus {
        color: $indigo;
        i { color: $indigo; }
      }
    }

    + .nav-link { border-top: 1px dotted $gray-400; }
  }

  &.sm {
    .nav-link {
      font-size: $font-size-base;
      font-weight: 400;
      padding: 10px 0;

      i { font-size: 21px; }
    }
  }
}

.az-nav-dark {
  .nav-link {
    color: rgba(#fff, .7);
    @include hover-focus() { color: #fff; }

    + .nav-link { border-color: $gray-700; }
    &.active { color: $az-color-primary; }
  }
}

.az-nav-colored-bg {
  .nav-link {
    + .nav-link { border-color: rgba(#fff, .4); }
    &.active { color: #fff; }
  }
}

.az-nav-line {
  position: relative;

  .nav-link {
    padding: 0;
    color: $gray-700;
    position: relative;

    @include hover-focus() { color: $gray-900; }

    + .nav-link {
      margin-top: 15px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 30px;
      }
    }

    &.active {
      color: $gray-900;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -30px;
        width: 2px;
        background-color: $gray-900;

        @include media-breakpoint-up(md) {
          top: auto;
          bottom: -30px;
          left: 0;
          right: 0;
          height: 2px;
          width: auto;
        }
      }
    }
  }

  &.az-nav-dark {
    .nav-link {
      color: rgba(#fff, .7);
      @include hover-focus() { color: #fff; }

      &.active {
        color: #fff;
        &::before { background-color: #fff; }
      }
    }
  }
}

.az-nav-tabs {
  padding: 15px 15px 0;
  background-color: $gray-300;

  .lSSlideOuter {
    position: relative;
    padding-left: 32px;
    padding-right: 35px;
  }

  .lSSlideWrapper { overflow: visible; }

  .lSAction {
    > a {
      display: block;
      height: 40px;
      top: 16px;
      opacity: 1;
      background-color: $gray-400;
      background-image: none;

      @include hover-focus() { background-color: darken($gray-400, 5%); }

      &::before {
        font-family: 'Ionicons';
        font-size: 18px;
        position: absolute;
        top: -4px;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.lSPrev {
        left: -32px;
        &::before { content: '\f3cf'; }
      }

      &.lSNext {
        right: -35px;
        &::before { content: '\f3d1'; }
      }

      &.disabled {
        background-color: $gray-200;
        color: #fff;
      }
    }
  }

  .lightSlider { display: flex; }

  .tab-item {
    flex-shrink: 0;
    display: block;
    float: none;
    min-width: 150px;
  }

  .tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    line-height: 1.428;
    color: $gray-700;
    white-space: nowrap;
    background-color: $gray-200;

    @include hover-focus() { background-color: $gray-100; }

    &.active {
      background-color: #fff;
      color: $gray-900;
      font-weight: 500;
    }
  }
}

.az-tab-pane {
  display: none;

  &.active { display: block; }
}

.adminHeadertop {
                  background-color: transparent;
                  // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
                  // -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
                  // -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
                  // -o-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        .naBar { 
                justify-content: space-between;
              }
         .mobView {

          @media (max-width:991px) {
            // display: flex;
            max-width: 66.66%;
            width: 66.66%;
            order: 2;
            // justify-content: center;
            padding: 0;
          }

           @media (max-width:767px) {
            display: flex;
            max-width: 33.33%;
            width: 33.33%;
            order: 2;
            justify-content: center;
            padding: 0;
          }
         .navbar-brand {

          @media (max-width:991px) {
            margin: 0;
          }
          @media (max-width:767px) {
                margin: 0;
          }
        }
        }
         .leftNavbar{
                width: 100%;
                  #basic-navbar-nav{
                    a {
                      position: relative;
                      padding: 0px 30px;
                      display: block;
                      height: 100%;
                       @include navFont;
                       &:active{
                        color: $az-black !important;
                       }
                      &:hover{
                        color: $az-black !important;
                        &:after { position: absolute; content: ''; bottom: -22px; border-bottom: 2px solid $az-black !important; width: 95%;  left: 0;}
                      }
                      &:first-child { padding: 0; margin-right: 30px;

                        @media (max-width:991px) { 
                          padding: 5px 30px;
                          &:hover{
                            &:after { display: none;}
                          }
                        }
                        @media (max-width:767px) {
                          padding: 5px 30px !important;
                         }
                      
                      }
                    
                      @media (max-width:991px) { 
                        padding: 5px 30px;
                        &:hover{
                          &:after { display: none;}
                        }
                      }
                      @media (max-width:767px) {
                        padding: 5px 30px !important;
                       }
                    
                  }

                  .active{
                     color: $az-black !important;
                     position: relative;
                    &:active{
                      color: $az-black !important;
                     }
                    &:hover{
                    color: $az-black !important;
                    &:after { position: absolute; content: ''; bottom: -22px; border-bottom: 2px solid $az-black; width: 95%;  left: 0;
                    
                      @media (max-width:991px) { 
                        display: none;
                       }
                       @media (max-width:767px) {
                         display: none;
                        }
                    }

                  } 
                  &:after { position: absolute; content: ''; bottom: -22px; border-bottom: 2px solid $az-black; width: 95%;  left: 0;

                    @media (max-width:991px) { 
                     display: none;
                    }
                    @media (max-width:767px) {
                      display: none;
                     }
                  }
                }
                  
              }
              
           @media (max-width:991px) {
                  max-width: inherit;
                  width: auto;
                  order: 1;
                  display: block;
                  flex: inherit;
                  justify-content: flex-start;
                  padding: 0;
                  margin: 0;
                  position: relative;
                }
            @media (max-width:767px) {
                max-width: none;
                width: none;
                order: 1;
                }
                button {

                  &:active { outline: none; border: none;}
                  &:hover { outline: none; border: none;}
                  &:focus { outline: none; border: none;}
                }
                .collapse {
                  transition: inherit;
                 
                  @media (max-width:991px) { 
                    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.08);
                    position: absolute;
                    left: -15px;
                    top: 54px;
                    background: #fff;
                    padding: 15px;
                    z-index: 1;

                  }

                  @media (max-width:767px) { 
                    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.08);
                    position: absolute;
                    left: -15px;
                    top: 55px;
                    background: #fff;
                    padding: 15px;
                    z-index: 1;
                  }

                }
                .collapsing {
                  height: auto;
                  position: absolute;
                  left: -15px;
                  top: 60px;
                  background: #fff;
                  padding: 15px;
                  transition: inherit;
                  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.08);
                }
            }
               
         .rightNavbar{
            .dropdown {
              figure{
                  margin:0;
                    display: block;
                    width: 42px;
                    height: 42px;
                    border: 2px solid $gf-primary-color;
                    border-radius: 50%;
                    @media (max-width:991px) {
                      display: none;
                    }
                    @media (max-width:767px) {
                        display: none;
                      }
                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100%;
                      }
                  }
               .profileContent { 
                    display: block;

                    b{ @include p-normal; color:$gf-primary-color; letter-spacing: 1.2px;}
                    i { font-style: normal; @include light-gray-font; letter-spacing: .5px;}
                   
                    @media (max-width:991px) {
                      display: none !important;
                    }

                    @media (max-width:767px) {
                      display: none !important;
                    }
              }
              .dropdown-menu { 
                right: 0;
                transform: translate(0px, 60px) !important;
                left: auto;
                top: -3px;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
                border: none;
                border-radius: 0px 0px 2px 2px;

                @media (max-width:991px) {
                  width: 200px;
                  top: -3px;
                }
                @media (max-width:767px) {
                  width: 200px;
                  top: -6px;
                }

                a {

                  font-family: $az-heading-two;
                  color: $az-black;

                  &:first-child {
                         display: none !important;
                  figure{
                        display: none !important;

                        @media (max-width:991px) {
                          display: block !important;
                          width: 42px;
                          height: 42px;
                          border: 3px solid $az-purple;
                          border-radius: 50%;
                          img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100%;
                          }

                        }

                        @media (max-width:767px) {
                          display: block !important;
                          width: 42px;
                          height: 42px;
                          border: 3px solid $az-purple;
                          border-radius: 50%;
                          img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100%;
                          }
                        }
                    }

                    .profileContent { 
                      display: none !important;

                      @media (max-width:991px) {
                        display: flex !important;

                      }

                      @media (max-width:767px) {
                        display: flex !important;

                      }

                    }

                    @media (max-width:991px) {
                      display: flex !important;

                    }

                    @media (max-width:767px) {
                      display: flex !important;

                    }
                  }
                  &:hover{
                    background-color: $az-light-gray;
                    color: $az-black;
                  }
                  &:active {
                    background-color: $az-light-gray;
                    color: $az-black;
                  }
                  &:focus {
                    background-color: $az-light-gray;
                    color: $az-black;
                  }
                  
                  &:last-child {
                    &:hover {
                      border-radius: 0px 0px 2px 2px;
                    }
                   
                  }
                  }
               }
            }
            @media (max-width:991px) {
              width: auto;
              max-width: initial;
              order: 3;
              flex: 0 0 0;
              padding: 0;
              }
            @media (max-width:767px) {
              width: 33.33%;
              max-width: 33.33%;
              order: 3;
              }
         }

         .dropdown-toggle {
          &::after { display: none;}
         }
         .navbar {
          padding: 2px 0px;

          @media (max-width:991px) {
            padding: 2px 16px;
          }
          @media (max-width:767px) {
            padding: 2px 16px;
          }
         }
}

.spinnerLoader {
  @include spinnerloaderonpage;
}

.backpage { @include breadcrumbs;}

.az-header-menu {
  .dropdown {
    .az-menu-sub {
        background: $az-black;
        left: -24px !important;
        top: 0 !important;
        width: 219px;
        

        .nav-link {
          border: none !important;
          font-family: $az-heading-para;
          font-size: $az-font-14;
          text-transform: uppercase;
          padding-bottom: 5px;
          color: $gf-input-label-color;
          &:hover {
            color: $az-white;
          }

          &:last-child {
            padding-bottom: 0;
          }

          i {
            margin-right: 15px;
          }
       
        }
        .nav-link.active {

          color: $az-white;
        }
       
    }
    .dropdown-toggle {
      position: relative;
      &::after {
        position: absolute;
        right: 0;
      }
    }
  }
}


