/* ###### 7.11 Error  ###### */

.az-error-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  min-height: 100vh;

  h1 {
    line-height: 1;
    font-size: 150px;
    font-weight: 700;
    color: $gray-900;
  }

  h2 {
    font-weight: 500;
    color: $gray-800;
    letter-spacing: -.5px;
    margin-bottom: 15px;
  }

  h6 {
    margin-bottom: 40px;
    font-size: 14px;
  }

  .btn {
    border-width: 2px;
    font-weight: 700;
  }
}
