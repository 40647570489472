/* ###### 3.5 Buttons ###### */

.btn {
  border-width: 0;
  line-height: 1.538;
  padding: 9px 20px;
  @include border-radius(0);
  transition: none;
  min-height: $az-height-base;

  &:active,
  &:focus { box-shadow: none; }
}

.btn-light { @include button-variant($gray-300, darken($gray-300, 5%)); }
.btn-indigo { @include button-variant($indigo, darken($indigo, 5%)); }
.btn-purple { @include button-variant($purple, darken($purple, 5%)); }
.btn-pink { @include button-variant($pink, darken($pink, 5%)); }

.btn-gray-500 { @include button-variant($gray-500, darken($gray-500, 5%)); }
.btn-gray-700 { @include button-variant($gray-700, darken($gray-700, 5%)); }

[class*=btn-outline-] {
  padding: 0.4rem 1rem;
  line-height: 1.657;
  border-width: 1px;
  background-color: transparent;

  @include hover-focus() { color: #fff; }
}

.btn-outline-indigo {
  border-color: $indigo;
  color: $indigo;

  @include hover-focus() { background-color: $indigo; }
}

.btn-outline-light {
  border-color: $gray-500;
  color: $gray-500;

  @include hover-focus() { background-color: $gray-500; }
}
