
$az-height-base:                  36px;
$az-height-header:                64px;
$az-height-header-mobile:         64px;
$az-height-navbar:                48px;
$az-height-navbar-compact:        42px;
$az-height-footer:                40px;
$az-height-content:               calc(100vh - #{$az-height-header + $az-height-footer});

$az-width-sidebar:                220px;
$az-width-iconbar-aside:          230px;

$az-border-color:                 rgba($gray-900, .2);
$az-border-base:                  #dee2ef;

$ckbox-w-size:                    18px;
$ckbox-h-size:                    18px;
$rdiobox-size:                    18px;
$checkbox-border:                 #6e6e6e;

$az-color-primary:                $indigo;
$az-color-secondary:              $blue;
$az-color-headline:               #031b4e;
$az-color-text:                   #4e5f83;

$font-size-sm:                    13px;
$font-size-md:                    $font-size-base + 0.0625;
$font-weight:                     500;

$input-btn-focus-color:           rgba($az-color-primary, .25);
$az-checkbox-height-base:         15px;
$az-black-color:                  rgba(0,0,0,0.55);
$az-light-white:                  #f7f7f7;
$az-profile-border:               #e8e8e8;


$az-purple:                       #321b63;
$az-button-outline-border-color:  #4a4a4a;
$az-btn-green:                    #2c5046;
$az-active-status:                #009d24;
$az-inactive-status:              #cc0000;
// $az-active-status-bg:             rgba(46, 125, 50,0.2);
$az-inactive-status-bg:           rgba(204,0,0,0.2);
$az-active-status-bg:            rgba(0, 171, 39, 0.1);
// $az-inactive-status-bg:           #cc3b3b;
$az-active-text:                  #250a33;
$az-border-color:                 #72818e33;
$az-border-md:                    #cccccc;
$az-border-light:                 #f2f2f2;;
$az-update-txt-color:             #658958;
//$az-input-placeholder:            #979797;
$az-input-bg-color:               #fafafa;
$az-onfoucs-datepicker:           #97a3b9;


$az-white:                        #ffffff;
$az-black:                        #000000;
$az-gray:                         gray;
$az-light-gray:                   #eeedeb;
$az-disbled:                      #B7B7B7;


$az-heading-two:                  Oswald-Regular;
$az-heading-para:                 Oswald-Medium;
$az-heading-para-light:           Oswald-Bold;

$az-heading-one:                  Roboto-Bold;
$az-heading-one-rg:               Roboto-Regular;
$az-heading-three-bd:             PlayfairDisplay-Bold;
$az-heading-three-md:             PlayfairDisplay-Medium;
$az-heading-three-rg:             PlayfairDisplay-Regular;

$az-font-10:                     10px;
$az-font-11:                     11px;
$az-font-12:                     12px;
$az-font-13:                     13px;
$az-font-14:                     14px;
$az-font-15:                     15px;
$az-font-16:                     16px;
$az-font-17:                     17px;
$az-font-18:                     18px;
$az-font-19:                     19px;
$az-font-20:                     20px;
$az-font-24:                     24px;
$az-font-26:                     26px;
$az-font-28:                     28px;
$az-font-30:                     30px;
$az-font-36:                     36px; 
$az-dash-mt:                     46px;

$az-font-44:                     44px;



// GF Hotels 

$gf-primary-color:                 #272E79;

$gf-input-label-color:             #797979;
$gf-input-bg-color:                #f0f0f0;
$gf-md-black:                      #121617;
$az-xl-light-white:                #f2f2f2;
$az-delele-text:                   #272E79;
$gf-md-black-secondary:            #333333;
$gf-gray-secondary:                #808080;

$az-inspection-red:               #de0000;
$az-inspection-blue:              #0069c0;
$az-inspection-black:             #121617;
$az-inspection-brown:             #272E79;
$az-solid-brown:                  #272E79;
$az-modl_dange_btn:               #ff5145;
$az-modl_dange_btn_sec:           #fc2f20;




