

/* ###### 7.9 Signin  ###### */
.authentication {
   width: 100%;
   background: $az-white;
  .az-signin-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 100vh;
    background-image: url(../../images/welcome_bg.png);
    background-repeat: no-repeat;
    flex-direction: column;
    background-size: cover;
    background-position: center 47%, center 100%;
    width: calc(100% - 500px);
    position: relative;
    @media (max-width:480px) {
      
      background-size: initial;
      background-position: center;
    }
  
    @media (max-width:320px) {
      
      background-size: initial;
      background-position: center;
    }
    
    &::before {
        position: absolute;
        top: 0;
        background-color: $az-black-color;
        height: 100%;
        content: '';
        width: 100%;
    }
    .az-copyright { 
        z-index: 0;
      p {
         color: $az-white;
         font-size: $az-font-16;
        //  font-family: $az-heading-two;
         
      }
  }
  
  }
  
  .az-card-signin {
    width: 500px;
    padding: 0px 30px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
    position: relative;
  
      @media (max-width:480px) {
      
         padding: 30px 15px !important;
         height: calc(100% - 40px);
      }
  
      @media (max-width:320px) {
        
      padding: 30px 15px !important;
      height: calc(100% - 40px);
      }
  
    .az-logo {
            img {
              display: block;
              margin: 0 auto !important;
              width: 83% !important;  
              @media (max-width:1440px) {
                
                margin: 10px auto 15px auto;
              }
              @media (max-width:1366px) {
  
                margin: 0 auto 15px auto;
              }
  
              @media (max-width:1280px) {
  
                margin: 0 auto 15px auto;
              }
  
              @media (max-width:1199px) {
    
                 margin: 0 auto 15px auto;
              }
              @media (max-width:991px) {
      
                 margin: 0 auto 45px auto;
              }
  
              @media (max-width:480px) {
      
               margin: 0 auto 15px;
               }
             
               @media (max-width:320px) {
                 
               margin: 0 auto 15px;
               }
            }
            h2 {
                 @include h2;
                 margin-bottom: 45px;
  
                 @media (max-width:1440px) {
  
                  margin-bottom: 15px;
  
                 }
              
               @media (max-width:1366px) {
  
                  margin-bottom: 15px;
                }
    
                @media (max-width:1280px) {
    
                  margin-bottom: 15px;
                  font-size: 30px;
                }
  
                @media (max-width:1199px) {
    
                  margin-bottom: 15px;
                  font-size: 30px;
                }
                @media (max-width:991px) {
        
                  margin-bottom: 45px;
                  font-size: 30px;
                }
  
                @media (max-width:480px) {
                      font-size: $az-font-30;
                      margin-bottom: 20px;
                    
                  }
  
               @media (max-width:320px) {
                font-size: $az-font-26;
                margin-bottom: 20px;
               
             }
           }
      }
  
  }
  .az-signin-header {
          background-color: #fff;
          padding: 48px 39px 28px;
          border-radius: 2px;
          // height: 442px;
  
          @media (max-width:1366px) {
            padding: 10px 35px 10px;
            height: 340px;
          }
  
          @media (max-width:1280px) {
            padding: 15px 35px 10px;
            height: 340px;
          }
  
          @media (max-width:1199px) {
    
            padding: 15px 35px 25px;
            height: 340px;
          }
          @media (max-width:991px) {
  
            padding: 15px 35px 25px;
            height: 340px;
          }
  
          @media (max-width:480px) {
                   padding: 30px 20px;
                   width: 320px;
                   margin: auto;
                   height: auto;
                }
          @media (max-width:320px) {
                  padding: 30px 20px;
                  width: 280px;
                  margin: auto;   
                  height: auto; 
              }
  
         h3 {
  
              @include h3
         }
          .p-dark { 
  
                @include p-dark ;
  
                @media (max-width:1366px) {
  
                  margin-bottom: 0;
                }
    
                @media (max-width:1280px) {
    
                  margin-bottom: 0;
                }
  
                @media (max-width:1199px) {
    
                  margin-bottom: revert;
                }
                @media (max-width:991px) {
    
                  margin-bottom: revert;
                }
    
          }
  
          form {
                margin-top: 32px;
  
                @media (max-width:1366px) {
  
                  margin-top: 5px;
                }
    
                @media (max-width:1280px) {
    
                  margin-top: 20px;
                }
                @media (max-width:1199px) {
    
                  margin-top: 5px;
                }
  
            .form-group { 
                          margin-bottom: 24px;

                          @media (max-width:1366px) {
  
                             margin-bottom: 10px;
                          }
              
                          @media (max-width:1280px) {
              
                             margin-bottom: 10px;
                          }
  
                    input {
                      
                         border: none ;
                        
                         padding: 0px;
                         font-size: $az-font-16 ;
                         color: $az-black !important;
                         border-radius: 4px !important;
                         background-color: transparent !important;
                         border-radius: 0!important;
                         border: 0 ;
                        border-bottom: .2px solid #80808038 ;
                         height: 28px;
                         &:hover {
                            border: none;
                         }
                         &:focus {
                            border: none;
                         }
                         &:active {
                            border: none;
                         }
                      
                      
                       &::placeholder {
                        color: $az-gray !important;
                        font-weight: normal !important;
                       
                     }
                       
                      
                    }
                   
                    .showPassword {
                      position: relative;
                      input {
                        padding: 0;
                      }
                      span {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translate(0px, -50%);
                        i {
                          cursor: pointer;
                          color: $gf-input-label-color;
                        }
                      }
                   }
                   label {
                     text-transform: capitalize;
                     color: $gf-input-label-color;
                     font-size: $az-font-15;
                     font-family: $az-heading-one-rg;
                   }
                  
               }
              
               .button-green {
                          @include green-button;
                          background: $gf-primary-color ;
                          min-width: 100%;
                          max-width: 100%;
                          height: auto;
                          margin-top: 32px;
                          letter-spacing: 1.2px;
                          border: 0;
                          &:focus {
                            box-shadow: none;
                            background: $gf-primary-color !important;
                            color: $az-white !important;
                          }
                          &:hover {
                            box-shadow: none;
                            background: $az-gray !important;
                            color: $az-white !important;
                          }
                          &:active {
                            box-shadow: none;
                            background: $gf-primary-color !important;
                            color: $az-white !important;
                          }
                          span{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          }
  
                          @media (max-width:1366px) {
  
                            margin-top: 20px;
                         }
             
                         @media (max-width:1280px) {
             
                             margin-top: 16px;
                         }
                         @media (max-width:767px) {
             
                          margin-top: 16px;
                      }
                 }
  
                .az-signin-footer {
                      //  position:absolute;
                        p {
  
                          @media (max-width:480px) {
                            margin-bottom: 0px;
                           
                         }
            
                         @media (max-width:320px) {
                          margin-bottom: 0px;
                         
                       }
                        a {
                           color: $gf-primary-color;
                           font-family: $az-heading-para;
                           font-size: $az-font-14;
                           text-transform: uppercase;
                          //  text-decoration: underline;
                          //  text-underline-offset: 0.3em;
                           font-weight: normal;
                           
                        }
                        &:hover {
  
                          color: $az-black;
                      }
                }
              }
          }
  
  }
  
}

