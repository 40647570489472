////////////////////////////////////////////////////////////
//     							                          //
//  Program: custom.scss                                  //
//  Application: scss                                     //
//  Option: Used to create custom css                     //
//  Developer: CV  						                  //
//  Date: 2021-02-01                                      //
//                                                        //
////////////////////////////////////////////////////////////

/* ############### Variables ############### */
@import "./custom/variables";

@import "../fonts/fonts";

// @font-face {  @include headingBold; }
@font-face {
  @include headingMainBold;
}

@font-face {
  @include headingMainRg;
}

@font-face {
  @include headingRg;
}

@font-face {
  @include headingMd;
}

@font-face {
  @include headingRgbd;
}

@font-face {
  @include headingthreeBold;
}

@font-face {
  @include headingthreeMd;
}

@font-face {
  @include headingthreeRg;
}

.container {
  @include maincontainer;
}

.az-logo {
  text-transform: none;
}

.az-header-menu-header {
  height: auto;
}

.az-header-top {
  padding:30px 30px 10px 0;
}

.az-header-menu-header .az-logo img {
  margin: 0 auto;
  height: 70px;
}

.az-card-signin {
  height: auto;
}

.az-2fa-signin-wrapper {
  min-height: 80vh !important;
}

.alert-model-message {
  font-size: 18px !important;
}

.az-2fa-signin-wrapper .az-card-2fafp {
  height: max-content;
}

.timerText,
.negnum {
  color: #dc3545;
}

.align-item-center {
  align-items: center;
}

.az-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.az-content-breadcrumb {
  margin-bottom: 20px;
}

.media-body p {
  margin-bottom: 0;
}

.az-contact-info-body {
  padding-left: 0;
}

// Pagination
.pagination .page-item.disabled {
  padding-right: 13px;
}

.page-item + .page-item {
  margin-left: 2px;
}

.pagination .page-item .page-link {
  width: unset;
  min-width: 36px;
}

// Alert modal
.alert-model-message {
  white-space: pre-wrap;
}

// Media css
@media (min-width: 480px) {
  .az-card-signin {
    padding: 30px 30px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 12rem auto;
  }
}

@media (min-width: 992px) {
  .az-sidebar {
    z-index: 1000;
  }

  .az-content-title {
    margin-bottom: 15px;
  }

  .az-contact-info-header {
    padding-left: 0;
    padding-bottom: 10px;
  }

  .az-contact-info-header .media-body {
    margin-left: 0px;
  }

  .az-contact-info-body .media-body {
    margin-left: 0px;
  }
}

.spinnerLoader {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  text-align: center;
  vertical-align: center;
}

.spinnerLoader .toploader {
  margin: 20% auto;

  img {
    max-width: 80px;
  }
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  position: relative;
}

.form-group span.text-danger {
  padding-left: 2px;
  position: absolute;
}

.asrt-page-length {
  display: inline-block !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

//  **Chrome, Safari, Edge, Opera**
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//  **Firefox**
input[type="number"] {
  -moz-appearance: textfield;
}

.nav-link-custom i {
  width: 23px;
}

.accordion .card-body {
  background: unset;
  border-bottom: 1px solid #f4f5f8;
}

.accordion > .card {
  overflow: unset;
}

.form-wizard .container ol.progtrckr li.progtrckr-doing {
  background-color: #16a2b8;
}

.form-wizard .container ol.progtrckr li.progtrckr-done {
  background-color: #aad3da;
}

.az-content-title {
  text-transform: uppercase;
}

.tx-capital {
  text-transform: uppercase;
}

.prefered-broker {
  max-height: 85px;
  overflow: auto;
  margin-bottom: 0;
  padding-left: 25px;
}

.current-price-holding-qty {
  background-color: #a8dde1;
}

.form-wizard .container .az-content-body {
  text-align: left !important;
}

.btn-primary,
.form-wizard .container > div:not(.content) > button {
  background-color: #16a2b8;
  border-color: #16a2b8;

  @include hover-focus() {
    background-color: #117a8b;
    border-color: #117a8b;
  }

  &:active,
  &:focus {
    background-color: #117a8b;
    border-color: #117a8b;
  }
}

.az-contact-info-body .media + .media::before {
  left: 0px;
}

.badge-status {
  margin-left: 20px;
  position: absolute;
  top: 4px;
}

.badge-status-image {
  margin-left: 20px;
  position: absolute;
  top: 20px;
}

#dropdown-basic {
  background-color: #16a2b8;
}

.az-contact-action a {
  margin-left: 0 !important;
}

.az-contact-action {
  a {
    margin-left: 0 !important;

    @include hover-focus() {
      color: #f4f5f8;
      background-color: #16a2b8;
    }
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.form-wizard
  .container
  > div:not(.content)
  > button:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.form-wizard
  .container
  > div:not(.content)
  > button:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.form-wizard .container > div.show:not(.content) > button.dropdown-toggle {
  background-color: #117a8b;
  border-color: #117a8b;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.form-wizard
  .container
  > div:not(.content)
  > button:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.form-wizard
  .container
  > div:not(.content)
  > button:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.form-wizard
  .container
  > div.show:not(.content)
  > button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #a8dde1;
}

.az-contact-info-header .az-img-user img {
  width: 80%;
  height: 80%;
}

// .table-striped tbody tr:nth-of-type(2n+1) {
//     background-color: #A8DDE1;
// }
.csv-upload {
  background-color: #ddddff;
}

.green-odd,
.green-even {
  background-color: #208b2c;
  padding: 5%;
}

span.green-odd,
span.green-even {
  color: white;
}

[class*="btn-outline-"]:focus {
  color: #17a2b8;
}

[class*="btn-outline-"]:hover {
  color: white;
}

th.sortable.asc,
th.sortable.desc {
  padding-right: 20px;
}

tr.row-bold:last-child,
tr.header-bold:first-child,
.table-last-row-bold tr:last-child,
.table-row-bold {
  font-weight: bold !important;
}

.text-white {
  color: #fff !important;
}

.grey {
  background-color: #717471;
  color: white;
  padding: 5%;
}

.table-row-grey {
  background-color: #cdd4e0 !important;
}

.table-row-grey td {
  border: 1px solid #fff !important;
}

span.warning {
  font-weight: bold;
  color: red;
}

.lightGreen {
  color: #090;
}

.no-wrap {
  white-space: nowrap;
}

.react-datepicker-popper {
  z-index: 4 !important;
}

.tail {
  background-color: #a2bde3 !important;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  border: 1px solid;
  border-radius: 3px;
}

td {
  position: relative;
}

td > span.green-even {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 12px;
}

td > span.green-even:after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

td > a.green-even {
  color: #fff;
  padding-left: 12px;
}

td > span.green-odd {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 12px;
}

td > span.green-odd:after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

td > a.green-odd {
  color: #fff;
  padding-left: 12px;
}

.trans_type_message > a.green-odd {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
}

.trans_type_message > a.green-even {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
}

.quantity > span.green-odd {
  padding-right: 15%;
}

.buy_or_sell_price > span.green-odd {
  padding-right: 15%;
}

.total_cost_or_proceeds > span.green-odd {
  padding-right: 15%;
}

.contract_note_number > span.green-odd {
  padding-right: 10%;
}

.quantity > span.green-even {
  padding-right: 15%;
}

.buy_or_sell_price > span.green-even {
  padding-right: 15%;
}

.total_cost_or_proceeds > span.green-even {
  padding-right: 15%;
}

.contract_note_number > span.green-even {
  padding-right: 10%;
}

td > span.grey {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 12px;
}

td > span.grey:after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

td > a.grey {
  color: #fff;
  padding-left: 12px;
}

// .newCGT {
//     color: #fff;
// }
.discretionaryWarning,
td.alert {
  background-color: #ff5050 !important;
  color: #fff;
}

.isActive input {
  width: 20px;
  height: 20px;
}

.chip,
.singleChip {
  white-space: unset;
}

.search-wrapper {
  max-height: 100px;
  overflow-y: auto;
}

.reviews label {
  padding-right: 15px;
  width: 55px;
}

.reviews select {
  padding-left: 15px;
  padding-right: 15px;
}

.btn-uppercase {
  text-transform: uppercase;
}

.measureperform {
  table-layout: fixed;
}

.measureperform th {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0.5em 0.6em 0.4em 0.6em !important;
}

.measureperform th:first-child {
  width: 230px;
}

.performanceUp {
  table-layout: fixed;
}

.performanceUp th {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0.5em 0.6em 0.4em 0.6em;
}

.initial {
  table-layout: initial;
  width: 100%;
}

.initial th {
  text-overflow: inherit;
  overflow: inherit;
  white-space: inherit;
}

#as-react-datatable td {
  vertical-align: middle;
}

.textUppercase {
  text-transform: uppercase;
}

@include checkbox;

table {
  thead {
    .bg-info {
      th {
        color: #fff !important;
        background: transparent;

        b {
          color: #fff !important;
        }
      }
    }
  }

  tbody {
    .bg-info {
      th {
        color: #fff !important;
        background: transparent;

        b {
          color: #fff !important;
        }
      }
    }
  }

  .bg-info {
    .header {
      th {
        color: #fff !important;
        background: transparent;

        b {
          color: #fff !important;
        }
      }
    }
  }
}

.peformance-report-table input {
  text-align: right;
}

.border-red {
  border: red !important;
  border-style: solid !important;
}

.border-blue {
  border: blue !important;
  border-style: solid !important;
}

.naColor {
  background-color: #faa4d3;
}

.specialColor {
  background-color: #ffff00;
}

.noSpecialColor {
  background-color: #e4b0cc;
}

.emptyColor {
  background-color: #ff00ff;
}

.emptyColor a {
  color: white;
}

.headerWhite td {
  color: white !important;
  background-color: #17a2b8 !important;
}

.az-header-menu .nav-item > .nav-link.text-danger:hover {
  color: #17a2b8 !important;
}

@media (min-width: 768px) {
  .col-md-12,
  .form-wizard.form-wizard-vertical .container > div:not(.content) {
    flex: 0 0 auto;
  }
}

.customeNegnumColor span {
  color: white !important;
}

.customLoad {
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 9999999;
  background-color: white;
  overflow: hidden;
}

.customLoad .div1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customLoad .div1 .customLoadimg {
  width: 200px;
}

#preload1 {
  margin-top: 17%;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  border: 10px solid #e5e5e5;
  animation: 3s pre1 linear infinite;
  position: absolute;
  left: 43.5%;
  top: 3.8%;
}

@keyframes pre1 {
  0% {
    transform: rotate(0deg);
    border-top: 10px solid #256b8b;
  }

  100% {
    transform: rotate(360deg);
    border-top: 10px solid #256b8b;
  }
}

@keyframes cLoad {
  0% {
    width: 0;
  }

  100% {
    width: 200px;
  }
}

.customTableDiv .table td {
  padding: 0px !important;
}

.customTableDiv .table td > p {
  position: unset !important;
  margin: 0px;
  height: 50px;
  padding: 10px;
}

.forgot-text {
  display: flex;
  justify-content: center;
}

.back-button {
  margin-right: 12px;
}

.bg-success {
  background-color: green !important;
}

.bg-danger {
  background-color: red !important;
}

.hand-cursor {
  cursor: pointer;
}

.status-active {
  @include activestatus;
}

.status-inactive {
  @include inactivestatus;
}

.modal-large {
  .modal-dialog {
    max-width: 800px !important;
  }
}

.valid_info {
  color: $gf-primary-color;
}

.text-danger {
  color: $gf-primary-color !important;
}

table tbody tr:hover .status-active {
  background-color: $az-white;
}

table tbody tr:hover .status-inactive {
  background-color: $az-white;
}

.mg-r-neg-7 {
  margin-right: -7px;
}

.model_first_p {
  color: $gf-md-black !important;
  font-family: $az-heading-two !important;
}

.model_sec_p {
  font-family: $az-heading-two !important;
  text-transform: uppercase !important;
}

.profil_img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.tracker_btn_new {
  margin: -90px 0 23px 0 ;
}

.mgt_10 {
  margin-top: 10px !important;
}

.mg-tneg-6 {
  margin-top: -6px !important;
}

.mgt_11 {
  margin-top: 11px !important;
}

.mg-t-31 {
  margin-top: 31px;
}

.mg-t-18 {
  margin-top: 18px !important;
}

.h-380 {
  height: 330px;
  display: grid;
  place-items: center;
}

.h-240 {
  height: 240px;
}

.note_content {
  background: #f2f2f2;
  border-radius: 8px;
  font-family: $az-heading-one-rg;
  color: $az-black;
  font-size: $az-font-14;
  padding: 12px 20px;
}

.date-time {
  font-size: $az-font-12;
  font-family: $az-heading-two !important;
  color: $gf-input-label-color !important;
  padding: 5px 5px 5px 20px;
}

.note_img img {
  width: 30%;
  height: auto;
  border-radius: 8px !important;
}

.note_img{
  &.single_item_note_box{
    width: 30%;
    position: relative;
    img{
      width: 100%;
    }
    .btn_remove{
      position: absolute;
    }
  }
}

.brand_list_image {
  width: 71px;
  height: 71px;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}

.img_close {
  padding-left: 10px;
  position: relative;
}

.btn_remove {
  position: absolute;
  top: -5px;
  min-height: 23px;
  padding: 3px;
  width: 23px;
  border-radius: 50%;
  line-height: 3pt;
  right: -5px;
  background: gray;
  border: 0;
  color: white;
  // z-index: 99;
}

.mro_btn {
  margin-right: -7px;
}

.list_img {
  display: -webkit-inline-box;
}

.multiImgsection .list-group-item {
  padding: 0px;
  margin: 0 15px;
}

.multiImgsection .list-group-item .progress {
  position: absolute;
  width: 71px;
  height: 10px;
  bottom: -10px;
}

.css-e56m7-control {
  border-color: rgba(0, 0, 0, 0.55) !important;
}

.hand-cursor img {
  object-fit: cover;
  object-position: top;
  margin-left: -5px;
  margin-right: 5px;
}

.cnt_center {
  place-content: center;
}

select {
  height: 44px !important;
}

.note_popup_img {
  width: 70px ;
  height: 70px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin-bottom: 10px !important;
  object-fit: cover;
  margin-left: 5px;
  margin-right: 5px;
}

.scroll_mro {
  max-height: 650px !important;
  overflow-y: auto;
}

.scroll_mro .tableFormatesimple table tr td {
  padding-left: 15px !important;
}

/* width */
.scroll_mro::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll_mro::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll_mro::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll_mro::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 990px) {
  .az-rightbar {
    width: 100% !important;
  }
}

/* The container */
.check_cust {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: 20%;
}

/* Hide the browser's default checkbox */
.check_cust input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 10px;
  top: -5px;
  width: 16px ;
  height: 16px ;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid gray;
  transform: translate(-50%, -90%);
}

/* On mouse-over, add a grey background color */
.check_cust:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.check_cust input:checked ~ .checkmark {
  background-color: $gf-primary-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_cust input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check_cust .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.excluded_box p {
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.06em;
}

.excluded_icon {
  background: $gf-primary-color;
  border-radius: 5px;
  padding: 2px 11px;
  color: #fff;
  border-radius: 5px;
  font-size: 19px;
  position: relative;
  top: 2px;
}

.text-danger {
  font-family: $az-heading-one-rg;
}

.az-rightbar .deleteCat {
  position: relative;
  top: -9px;
}

.dust_icon {
  top: 0px !important;
}

.new_btn_add {
  position: relative;
  right: -25px;
  top: -10px;
  z-index: 0;
}

.mro_btn {
  margin-right: -17px;
}

.dashbaord-pageHeadertitle {
  padding: 20px 0px 25px !important;
  margin-bottom: 0px !important;
  &.audit-ai-title {
    padding: 20px 0px 15px !important;
  }
}

.mgb-neg-20 {
  margin-top: -20px !important;
}

.table_mro thead th {
  padding-right: 5px !important;
}

.heading_alignment {
  margin-top: 5px;
}

.heading_alignment span {
  margin-top: 6px !important;
}

.dash_heading {
  padding: 0;
  margin: 0;
}

.dash_info_box {
  background-color: $az-white;
  position: relative;
  min-height: 120px;

  .inner_dash_info {
    padding: 10px;
    margin-top: 30px;
    position: relative;
    h3 {
      font-size: $az-font-30;
      color: $gf-primary-color;
      margin: 0;
    }
    p {
      margin: 0;
      padding-top: 10px;
    }
  }

  .btn_info_move {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
    background-color: $gf-primary-color;
    color: $az-white;
    padding: 7px;
    transition: 0.2s;
    text-transform: capitalize;
    &:hover {
      background-color: $gf-md-black-secondary;
    }
  }
}

.card-dashboard-sixteen {
  box-shadow: 0 0 10px #1c273c0f;
}

.card-dashboard-sixteen .card-title {
  color: $gf-primary-color;
}

.card-dashboard-sixteen .card-title.green-title-recent-dailylog{
  color: #1f7f0b;
}

.card-dashboard-sixteen table tr:hover {
  color: #fff !important;
}

.dash_head {
  color: $gf-primary-color;
}
.dashboard .card-title {
  text-transform: uppercase;
}
.h-chart_1 {
  height: 390px;
}

.dash_icons {
  float: right;
  width: 160px;
}

.modal-open .brandSection .order_form {
  z-index: -1 !important;
  transition: 0s;
}

// Disable browser close icon for Chrome
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-size: 10px 10px;
  cursor: pointer;
}

.btn_view {
  float: right;
  color: gray;
  text-transform: capitalize;
}

.btn_view:hover {
  color: #272e79;
}
.link-style {
  color: inherit;
  text-decoration: none;
}

.bd-color-cust {
  border-color: transparent;
  box-shadow: none;
}

.bg-green {
  background-color: #090;
}

.card-dashboard-events .list-group-item h6 {
  font-size: 16px;
}

.card-dashboard-finance h2 {
  text-align: right;
}

.card-dashboard-ratio {
  height: auto !important;
}

.card-dashboard-ratio .card-body h2 {
  font-size: 50px;
  padding-right: 10px;
  color: #000;
}

.card-dashboard-sixteen .table tbody > tr td:first-child {
  text-align: left;
}

.card-dashboard-sixteen .table tbody > tr td {
  text-align: right;
}

// .inspe_hgt{
//   height: 65px;
// }

.dash_borad_sec {
  padding: 0px 30px;
}

.form-control, .select2-example .select2-container .select2-selection--single, .select2-example .select2-container .select2-selection--multiple, .input-group .react-datepicker-wrapper {
  height: 28px;
  border: 0 !important;
  border-bottom: 0.2px solid #80808038 !important;
  padding: 0 !important;
}

.css-2b097c-container .css-yk16xz-control{
  height: 100% ;
  border: 0 !important;
  border-bottom: 0.2px solid #80808038 !important;
}
.css-g1d714-ValueContainer {
  padding: 0 !important;
}

.css-2b097c-container .css-1pahdxg-control{
  height: 28px ;
  border: 0 !important;
  border-bottom: 0.2px solid #80808038 !important;
}

.css-2b097c-container .css-1pahdxg-control:hover{
  height: 28px ;
  border: 0 !important;
  border-bottom: 0.2px solid #80808038 !important;
}

.css-yk16xz-control{
  min-height: 28px ;
}

.css-b8ldur-Input {
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  height: 28px ;
}

.css-2b097c-container .css-1pahdxg-control , .css-2b097c-container .css-exgvk6-control {
  height: 28px ;
}
.css-g1d714-ValueContainer , .css-1hwfws3{
  padding: 0 !important;
  height: 28px ;
}

.css-2b097c-container .css-1pahdxg-control{
  height: 28px ;
}

.css-1uccc91-singleValue {
  margin: 0 !important;
}

/* width */
.az-header::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
.az-header::-webkit-scrollbar-track {
  background: #fff !important;
}

/* Handle */
.az-header::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
.az-header::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
.az-header{
  overflow: unset;
}

textarea {
  border: 0 !important;
  border-bottom: 0.2px solid #80808038 !important;
  padding: 0 !important;
  width: 100%;
  min-height: 70px;
}

textarea:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: 0px !important;
}

/* width */
textarea::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
textarea::-webkit-scrollbar-track {
  background: #fff !important;
}

/* Handle */
textarea::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
textarea::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}


.react-datepicker__header{
  background: $gf-primary-color;
  color: #fff;
}

.react-datepicker__navigation--next {
  border-left-color: #fff;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #fff;
}

.react-datepicker__navigation--previous {
  border-right-color: #fff;
}

.react-datepicker__navigation--previous:hover{
  border-right-color: #fff;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color: #fff;
  font-family:$az-heading-one;
}

.react-datepicker__year-container-text{
  font-family: $az-heading-one;
}

.react-datepicker__day-name{
  color: #fff;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
  background-color: $gf-primary-color;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
  border-top-color: #fff;
}

.react-datepicker__month-read-view--down-arrow:hover{
  border-top-color: #fff !important;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow{
  border-top-color:#fff!important;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown{
  color: #000;
  background: #fff;
  box-shadow: 0px 0px 2px 0px #000;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: $gf-primary-color;
  color: #fff !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #272E79;
}

.react-datepicker__month-option--selected_month{
  background: #272E79 !important;
  color: #fff !important;
}


.react-datepicker-year-header{
  width: 240px;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #fff;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #fff;
}

.css-1hwfws3{
  padding: 0px !important;
  height: 100% !important;
}
.react-datepicker-year-header{
  width: 240px;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #fff;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #fff;
}

.css-1hwfws3{
  padding: 0px !important;
  height: 100% ;
}

textarea.form-control{
  height: auto !important;
}

.css-1g6gooi{
  margin: 0 !important;
}
.css-tlfecz-indicatorContainer{
  padding: 0 8px!important;
}

.css-11aao21-a11yText{
  height: 0 !important;
  width: 0 !important;
}

.multivalue_drop > .css-yk16xz-control{
  height: 100% !important;
}

.multivalue_drop .css-g1d714-ValueContainer{
  height: 100% !important;
}

.multivalue_drop .css-1pahdxg-control{
  height: 100% !important;
}



.multivalue_drop  .css-1pahdxg-control .css-g1d714-ValueContainer{
  height: initial !important;
}

// .css-b8ldur-Input{
//   width: 0;
// }


.css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer {
  padding: 8px !important;
  cursor: pointer !important;
}
// .multivalue_drop > .css-1pahdxg-control:hover{
//   height: initial !important;
// }

.css-2b097c-container .css-yk16xz-control{
  min-height: 100%;
}

.css-2b097c-container .css-1pahdxg-control{
  height: 100%;
}

.css-1uccc91-singleValue{
  color: #000 !important;
}

#react-select-63-input{
  display: none;
}

input[type="file"]:focus{
  border-bottom: 0px !important;
}


@media screen and (max-width:1990px) {
  .css-yk16xz-control input{
    height:100% !important;
  }
  .css-b8ldur-Input{
    height: 100%!important;
  }
  .css-2b097c-container input:focus{
    height: 100% !important;
  }
  .css-1pahdxg-control{
    height: 100% !important;
  }
  .css-yk16xz-control{
    min-height: 100% !important;
  }
  .css-2b097c-container .css-yk16xz-control{
    height: 100% !important;
  }

  .css-2b097c-container .css-1pahdxg-control{
    height:100% !important;
  }
  .brandList .asrt-table-head .table_filter input[type="search"] {
    padding-left: 25px !important;
  }
  .css-2b097c-container .css-1pahdxg-control{
    min-height: 28px !important;
  }

  .multivalue_drop .css-yk16xz-control{
    height: 100% !important;
  }
  .multivalue_drop .css-g1d714-ValueContainer{
    height: 100% !important;
  }
  select {
    height: 28px !important;
}
.css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer{
  padding: 0 8px !important;
}
.back-size{
  font-size: 16px !important;
}
.note_popup_img {
  width: 70px !important;
}
.modal .modal-dialog .modal-content .modal-body .az-toggle {
  width: 36px !important;
}
}

@media screen and (max-width:1280px) {
.modal .modal-dialog .modal-content .modal-body .az-toggle {
  width: 47px !important;
}
}

// #react-select-3-input{
//   height: 0 !important;
// }

.btn-danger.focus, .btn-danger:focus, .btn-danger:hover {
  background-color: $gf-primary-color !important;
  border-color: $gf-primary-color !important;
}

.pagination{
  padding: 3px;
}

.month_width table tr th{
  width: 100px;
}

.month_width table tr th{
  width: 100px;
}

.month_width table tr td:first-child{
  width: auto ;
}

.month_width table tr th:first-child{
  width: auto;
}

.react-datepicker__day--outside-month{
  visibility: hidden;
}

.load_text{
  color: #fff !important;
  font-size: 16px ;
}

.toploader{
  position: relative;
  bottom: -5px;
}


.loader_btn .load_text{
  color: #fff !important;
  font-size: 16px ;
}

.css-1hwfws3{
  position: unset;
}

.az-rightbar .deleteCat img{
  margin-bottom: 2px;
}

.brandLogo {
  border-radius: 50%;
  object-fit: contain !important;
  object-position: center !important;
  background: #f7f7f7;
}

.show_password_new{
  position: relative;
}

.pass_eye_btn{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
}

.pass_eye_btn .fa{
  cursor: pointer;
    color: #797979;
}

.fliter_back{
  background: rgba(0, 0, 0, .82);
  width: 100%;
  position: fixed;
  top: 0px;
  left: -240px;
  z-index: 999999999;
  height: 100vh;
}

.bdy_over{
  overflow: hidden;
}

.toploader img{
  max-width: 100px!important;
}

.css-12jo7m5{
  font-size: 16px !important;
  color:$az-black !important;
  @media (max-width:1280px){
    font-size: 15px !important;
  }
  @media (max-width:1200px){
    font-size: 16px !important;
  }
}

.privacy_sec{
    padding:50px 220px;
    background: $az-white;
    max-width: 100%;
    @media (max-width:1350px) {
      padding: 50px 180px;
    }
    @media (max-width:991px) {
      padding: 50px 15px;
    }
    .logo_privacy{
      text-align: center;
      padding: 50px auto 15px;
      max-width: 100%;
      img{
        margin-top: 10px;
        max-width: 300px;
        margin-bottom: 50px;
      }
    }
    h1{
      font-size: $az-font-26;
      font-family: $az-heading-para;
      color: $gf-primary-color;
      margin-bottom: 20px;
    }
    h4{
      font-size: $az-font-20;
      margin-bottom: 15px;
      color: $gf-primary-color;
      font-family: $az-heading-one-rg;
    }
    p{
      font-size: $az-font-16;
      color:$az-black;
      margin-bottom: 10px;
      b{
        color:$az-black;
      }
    }
    ol{
      li{
        font-size: $az-font-16;
        color: $az-black;
        margin-bottom: 10px;
        b{
          color:$az-black;
        }
        ul{
          list-style-type: lower-alpha;
          padding-left: 15px;
          li{
            font-size: $az-font-16;
            color:$az-black;
            margin-bottom: 10px;
            b{
              color:$az-black;
            }
            a{
              font-weight: 600;
              text-decoration: underline;
              color:$az-black;
              text-underline-offset: 3px;
              word-break: break-all;
            }
          }
        }
      }
    }
}

.az-daily-mid_text{
    font-size: $az-font-14;
    font-weight:600!important;
    margin-bottom: 5px;
    color: $az-black;
}

.mg-cust-b-5{
  margin-bottom: 5px !important;
}

h3.title_daily_log{
  font-size: $az-font-20;
  font-family: $az-heading-para;
  color: #272E79;
  margin-bottom: 0px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: start;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-bottom: 20px;
}

.card-dashboard-ratio .card-body h2, .card-dashboard-ratio .nav-tabs + .tab-content h2{
  font-size: 40px;
  color: $gf-primary-color;
}

.login-footer_cust{
  display: flex;
  justify-content: space-between;
  @media (max-width:767px) {
    display: block;
  }
}

.divider-login{
  @media (max-width:767px) {
  display: none;
  }
}

.cust_pb_10{
  @media (max-width:767px) {
    margin-bottom: 20px;
  }
}

.header_help  a {
  color: $gf-primary-color;
  font-family: $az-heading-para;
  font-size: $az-font-14;
  text-transform: uppercase;
 //  text-decoration: underline;
 //  text-underline-offset: 0.3em;
  font-weight: normal;

}


.dash_middle_sec{
  .card-dashboard-ratio {
    height: 185px!important;
  }
}

.table {
  color: #000000;
}

.dash_middle_sec{
    .card-body {
      .list-unstyled{
        margin-top: 10px;
        li{
          width: 50%;
          float: left;
        }
      }
    }
}

.bg-backlog{
  background: #ff7bd7;
}

.bg-overdue{
  background: #f56b62;
}

.bg-complete-cust{
  background: #74cb80;
}

.b-cancalled-cust{
  background: #c5a070;
}

.quicklinks_tbl{
  thead{
    tr{
      th{
        width: 50%;
      }
    }
  }
  tbody{
    tr{
      th{
        width: 50%;
        color: $gf-md-black;
      }
      &:hover{
      td{
        color: $gf-md-black !important;
    }
        background: transparent !important;
        td.customer_name{
          a{
            color: #0067b8 !important;
            &:hover{
              text-underline-offset: 3px;
            }
          }
        }
      }
    }
  }
}

.daily_logy_cnt {
  .dashboardRightcard{
    .titleLable{
      font-size: $az-font-16;
      color: $gf-md-black;
      font-family: $az-heading-one-rg !important;
      text-transform: capitalize;
      letter-spacing: unset;
    }
    .az-daily-mid_text{
      font-weight: unset !important;
      color: $gf-gray-secondary !important;
      font-size: $az-font-14;
    }
    .az-daily-para-text{
      font-size: $az-font-15;
      color: $gf-md-black;
    }
    hr{
      max-width: 100%;
    }
  }
}

.app-download-sec{
    padding: 100px 130px;
    background: $az-white;
    height: 100vh;
    display: grid;
    place-items: center;
    @media (max-width:1800px) {
      padding: 100px;
    }
    @media (max-width:1024px) {
      padding:50px;
    }
    @media (max-width:991px) {
      display: block;
      height: 100%;
    }

    @media (max-width:768px) {
      padding: 20px;
    }

    .col-md-6{
      @media (max-width:991px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .download_heading{
      h3{
        font-size:9rem;
        color: #202556;
        font-family: $az-heading-para-light;
        line-height: 9.5rem;
        @media (max-width:1800px) {
          font-size:8rem;
          line-height: 8.5rem;
        }
        @media (max-width:1600px) {
          font-size:7rem;
          line-height: 7.5rem;
        }
        @media (max-width:1525px) {
          font-size:6rem;
          line-height: 6.5rem;
        }
        @media (max-width:1440px) {
          font-size:5rem;
          line-height: 5.5rem;
        }

        @media (max-width:1024px) {
          font-size:4rem;
          line-height: 4.5rem;
        }
        @media (max-width:991px) {
          display: flex;
          justify-content: center;
        }
        @media (max-width:991px) {
          display: block;
          font-size:3rem;
          line-height: 3.5rem;
        }
        span{
          color: #92c3b5;
           @media (max-width:991px) {
              padding-left: 5px;
          }
        }
      }
    }

    .gf-bot-img-sec{
      position: relative;
      @media (max-width:1600px) {
        margin-top: 30px;
      }
      @media (max-width:1600px) {
        margin-top: 0px;
      }
      .btn-bot-img-link{
        position: absolute;
        top: 40px;
        left: 150px;
        @media (max-width:1600px) {
          top: 30px;
        }
        @media (max-width:1440px) {
          top: 20px;
        }
        @media (max-width:1440px) {
          top: 10px;
          left: 50px;
        }
        img{
          width: 300px;
          @media (max-width:1525px) {
            width: 250px;
          }

          @media (max-width:1366px) {
            width: 200px;
          }

          @media (max-width:991px) {
            width:55%;
          }

          @media (max-width:767px) {
            width:55%;
          }

        }
      }
      .pc-app-img{
        width: 100%;
      }
    }

    .or_code_sec{
      display: flex;
      margin-top: 50px;

      @media (max-width:1366px) {
        margin-top: 30px;
      }
      @media (max-width:991px) {
        justify-content: center;
        margin: 50px 0;
      }
      @media (max-width:767px) {
        display: block;
        margin-bottom: 0;
        margin-top: 35px;
      }
        .or_box{
          display: grid;
          place-items: center;
          margin-right: 20px;

          img{
            width: 200px;
            @media (max-width:1600px) {
              width:180px;
            }
            @media (max-width:1525px) {
              width:160px;
            }
            @media (max-width:1366px) {
              width:140px;
            }
            @media (max-width:1024px) {
              width:120px;
            }
            @media (max-width:991px) {
              width:200px;
            }
            @media (max-width:767px) {
              width:90%;
            }
          }
          a{
            margin-top: 20px;
            display: block;
             @media (max-width:991px) {
              width:200px;
            }
            @media (max-width:767px) {
              margin-bottom: 50px;
               width:100%;
            }
            img{
              width:300px;
              @media (max-width:1600px) {
                width:270px;
              }
              @media (max-width:1525px) {
                width:270px;
              }
              @media (max-width:1366px) {
                width:200px;
              }
              @media (max-width:1024px) {
                width:170px;
              }
               @media (max-width:991px) {
                width: 100%;
              }
               @media (max-width:767px) {
                width:100%;
              }
            }
          }
        }
    }
}

.w-max-100{
  max-width: 100% !important;
}

textarea.form-control.daily_log_text{
  height: 200px !important;
}

.daily_log_modal{
  .modal-dialog{
    max-width: 800px !important;
  }
}

.pc_img_center{
  display: flex;
  place-content: center;
  place-items: center;
}


.dashboardRightcard.link_page_sec{
  padding:46px 46px 36px 46px;
  .quick_links_sec{
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        display: inline-grid;
        width: 15.69%;
        margin: 0 15px 15px 0;
        @media (max-width:1910px) {
          width: 15.50%;
        }
        @media (max-width:1661px) {
          width: 18.7%;
        }
        @media (max-width:1529px) {
          width: 18.6%;
        }
        @media (max-width:1446px) {
          width: 18.5%;
        }
        @media (max-width:1400px) {
          width: 18.5%;
        }
        @media (max-width:1375px) {
          width: 23%;
        }
        @media (max-width:1125px) {
          width: 31.5%;
        }
        @media (max-width:1125px) {
          width: 31.2%;
        }
        @media (max-width:991px) {
          width: 30.8%;
        }
        @media (max-width:767px) {
          width: 100%;
          margin-left: 0;
        }
        img{
          width: 80px;
        }
        h3{
          font-size: $az-font-14;
          margin: 15px 0 0 0;
          color: $gf-primary-color;
          text-transform: uppercase;
          font-family: $az-heading-para;
          word-break: break-word;
        }
      }
    }
  }
}

.quick_links_cont {
  box-shadow: 0px 0px 7px 0px #c4c4c4;
  width: 100%;
  height: 200px;
  padding: 10px;
  border-radius: 4px;
  position: relative;
  &:hover {
		.quick_overlay {
			opacity: 1;
		}
	}

  .quick_img_box {
    display: block;
    width: 100%;
    height: auto;
        position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    text-align: center;
  }

  .quick_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: $gf-md-black-secondary;
    border-radius: 4px;
      .quick_text {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 10px;
        p{
          text-align: center;
          color: white;
          font-size: $az-font-14;
          margin: 0;
          height: 100%;
          width: 100%;
          display: grid;
          place-content: center;
        }
      }
  }
}
.topLoder{
   p{
     color: $az-color-headline;
   }
}

.btn-thene-modal{
  background: $gf-primary-color !important;
}

.prop_name{
  .multiselect {
        .css-yk16xz-control {
           height: inherit !important;
           overflow: inherit !important;
          .css-1hb7zxy-IndicatorsContainer {
            align-items: start !important;
            .css-tlfecz-indicatorContainer {
              padding-top: 0px !important;
            }
          }
        }
        .css-g1d714-ValueContainer {
          height: inherit !important;
          overflow: inherit !important;
        }
  }
}

.brandList {
  .asrt-table-body {
    table {
      thead {
        .asc{
          padding-right: 30px !important;
        }
        .desc{
          padding-right: 30px !important;
        }
      }
    }
  }
}
.dashbaord-pageHeadertitle a.perform_btn{
  font-family: $az-heading-para !important;
  background-color: #272E79;
  color: #ffffff !important;
  font-size: $az-font-16;
  border-radius: 8px;
  font-weight: 500;
  border: none ;
  padding: 9px 15px 6px;
  outline: none ;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  height: 40px;
}

.box {
// border: 1px solid #ddd;

.step-component {
  padding: 40px 20px 20px;

  .category_name{
    display: inline-block;
    color: $gf-primary-color;
  }

  .bedroom_title{
    background-color: #f2f2f2;
    text-align: center;
    margin-top: 10px;
    padding: 12px;
    border-radius: 8px;
    display: block;
    font-family: $az-heading-para;
    font-size: $az-font-16;
    color: $az-gray;
    text-transform: uppercase;
    cursor: pointer;
  }

  .qes_ans_box{
    .checbox_cont_div{
      margin: 20px 0; 
      display: none;
      button{
        display: inline-block;
        font-family: $az-heading-para;
        max-width: unset;
        min-width: unset;

        &.active{
          background: $gf-gray-secondary !important;
        }
        &.ansChangeNo{
          background-color: $az-inactive-status !important;
        }
      }
    }
  }

  .checklist_title{
    font-family: $az-heading-one-rg;
    color: $az-color-headline;
    font-size: $az-font-14;
    display: none;
  }
}

.btn-component {
  padding: 35px 0;
  display: flex;
  justify-content: end;

  button{
    min-width: unset;
    max-width: none;
  }

  input{
    display: inline-block;
    width: auto !important;
    font-family: $az-heading-para !important;
    background-color: #272E79 !important;
    color: #ffffff !important;
    font-size: $az-font-16 !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
    border: none ;
    padding: 9px 15px 6px;
    outline: none ;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    height: 40px;
    margin-right: 15px;

    &:disabled{
      display: none;
    }

    @media(max-width: 1600px){
      height: 40px !important;
    }
  }
}

.steps {
  border-top: 1px solid $az-border-color;
  border-bottom: 1px solid $az-border-color;

  ul {
    overflow: hidden;
    li{
      div {
        color: #999;
        padding: 15px 0;
        position: relative;
        // background: #f5f5f5;
        width: 100px;
        text-align: center;

        span {
          font-size: $az-font-16;
          font-family: $az-heading-two;
          letter-spacing: 0.72px;
          text-transform: uppercase;
        }

        // &::before{
        //   content: " ";
        //   border-top: 50px solid transparent;
        //   border-bottom: 50px solid transparent;
        //   border-left: 30px solid #ddd;
        //   position: absolute;
        //   top: 50%;
        //   margin-top: -50px;
        //   left: 100%;
        //   z-index: 1;
        //   margin-left: 1px;
        // }

        // &::after{
        //   content: " ";
        //   border-top: 50px solid transparent;
        //   border-bottom: 50px solid transparent;
        //   border-left: 30px solid #f5f5f5;
        //   position: absolute;
        //   top: 50%;
        //   margin-top: -50px;
        //   left: 100%;
        //   z-index: 1;
        // }
      }
      // &:first-child div{
      //   width: 135px;
      //   padding-left: 15px;
      // }

      &.done div{
        // border-color: $az-gray !important;
        color: $az-gray !important;

        // &::after{
        //   border-left: 30px solid $az-gray;
        // }
      }

      &.active div{
        // border-color: #272e79!important;
        color: $gf-primary-color !important;
        // background: #272e79!important;

        // &::after{
        //   border-left: 30px solid #272e79;
        // }
        &::after{
          border-bottom: 1px solid $gf-primary-color;
          width: 100%;
          height: 1px;
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          text-align: center;
          -webkit-transform: translate(-50%);
          transform: translate(-50%);
        }
      }
    }
  }
}
}

.create_mro_field_section{
  display: none;
}

.inspection_perform_section{
  padding: 0;
}

.note_task_name_box{
  margin-bottom: 10px;

  label{
    display: inline-block;
    margin: 0 5px 0px 0 !important;
  }
}

.qes_ans_box{
  margin: 15px 0 15px 40px;

  .check_cust{
    min-height: 20px;
    display: inline-block;
    margin-bottom: 8px;
    font-family: $az-heading-two;
    color: $az-inspection-black;
    text-transform: uppercase;
    font-size: $az-font-17;

    span {
      p{
        margin: 0;
        font-family: $az-heading-two;
      }
    }
    input{
      width: 0 !important;
    }
    .checkmark{
      left: 12px;
      top: 18px;
    }
  }
  .checkbox_input{
    .form-check{
      display: flex;

      input{
        width: auto !important;
        height: auto !important;
        margin-top: 5px !important;
      }

      label{
        margin-left: 15px;
      }
    }
    
  }

  &.crete_mro_checkbox{
    .checkmark{
      left: 8px;
    }
  }

}

.note_image_box{
  margin-top: 15px;

    img{
      margin-bottom: 0 !important;
    }

  .form-group{
    padding-left: 0 !important;
  }

  span{
    .progress {
      display: none;
    }
  }
}
.ansChange{
  background: $az-active-status !important;
}

.cate_per_title{
  flex:1;
  padding-right: 10px;
}
.checklist_table_heading{
  display: block;
  margin-bottom: 5px;
  .lspan{
    color: $gf-input-label-color;
    font-family: $az-heading-two;
    letter-spacing: 0.72px;
    font-weight: normal;
  }
  .rspan{
    font-family: $az-heading-para;
    color:$gf-primary-color;
    letter-spacing: 0.72px;
  }

  @media (max-width: 1199px){
    padding: 0 15px;
  }
}

.dashboard_prop_dropdown{
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 10px 15px;
    box-shadow: 0 0 10px rgba(28,39,60,.05);

  label{
    font-size: $az-font-15;
    color: $gf-primary-color;
    font-weight: 600;
    text-transform: uppercase;
  }

  .multiselect {
    background-color: transparent !important;
  }
}

.contact_us_dashbord{
  cursor: pointer;
}

/*****************Perform Checklist Inspection CSS********************/
.dashbaord-pageHeadertitle a img{
  margin-right: 5px !important;
}

.per_checklist_section{
  .tabNavigation{
    .checklist_search{
      min-width: 250px;
      max-width: 250px;
      position: relative;
      margin-bottom: 20px;

      input{
        padding-left: 25px !important;
      }

      &::before{
        position: absolute;
        left: 0px;
        background: url('http://54.211.252.139/static/media/Search.5e1561ba.svg');
        background-repeat: no-repeat;
        content: '';
        width: 18px;
        height: 18px;
        z-index: 0;
        top: 5px;
        background-size: 100% 100%;
      }
    }
    // ul{
    //   li{
    //     list-style: none;
    //     border-bottom: 1px solid $az-xl-light-white;
    //     padding: 10px 0;
        
    //     a{
    //       display: flex;
    //       align-items: center;
    //       justify-content: space-between;
    //       font-weight: 400;

    //       h4{
    //         font-size: $az-font-15;
    //         margin-bottom: 0;
    //         color: $gf-primary-color;
    //         font-family: $az-heading-one-rg;
    //       }
    //       span{
    //         color: $az-gray;
    //         strong{
    //           font-weight: 400;
    //         }
    //       }
    //     }

    //     img{
    //       transform: rotate(180deg);
    //     }

    //     &:last-child{
    //       border-bottom: none;
    //       padding-bottom: 0;
    //     }
    //   }
    // }
  }
}

.start_inspectionlist_section{
  .start_insp_title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;

    .insp_innerBox{
      flex: 1;
      padding-right: 20px;
    }

    h4{
      font-size: $az-font-15;
      margin-bottom: 0;
      color: $az-gray;
      font-family: $az-heading-one-rg;
      font-weight: 400;
    }
    span{
      color: $gf-primary-color;
    }
  }

  .start_insp_category_list_box{
    .bedroom_title{
      background-color: #f2f2f2;
      text-align: center;
      margin-top: 10px;
      padding: 12px;
      border-radius: 8px;
      display: block;
      font-family: $az-heading-para;
      font-size: $az-font-16;
      color: $az-gray;
      text-transform: uppercase;
    }

    ul{
      li{
        min-height: 20px;
        margin: 15px 0; 
        font-family: $az-heading-two;
        color: $az-inspection-black;
        text-transform: uppercase;
        font-size: $az-font-17;
        list-style: none;
        display: flex;
        span{
          p{
            margin-bottom: 0;
            font-family: $az-heading-two;
          }
        }
      }
    }
  }
}

.per_checklist_modal.modal{
  .single_property_mod_dialog {
    max-width: 450px;
  }
  .per_checklist_modal_dialog{
    max-width: 650px;
  }

}

.modal {
  .modal-dialog{
    .modal-content{
      .checklist_scroll_carousel{
          .styles-module_sliderBase__swkx1{
            width: 100% !important;
        }
        .swipesection {
          .nav-pills {
            .nav-item{
              .nav-link{
                border-radius: 20px !important;
                padding: 5px 10px !important;
                background: $az-gray !important;
                color: $az-white !important;
                &.active{
                  background: $gf-primary-color !important;
                  &::after{
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      button{
        &.btn-outline-light{
          max-width: fit-content !important;
        }
      }
      .modal-body{
        .multiselect {
          .css-26l3qy-menu{
            z-index: 999;
          }
        }
        .tabNavigation{
          max-height: 500px;
          overflow-x: auto;

          table{
            &.checklist_inspect_table{
              tbody{
                tr{
                  th{
                    background: $az-white !important;
                    vertical-align: middle;
                    border-top: none;
                    border-bottom: 1px solid $az-border-md;
                    a{
                      display: block;
                      color: $gf-primary-color;
                      font-family: $az-heading-two;
                      font-size: $az-font-15;
                      span{
                        display: block;
                        color: $az-gray;
                      }
                    }
                    // &:first-child{
                    //   border-top: 1px solid $az-border-md;
                    // }
                  }
                  td{
                    vertical-align:middle;
                    border-top:none;
                    border-bottom: 1px solid $az-border-md;
                    text-align: right;
                    a{
                      display:inline-block;
                      font-family:$az-heading-two;
                      font-size:$az-font-15;
                      img{
                        margin-bottom:0;
                        width:auto;
                        height:18px;
                      }
                    }
                    
                  }
                  &:hover{
                    background-color:$az-white !important;
                  }
                  &:last-child{
                    th,td{
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
        }
        p{
          margin-bottom: 0px !important;
        }
        h3{
          font-family: $az-heading-para !important;
        }
      }
    }
  }
  &.editor_common_modal{
    .modal-dialog{
      max-width: 525px;
      .modal-body{
        box-shadow: 10px 9px 22px 15px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
        .editor_modal_popup{
          .close{
            cursor: pointer;
            margin-top: -13px;
            margin-right: -13px;
          }
        }
        button{
          min-width: unset !important;
          &.remove_link_btn{
            max-width: 125px;
          }
        }
      }
    }
  }
}
.inspect_button_new{
  background: $az-border-light!important;
    color: $az-black !important;
}
.inspect_button_yes{
  background: $az-active-status !important;
    color: $az-white !important;
}
.inspect_button_No{
  background: $az-inactive-status!important;
    color: $az-white !important;
}
.inspect_button_NA{
  background: $az-gray!important;
    color: $az-white !important;
}
.inspect_button_Note{
  background: $gf-primary-color !important;
    color: $az-white !important;
}

.note_image_box{
  input[type = "file"]{
    padding-left: 0 !important;
  }
}

ul.img_view_loop{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  li {
    a{
        img.brand_list_image{
          width: 71px !important;
          height: 71px;
          border-radius: 5px;
          object-fit: cover;
          object-position: center;
        }
    }
    .img_close{
      padding-left: 0 !important;
      margin-right: 15px !important;
      margin-top: 10px;

      .btn.btn-danger.btn_remove{
        padding: 12px 10px 12px 12px !important;
        background: gray;
        border: 0;
        border-radius: 50%;
        height: 20px !important;
        width: 24px;
        &:hover{
          background: gray !important;
          border: 0 !important;
        }
      }
    }
  }
}

.card-dashboard-sixteen{
  .card-body{
    .table-responsive{
      table{
        tr:first-child{
          td:first-child{
            h6{
              width: auto;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @media (max-width: 1440px){
                width: 135px;
              }
              @media (max-width: 1385px){
                width: 120px;
              }
              @media (max-width: 1330px){
                width: 110px;
              }
            }
          }
        }
        tr:last-child{
          td:first-child{
            h6{
              width: auto;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @media (max-width: 1440px){
                width: 135px;
              }
              @media (max-width: 1385px){
                width: 120px;
              }
              @media (max-width: 1330px){
                width: 110px;
              }
            }
          }
        }
        tr{
          h6{
            @media (max-width: 1330px){
              // width: 110px;
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
            }
            @media (max-width: 1280px){
              // width: 100px;
            }
          }
        }
      }
    }
  }
}


@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .box {
      .btn-component input {
        height: 40px !important;
      }
    }
  }

  table{
    &.rolepermission{
      .select_all_cus_col{
        padding-left: 5px !important;
      }
      .select_all_tbl{
        display: flex;
        align-items: center;
        color: #808080;
        font-family: Roboto-Regular;
        font-size: 15px;
        font-weight: normal;
        text-transform: capitalize;
        letter-spacing: 0px;
      }
  
      .permission_title_col{
        vertical-align: middle;
        color: #808080;
        font-family: Roboto-Regular;
        font-size: 15px;
        font-weight: normal;
        text-transform: capitalize;
        letter-spacing: 0px;
      }

      tbody{
        tr{
          &:hover{
            .form-label{
              color: $az-white;
            }
          }
        }
      }
    }
  }

  .modal{
    &.room_popup_modal{
      .modal-dialog{
        max-width: 550px;
        .modal-content{
          .modal-body{
            .room_popup_btn{
              display: flex;
              align-items: center;
              height: 100%;
              button{
                background: none;
                border: none;
                outline: none;
                box-shadow: none;
              }
            }
            img{
              width: auto;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .export_pdf_btn{
    img{
      margin-top: -4px;
    }
  }


  .width_402.border_radius_14.no_img_pop.modal-body {
    height: 305px;
    place-content: center;
  }

  .quill{
    &.custom_editor_style{
      .ql-container{
        min-height: 135px;
        p{
          color: $az-black !important;
          font-size: $az-font-16;
          margin-bottom: 3px !important;
          a{
             font-family: $az-heading-one-rg;
             cursor: pointer;
          }
        }
      }
    }
  }

  /* TextEditor.css */
.modalquill {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensure the modal is on top */
}

.modalquill-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.modal .modal-dialog .modal-content .modal-body p.note_task_name_box{
  color: $az-black !important;
}

.modal .modal-dialog .modal-content .modal-body p.note_task_name_box p{
  color: $az-black !important;
  margin: 0 !important;
}

.task-name-span{
  font-family: $az-heading-one-rg !important;
  cursor: pointer;
}

.qes_ans_box.crete_mro_checkbox{
  margin-left: 0 !important;
}

#insp_form {
  p.titleLable{
    p{
      margin: 0 !important;
      color: $gf-primary-color;
      font-family: $az-heading-two;
      font-size: $az-font-17;
    }
  } 
}

.insp_inner_p p{
  margin: 0 !important;
}

.modal .modal-dialog .modal-content .modal-body p.editor_text_box p{
  margin: 0 !important;
}

.mro_ttl_p p{
  margin: 0 !important;
}

table tbody tr:hover td p{
  color: $az-white !important;
}

table tbody tr:hover td a{
  color: $az-white !important;
}

table tbody tr:hover td b{
  color: $az-white !important;
}

table tbody tr:hover td strong{
  color: $az-white !important;
}

table tbody tr:hover td b.status-active{
  color: #009d24 !important;
}

table tbody tr:hover td b.status-inactive{
  color: #cc0000 !important;
}

.insp_inner_p a{
  font-family: $az-heading-one-rg !important;
}

.insp_inner_p b{
  font-family: $az-heading-one-rg !important;
}

.insp_inner_p i{
  font-family: $az-heading-one-rg !important;
}

.text_para_inn p{
  margin: 0 !important;
}
.daily_log_image_upload{
  margin-top: 20px;
  .multiImgBuuton {
    .file_widget_class{
      img{
        width: 71px !important;
      }
    }
  }
  .multiImgsection {
    flex-wrap: wrap;
    img{
      width: 71px !important;
      height: 71px !important;
      margin-bottom: 0 !important;
    }
    .list-group-item{
      margin: 0 15px 30px;
    }
  }
  .row {
    &.pd-l-20{
      padding-left: 0;
    }
  }
}
.daily_log_info_imgbox{
  display: flex;
  .img_close{
    &:first-child{
      padding-left: 0;
    }
  }
}

.add_btn_fst {
    right: 28px;
    position: relative;
}

.audit_check_box{
  .check_cust{
    top: 3px !important;
    z-index: 1;
    .checkmark {
      top: 7px !important;
    }
  }
}

.insp_cat_list_box{
  color: $gf-primary-color !important;
  font-size: $az-font-17 !important;
}

.display_none{
  display: none;
}

.position_relative{
  position: relative;
}

.background_white{
  background: #fff;
}

.margin_left32{
  margin-left: 32px;
}

.donut_mro_box .chartjs-render-monitor {
  cursor: pointer;
}

table.rolepermission {
  tr{
    td{
      .col-md-2{
        .form-label{
          word-break: break-all;
          width: max-content;
        }
        &:nth-child(2){
          flex: 0 0 10%;
          max-width: 10%;
          @media (max-width:1024px) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 10px;
          }
        }
        &:nth-child(3){
          flex: 0 0 13%;
          max-width: 13%;
           @media (max-width:1024px) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 10px;
          }
        }
        &:nth-child(4){
          flex: 0 0 11%;
          max-width: 11%;
          @media (max-width:1024px) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 10px;
          }
        }
        &:last-child{
          @media (max-width:1024px) {
            margin-top: 10px;
          }
        }
      }
    }
  }
  thead{
    .role_fst_td{
      width: 200px;
      min-width: 200px;
    }
  }
}

.checklist-custom-btnBox{
  position: relative;
  display: flex;
    .new_btn_add{
      position: static;
      // left: 0;
      // right: auto;
      // margin-left: 50px;
    }
  .add_btn_fst{
    position: static;
    // left: 0;
    // right: auto;
    // z-index: 9;
  } 
  .trash-btnBox {
    order: 2;
  }
}

.audit-custom-BtnBox{
  display: flex;
  align-items: baseline;
  position: relative;
  top: -8px;
  .add_btn_fst,button,.deleteCat{
    position: static;
  }
  .add_edit_del_box{
    order: 2;
  }
}

.dashboard{
  .dashboard-section-box{
    .card-dashboard-sixteen{
      height: 100%;
      .table {
        tbody {
          tr{
            &.no-recrod-found{
              text-align: center;
            }
          }
        }
      }
    }
    .dash_middle_sec{
      .inspection-icon, .audit-icon{
        width: 66px;
      }
      h2{
        .total-title-inspection{
          display: block;
          color: gray;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
}

.filter_bg_cust_common{
  margin-top: 5px;
  span{
    font-size: $az-font-14;
    display: inline-block;
  }
  .custom_clear_flt_btn{
    display: inline-flex !important;
  }
}

.spinnerLoader1 {
  background-color: rgba(0, 0, 0, 0.4); /* Make background transparent */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
  z-index: 99999;
  text-align: center;
  vertical-align: center;
}

.spinnerLoader1 .toploader1 {
  margin: 20% auto;
  text-align: center; 
  height: 180px;
  width: 180px;
  min-width: 180px;
  background: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 90%;
    border-radius: 36%;
    margin-bottom: 12px;
  }
}
.checklist_table_heading {
  display: flex;
  align-items: center;
}

.checklist_table_heading .rspan {
  display: flex;
  align-items: center;
}
a{
  &.global-flt-clearBtn{
    display: inline-block;
    background: #272E79;
    color: #ffffff;
    font-family: Oswald-Medium;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 8px;
    padding: 4px 10px 4px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    margin-left: 10px;
  }
}

.scrolling-carousel{
  .status-buttons{
    margin-bottom: 3px;
    button{
      background: none;
      border-radius: 0;
      font-family: Oswald-Regular;
      color: grey;
      font-size: 16px;
      position: relative;
      border: none;
      margin-right: 22px;
      text-transform: uppercase;
      padding: 2px 0 5px;

      &.active{
        color: #272e79;
        &::after{
          border-bottom: 1px solid #272e79;
          width: 100%;
          height: 1px;
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          text-align: center;
          transform: translate(-50%);
        }
      }
    }
  }
}

.authentication{
  .az-signin-header {
    h3 {
      font-size: $az-font-30 !important;
    }
  }
}

.brandList {
  .asrt-table-head {
    .table_filter {
      input[type="search"]{
        padding-left: 25px !important;
      }
    }
  }
}

.property-insp-audit-box{
  .card-dashboard-ratio{
    .card-body {
      div{
        &:first-child{
          min-width: 72px;
          width: 72px;
        }
      }
    }
  }
  .property-user-title{
    width: 115px;
    min-width: 115px;
    font-size: $az-font-30;
    span{
      color: $gf-gray-secondary;
      display: block;
      font-weight: 700;
      margin-bottom: 5px;
      font-size: $az-font-14;
    }
  }
}

.az-rightbar {
  .gf-primary-btn-small-one {
    &.delete-inspection-btn{
      background-color: $az-inspection-red !important;
      &:hover{
        background-color: $az-modl_dange_btn !important;
      }
    }
  }
}

.custom-table-box{
  .table-responsive{
    &.no-record-data{
      height: 100%;
      table{
        height: 100%;
        tbody{
          height: 100%;
          .no-recrod-found{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $az-font-16;
          }
        }
      }
    }
  }
}

.dashboardRightcard {
  .form-label{
    &.tracker-table-label{
      font-family: $az-heading-two;
    }
  }
}

.excluded_icon{
  font-size: 12px;
  padding: 2px 8px;
  top: -1px;
}
.width4Img{
  width: 65px;
  margin-right: 10px;
}
.auditAi{
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}
.auditAi .AiPara{
  margin: 0;
  color: #272E79;
}
.image-container {
  display: inline-block;
  margin-right: 10px; /* Adjust spacing between images */
}
.custom-image-magnify div:last-child {
  z-index: 10;
  border: none !important;
  margin-left: 0 !important;
  box-shadow: 0 0 10px #1c273c0f;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.pop-up-image-megnify{
  width: 70px !important;
  height: 70px !important;
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin-bottom: 10px !important;
  object-fit: cover;
  margin-left: 5px;
  margin-right: 5px;
  .custom-image-magnify > img:first-of-type{
    border-radius: 8px;
    height: 70px !important;
    width: 70px !important;
  }
}
.blocked_user .az-toggle.on {
  background-color: #de0000 !important;
}
@media (max-height: 700px){
  .order_form{
    .filter-box-scroll{
      overflow-y: auto;
      height: calc(100vh - 135px);
    }
  }
}

.modal .modal-dialog .modal-content button.btn-success.export_pdf_btn {
  width: auto;
  max-width: fit-content;
  min-width: auto;
  &:hover{
    img{
      filter: unset !important;
    }
  }
  img{
    width: auto !important;
  }
}
.common_delete_btn{
  border: 2px solid #ff5145 !important;
  box-shadow: none;
  font-size: 16px !important;
  border-radius: 8px !important;
  padding: 9px 15px 6px !important;
  outline: none;
  background-color: #ff5145 !important;
  color: #ffffff !important;
  height: 40px !important;
  line-height: normal !important;
  text-transform: uppercase;
  &:hover, &:focus{
    background-color: #ff5145 !important;
    border-color: #ff5145 !important;
  }
}

.Collapsible-arrow {
  float: right;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.fa-angle-down{
  font-size: 20px;
}
.fa-angle-up{
  font-size: 20px;
}

.az-rightbar {
  .tableFormatesimple{
    caption{
      &.table-accord-caption{
        margin-bottom: 10px;
      }
    }
  }
}


