/* ###### 6.4 Header  ###### */

.dashboard {
  height: 100vh;
  background: $az-xl-light-white !important;
}

.az-header-menu-show {
  @include media-breakpoint-down(md) {
    overflow: hidden;

    .az-header-menu {
      // border-right: 1px solid rgba($gray-900, 0.08);
      box-shadow: 0 0 30px rgba($gray-900, 0.08);
      transform: translateX(0);
    }

    .az-navbar-backdrop {
      visibility: visible;
      opacity: 1;
    }
  }
}
.az-sidebar {
  width: 220px !important;
  @include transition(all 0.35s);
  // background: $az-solid-brown;
  background: $gf-md-black-secondary;
}
.az-header {
  height: $az-height-header-mobile;
  background-color: #fff;
  box-shadow: 0 0 30px rgba($gray-900, 0);
  border-bottom: 1px solid rgba($gray-900, 0.12);

  @include media-breakpoint-up(lg) {
    position: fixed;
    z-index: 100;
    height: 100%;
    border-bottom: none;
    width: inherit;
    // overflow: auto;
    background: #333333;
  }

  > .container,
  > .container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-up(lg) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  > .container {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    @include media-breakpoint-down(md) {
      max-width: none;
    }
  }

  .az-header-left .az-logo {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      padding: 10px 20px;
    }
  }
}

.az-header-left {
  display: block;
  align-items: center;

  .az-header-arrow {
    display: none;
    position: relative;
    top: -2px;
  }
}

.az-logo {
  font-weight: 700;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  text-transform: lowercase;
  color: $az-color-primary;
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  position: relative;
  top: -2px;

  @include hover-focus() {
    color: $az-color-primary;
  }

  i {
    display: block;
    line-height: 0.9;
    margin-right: 8px;
    font-size: 40px;

    &::before {
      width: auto;
      height: auto;
    }
  }
}

.az-header-menu-icon {
  margin-right: 10px;
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    margin-right: 25px;
  }

  span {
    display: block;
    position: relative;
    width: 15px;
    height: 2px;
    background-color: $gray-900;
    @include border-radius();
    @include transition($transition-base);

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 20px;
      height: 2px;
      background-color: $gray-900;
      @include border-radius();
      @include transition($transition-base);
    }

    &::before {
      top: -6px;
    }
    &::after {
      bottom: -6px;
    }
  }
}

.az-header-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $az-width-sidebar;
  // background-color: $az-solid-brown !important;
  background-color: $gf-md-black-secondary !important;
  height: calc(100vh - 100px);
  position: relative !important;
  overflow-y: auto !important;
  z-index: 9999;
  transform: translateX(0);
  @include transition(all 0.35s);
  padding: 10px 0px 54px;
  padding-top: 0;
    .media{
      .profil_img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
    }
    .media-body{
      p{
        color: $az-white;
        font-family: $az-heading-para;
        // font-size: $az-font-18 !important;
        font-size: $az-font-18 !important;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        padding-top: 5px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    align-self: stretch;
    background-color: transparent;
    border-right: 0;
    position: static;
    width: auto;
    overflow-y: visible;
    transform: none;
    z-index: auto;
    > .nav {
      width: 100%;
      .nav-item {
        display: block;
        align-self: stretch;
        width: 100%;
        &.dropdown {
          position: static;
        }
        &.dropdown-mega {
          position: static;
        }
      }
    }
  }

  > .nav {
    flex-direction: column;
    padding: 20px;

    @include media-breakpoint-up(lg) {
      padding: 0;
      flex-direction: column;
      align-items: center;
    }
  }
  .az-sidebar-loggedin {
    padding: 10px 0;
  }
  nav {
    &.az-menu-sub {
      &.dropdown-menu {
        @include media-breakpoint-down(md) {
          position: static !important;
          transform: translate(0) !important;
          border-width: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

  .nav-item {
    &::before {
      content: "";
      position: absolute;
      top: $az-height-header + 3px;
      margin-left: 25px;
      width: 15px;
      height: 15px;
      border-top: 2px solid rgba($gray-900, 0.12);
      border-left: 2px solid rgba($gray-900, 0.12);
      background-color: #fff;
      transform: rotate(45deg);
      z-index: 10001;
      display: none;
    }

    + .nav-item {
      margin-top: 10px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 0px;
      }
    }

    > .nav-link {
      align-items: center;
      padding: 8px 22px;
      cursor: pointer;
      border-bottom: none;
      @include transition($transition-base);
      // color: #cf8f93;
      color: $az-white;
      font-family: $az-heading-para;
      // font-size: $az-font-18 !important;
      font-size: $az-font-16 !important;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      margin:0;

      &:hover {
        color: $az-white;
        background: $az-gray !important;
        padding: 8px 22px;
        margin: 0;
      }

      &:first-child {
        padding: 8px 22px;

        &:hover {
          color: $az-white;
          background:$gf-primary-color;
          padding: 8px 22px;
          margin: 0;
        }
      }
      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }

      @include hover() {
        color: $az-white;
        i {
          color: $az-white;
        }
      }

      &.with-sub::after {
        content: "\f3d0";
        display: inline-block;
        font-family: "Ionicons";
        font-size: 12px;
        font-weight: 400;
        margin-left: auto;
        margin-top: 3px;
        opacity: 0.5;
        border: 0;

        @include media-breakpoint-up(lg) {
          margin-left: 5px;
        }
      }

      .typcn {
        font-size: 24px;
        line-height: 0.95;
        margin-right: 15px;
        width: 16px;

        @include media-breakpoint-up(lg) {
          font-size: 24px;
          margin-right: 7px;
        }

        &::before {
          width: auto;
        }
      }
    }

    &.active {
      > .nav-link {
        color: $az-white;
        position: relative;
        background: $az-solid-brown;
        padding: 8px 22px;
        margin: 0;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          top: 0;
          left: -20px;
          width: 2px;
          background-color: transparent;
          @include border-radius();

          @include media-breakpoint-up(lg) {
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            width: auto;
            height: 2px;
          }
        }
      }
    }

    &.show {
      &::before {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
  }
}

.az-header-menu-header {
  height: $az-height-header-mobile;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:15px 47px 15px 22px;
  border-bottom: 1px solid rgba($gray-900, 0.05);
  background: $az-white;
  .close {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    .close {
      display: none;
    }
  }
  .close {
    font-weight: 300;
    font-size: 28px;
  }
}

.az-menu-sub {
  font-size: 13px;
  margin-left: 32px;
  margin-top: 12px;
  display: none;
  position: relative !important; // Overrideing react-bootstrap inline css
  transform: translate3d(0px, 0, 0px)!important; // Overrideing react-bootstrap inline css
  margin-bottom: 20px!important; // Overrideing react-bootstrap inline css
  @include media-breakpoint-down(md) {
    border: 0;
    position: static !important;
    transform: none !important;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 10px !important; // Overrideing react-bootstrap inline css
    background-color: #fff;
    padding: 15px 20px;
    border:none;
    border-left: 2px solid rgba($gray-900, 0.12);
    min-width: 200px;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .nav {
    flex-direction: column;
  }

  .nav-link {
    padding: 0;
    display: block;
    color: $gray-700;

    + .nav-link {
      margin-top: 6px;
      padding-top: 6px;
      border-top: 1px dotted $gray-200;
    }

    @include hover-focus() {
      color: $az-color-primary;
    }

    &.active {
      color: $az-color-primary;
      font-weight: 500;

      @include media-breakpoint-up(lg) {
        font-weight: 400;
      }
    }
  }
}

.az-menu-sub-mega {
  top: 0 !important; //Overrideing react-bootstrap inline css
  left: 0 !important; //Overrideing react-bootstrap inline css
  right: 0 !important; //Overrideing react-bootstrap inline css
  transform: translate(0) !important; //Overrideing react-bootstrap inline css
  background-color: transparent;
  padding: 0;
  border: 0;
  position: relative!important; //Overrideing react-bootstrap inline css

  .container {
    padding: 0;

    @include media-breakpoint-down(md) {
      max-width: none;
    }
    @include media-breakpoint-up(lg) {
      display: block;
      background-color: #fff;
      border-left: 2px solid rgba($gray-900, 0.12);
    }

    > div {
      @include media-breakpoint-up(lg) {
        display: block;
        padding: 20px;
        flex: 1;
        margin-top: 0;
      }

      @include media-breakpoint-up(xl) {
        padding: 20px 25px;
      }

      + div {
        margin-top: 25px;
        @include media-breakpoint-up(lg) {
          margin-top: 0;
          border-left: 1px solid rgba($gray-900, 0.05);
        }
      }
    }
  }

  .nav {
    min-width: 120px;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      min-width: 140px;
    }

    + .nav {
      border-top: 1px dotted $gray-200;
      margin-top: 6px;
      padding-top: 6px;

      @include media-breakpoint-up(lg) {
        margin-left: 0px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 0px;
        margin-top: 0;
      }
    }

    > span {
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      color: $gray-900;
      letter-spacing: 0.5px;
      margin-bottom: 15px;
      margin-top: 30px;

      @include media-breakpoint-up(lg) {
        font-size: 12px;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.az-header-center {
  flex: 1;
  margin: 0 50px;
  position: relative;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  .form-control {
    border-color: $gray-200;
    border-width: 2px;
    background-color: #fcfcfc;
    transition: none;
    height: $az-height-base + 2px;

    &:focus {
      background-color: #fff;
      border-color: $gray-400;
      box-shadow: none !important;

      + .btn {
        color: $gray-600;
      }
    }
  }

  .btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    height: $az-height-base + 2px;
    color: $gray-400;
    transition: none;
    font-size: 16px;
    padding-right: 13px;

    @include hover-focus() {
      color: $gray-900;
    }
  }
}

.az-header-right {
  display: flex;
  align-items: center;

  .btn-social {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-500;
    @include border-radius();
    @include transition($transition-base);

    @include hover-focus() {
      color: $gray-700;
    }

    i {
      font-size: 18px;
      line-height: 0;
    }

    + .btn-social {
      margin-left: 10px;
    }
  }

  .btn-buy {
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    font-size: 11px;
    font-weight: 500;
    background-color: $az-color-primary;
    color: #fff;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    @include border-radius();
    @include transition($transition-base);
    margin-left: 20px;

    @include hover-focus() {
      background-color: darken($indigo, 5%);
    }
  }
}

.az-header-search-link {
  font-size: 18px;
  color: $gray-700;
  line-height: 1;
  margin-right: 15px;
}

.az-header-arrow {
  font-size: 27px;
  color: $gray-900;
  display: inline-block;
  line-height: 0;

  i {
    line-height: 0;
  }

  @include hover-focus() {
    color: $gray-700;
  }
}

.az-header-message,
.az-header-notification {
  position: relative;

  > a {
    display: block;
    font-size: 24px;
    color: $gray-900;
    position: relative;
    line-height: 0.9;
    outline: none;

    &.new {
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 2px;
        width: 8px;
        height: 8px;
        background-color: $gf-primary-color;
        @include border-radius(100%);

      }
    }
  }
}

.az-header-notification {
  margin-left: 15px;

  > a {
    &.new {
      &::after {
        content: "";
        position: absolute;
        top: 47px;
        left: 50%;
        margin-left: -7px;
        width: 14px;
        height: 14px;
        border: 2px solid transparent;
        border-top-color: $gray-300; //$az-border-color;
        border-left-color: $gray-300; //$az-border-color;
        transform: rotate(45deg);
        background-color: #fff;
        z-index: 901;
        display: none;

        @include media-breakpoint-up(lg) {
          bottom: -36px;
        }
      }
    }
  }

  .dropdown-menu {
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 20px;
    border-width: 0;
    margin-top: 0;
    z-index: 900;
    @include border-radius(0);

    @include media-breakpoint-up(sm) {
      width: 300px;
      position: absolute;
      top: 28px !important; //Overrideing react-bootstrap inline css
      left: auto !important; //Overrideing react-bootstrap inline css
      right: -10px;
      bottom: auto;
      transform: none !important;
      padding: 8px;
      border-color: $gray-300;
      border-width: 2px;
      transform: translate(-260px, 25px) !important;

    }

    @include media-breakpoint-down(sm) {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      transform: translate(0, 0) !important;
      transform: translate(-0px, 25px) !important;
    }
    .nofi_header_top{
      display: flex;
      justify-content: space-between;
      place-items: baseline;
      margin-bottom: 10px;
      .notify_top_btn{
        display: inline-flex;
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        li{
          padding-left:10px ;
          a{
            font-size: $az-font-12;
            line-height: 1;
            color: $gf-primary-color;
            font-weight: 700;
            text-transform: uppercase;
          }
        }
      }
    }

  }

  .dropdown-footer {
    text-align: center;
    padding-top: 10px;
    font-size: $font-size-sm;
    border-top: 1px dotted $gray-400;
    a{
      font-size: $az-font-12;
      font-weight: 500;
      line-height: 1;
      color: $gf-primary-color;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &.show {
    > a::after {
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .dropdown-menu {
      display: block;
      left: 0 !important;
    }
  }
}

.az-notification-title {
  font-size: $az-font-14;
  margin-bottom: 5px;
  line-height: 1;
  color: $gf-primary-color;
  font-weight: 700;
  text-transform: uppercase;
}

.az-notification-text {
  font-size: $font-size-sm;
  margin-bottom: 15px;
}

// .az-notification-list {
//   height: 60vh;
//   overflow-x: hidden;
//   overflow-y: auto;

//   .media {
//     padding: 10px 0;
//     position: relative;

//     + .media {
//       border-top: 1px dotted $gray-400;
//     }

//     &.new {
//       color: $gray-900;
//     }

//     @include hover-focus() {
//       cursor: pointer;
//       z-index: 1;

//       &::before {
//         content: "";
//         position: absolute;
//         top: -1px;
//         left: -20px;
//         bottom: -1px;
//         right: -20px;
//         background-color: $gray-100;
//         border-top: 1px solid $gray-300;
//         border-bottom: 1px solid $gray-300;
//         z-index: -1;
//       }
//     }
//   }

//   .media-body {
//     margin-left: 15px;
//     font-size: $font-size-sm;

//     p {
//       margin-bottom: 5px;
//       line-height: 1.3;
//     }

//     strong {
//       font-weight: $font-weight;
//     }
//     span {
//       display: block;
//       font-size: 11px;
//       color: $gray-500;
//     }
//   }
// }
.az-notification-list {
  height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;

  .media {
    padding: 10px 0;
    position: relative;

    &:first-child{
      padding-top: 0;
    }

    + .media {
      border-top: 1px dotted $gray-400;
    }

    &.new {
      color: $gray-900;
    }

    @include hover-focus() {
      cursor: pointer;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -20px;
        bottom: -1px;
        right: -20px;
        background-color: $gray-100;
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        z-index: -1;
      }
    }
  }

  .media-body {
    margin-left: 15px;
    font-size: $font-size-sm;

    p {
      margin-bottom: 5px;
      line-height: 1.3;
    }

    strong {
      font-weight: $font-weight;
    }
    span {
      display: block;
      font-size: 11px;
      color: $gray-500;
    }
  }
}

.az-profile-menu {
  position: relative;
  margin-left: 15px;

  > .az-img-user {
    outline: none;
    width: 32px;
    height: 32px;

    &::before {
      content: "";
      position: absolute;
      bottom: -33px;
      left: 50%;
      margin-left: -7px;
      width: 14px;
      height: 14px;
      border: 2px solid transparent;
      border-top-color: $gray-300; //$az-border-color;
      border-left-color: $gray-300; //$az-border-color;
      transform: rotate(45deg);
      background-color: #fff;
      z-index: 901;
      display: none;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 20px 5px;
    border-width: 0;
    margin-top: 0;
    z-index: 900;
    @include border-radius(0);

    @include media-breakpoint-up(sm) {
      padding-top: 20px;
      position: absolute;
      top: 56px !important;
      left: auto !important;
      right: -10px;
      bottom: auto;
      transform: none !important; //Overrideing react-bootstrap inline css
      width: 230px;
      border-color: $gray-300;
      border-width: 2px;
    }

    @include media-breakpoint-down(sm) {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      transform: translate(0, 0) !important;
    }
  }

  .dropdown-item {
    position: relative;
    padding: 0;
    height: $az-height-base;
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    color: $gray-700;

    i {
      font-size: 24px;
      margin-right: 10px;
      width: 24px;
      text-align: center;

      &.typcn {
        line-height: 0.9;
      }
    }

    @include hover-focus() {
      background-color: transparent;
      color: $az-color-primary;
    }

    + .dropdown-item {
      border-top: 1px dotted $gray-400;
    }

    &:last-child {
      margin-bottom: 10px;
    }
  }

  &.show {
    .az-img-user::before {
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
    .dropdown-menu {
      display: block;
    }
  }
}

.az-header-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .az-img-user {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
      width: 80px;
      height: 80px;
    }

    &::after {
      display: none;
    }
  }

  h6 {
    font-size: 24px;
    font-weight: 700;
    color: $gray-900;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }

  span {
    display: block;
    font-size: $font-size-sm;
  }
}

// New
.az-header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.az-header-top {
  padding: 0 20px 30px 20px;
}
  .az-header-menu-show {
    .az-sidebar {
      display: none;
      @media (max-width: 991px) {
        display: block;
        visibility: visible;
        transform: translateX(0px);
      }
    }
  }

.az-rightbar {
    width: calc(100% - 220px) !important;
    background: $az-xl-light-white !important;
}

.az-login-rightbar {
  width: 100% !important;
  background: $az-white !important;
}

.menu_line{
  width: 90%;
  opacity: 0.4;
  border-top: 2px solid #FFFFFF;
  height: 2px;
  margin:5px 0 ;
}

.az-header-menu .nav-item.active > .nav-link{
  background: #f2f2f2 !important;
  color:$gf-primary-color !important;
}

.menu_heading{
    color: #FFF;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    margin: 0;
    font-family: $az-heading-two;
    text-transform: uppercase;
    width: 100%;
    background: #000;
    padding: 10px 22px;
    color: #fff;
    margin-top: 24px;
}

.menu_heading:first-child{
  margin-top: 0;
}