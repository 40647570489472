.brandList {
    position: relative;
    .asrt-table-foot{
        .col-md-6 {
        &:first-child {
        position: relative;
        top: 10px;
        font-size: $az-font-14;
        font-family: $az-heading-one-rg;
        letter-spacing: 0;
        color: $gf-gray-secondary;

        @media(max-width:767px){
            text-align: center;
            margin-bottom: 20px;
        }
        }   
        &:last-child {

            .pagination {
                li{
                    font-family: $az-heading-one-rg;
                    letter-spacing: 0;
                    color: $gf-gray-secondary;
                    a { background: none; }
                    &:first-child {
                        padding-right: 13px;
                    }
                    &:last-child {
                        padding-left: 13px;
                        padding-right: 0px;
                    }
                }
                li.active
                a {
                   border: none;
                   color: $az-black !important;
                   border-radius:none;
                   &:focus, &:active, &:visited {
                       box-shadow: none;
                   }
                }
               
                @media(max-width:767px){
                   justify-content: center !important;
                }

                .page-item.disabled {.page-link {
                    color: $az-gray;
                    letter-spacing: 0;
                 }
              }
            }
        }  
      }
     
    }
    .asrt-table-head {
    .table_filter {
        position: relative !important;
        width: 313px ;

        @media (max-width:767px){
            width: 100% !important;
        }

          input[type="search"] {
            padding-left: 25px ;
            // border: 1px solid $gf-gray-secondary !important;
            border-radius: 0px !important;
            background: #fff !important;
            font-size: $az-font-16 ;
            border: 0 !important;
           border-bottom: .2px solid #80808038 !important;
            padding-top: 6px;
            padding-right: 0;
            &::placeholder {
                font-size: $az-font-14 ;
                letter-spacing: 0;
                color: #cccccc !important;
            }
          }
          &:before{
            position: absolute;
            left: 0px;
            background: url(./../../images/Search.svg);
            background-repeat: no-repeat;
            content: '';
            width: 18px;
            height: 18px;
            z-index: 0;
            top: 5px;
            background-size: 100% 100%;
       }
      }
      .asrt-page-length {
          .input-group-addon { 
            background: none !important;
              .input-group-text {
              color: $gf-gray-secondary;
              font-size: $az-font-14;
              letter-spacing: 0;
              font-family: $az-heading-one-rg;
              }
            }
          select {
              width: auto !important;
              -webkit-appearance: none;
              -moz-appearance: none;
              border-radius: 0px !important;
              text-align: center;
              background: $az-white !important;
              padding-top: 5px !important;
              padding-right: 5px !important;
              border: 0 !important;
           border-bottom: .2px solid #80808038 !important;
            }
      }
   }
   .asrt-table-body {

      @media (max-width:1199px){
        overflow: auto;
        width: 100%;
       }
       @media (max-width:991px){
        overflow: auto;
        width: 100%;
       }
       @media (max-width:767px){
        overflow: auto;
        width: 100%;
       }
      
   table {
       thead{
           th{
               padding: 14px 0;
               font-size: $az-font-15;
               font-family: $az-heading-one-rg;
               color: $gf-gray-secondary;
               font-weight: 400;
               border-bottom: 1px solid $az-border-md;
               padding-left: 5px;
               letter-spacing: 0;
               &:last-child{
                   text-align: left;
               }

              
               
           }
           th.asc {
                // background: none !important;
                span{
                    position: relative;
                    background-image:url(../../images/asc.svg) ;
                    left: 10px;
                    width: 21px;
                    height: 21px;
                    display: inline-flex;
                    top: 6px;
                    background-size: 100%;
                }
              }
    
           th.desc {
            // background: none !important;
            
            span{
                position: relative;
                background-image:url(../../images/desc.svg) ;
                left: 10px;
                width: 21px;
                height: 21px;
                display: inline-flex;
                top: 6px;
                background-size: 100%;
                }
            }


           .asc{ background-image:url(../../images/asc.svg) !important;}
           .desc{ background-image:url(../../images/desc.svg) !important;}
       }
       tbody{
           tr{
               background: none;
                td{
                    padding: 7px 0;
                    font-size: $az-font-16;
                    font-family: $az-heading-one-rg;
                    color: $gf-md-black;
                    font-weight: normal;
                    border-top: 1px solid $az-border-light;
                    &:last-child{
                        text-align: left;
                    }
                    a {
                        color: $az-black;
                    &:hover {
                        text-decoration: underline;
                        text-underline-offset: 0.3em;
                      }
                    }
                
                }
            .brand_list_image{
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    object-fit: cover;
                    border: 1px solid $az-light-gray;
                }
            }
        }
    }
    table.customerTable {
        thead{
            th{
                &:first-child {
                    width: 107px;
                }
            }
        }
    }
   }
   .as-react-table{

    @media (max-width:1199px){
       padding: 0 15px;
       }
       @media (max-width:991px){
       padding: 0 15px;
       }
       @media (max-width:767px){
       padding: 0 15px;
       }
   }
}

.baradForm { 
    .form-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid $az-border-color;
        margin-bottom: 0;
        input[type=file] {
            border: none;
           padding-left: 0;
           padding-right: 0;
           background: none !important;
        }
        .col-form-label {
            margin-bottom: 0;
            padding-left: 0;
        }
        &:last-child { border-bottom: none;}

        @include fileUploadeditpage;
    }
}
.brandSection {
    // position: relative;

    .order_form{
        position: fixed;
        right: -32px;
        left: auto;
        max-width: 360px;
        background: $az-white;
        margin: 0;
        z-index: 99999;
        border-radius: 0px;
        padding: 35px 30px 47px 15px;
        top: 0px;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        box-shadow: none !important;
        height: 100vh;
        flex-direction: column;
       
        h3 {
            font-size: 24px;
            font-family: $az-heading-one-rg;
            color: $az-solid-brown;
            margin-bottom: 30px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: start;
            text-transform: capitalize;
            line-height:12pt;
        }

        .btn-success {
            @include gf-btn-lg-stroke;
            min-width: 155px;
            max-width: 155px;
          }
          .btn-success-outline {
            @include gf-btn-lg-outline;
            min-width: 115px;
            max-width: 115px;
          }
          label{
            color: $gf-gray-secondary;
            font-family: $az-heading-one-rg;
            font-weight: normal;
            text-transform: capitalize;
            letter-spacing: 0px;
            font-size: 15px ;
          }

          input {
              border-bottom: 1px solid $az-black;
              border-left:none;
              border-right:none;
              border-top:none;
              border-radius: none !important;
              background: none !important;
          }
         
    }
}

.dashbaord-pageHeadertitle {  
    @include pageHeadertitle;

    .bothBtn{
        .btn-success-outline {
            @media(max-width:767px){
                min-width: 95px;
                max-width: 95px;
                margin-right: 0px;
              }
          }
          .btn-danger-outline {
            @media(max-width:767px){
                min-width: 95px;
                max-width: 95px;
                margin-right: 10px;
              }
          }

    } 
    .form-group {
        @media(max-width:767px){
           margin: 0;
        }
        label{
            text-align: right;
            color: $az-black;
            @media(max-width:767px){
                text-align: left;
            }
        }
    }
    
}

.page-item.disabled .page-link {
    color: $az-light-gray;
}

