
@media screen 
and (min-device-width: 2559px) 
and (max-device-width: 3840px){
  .authentication .az-card-signin{
    width:1000px !important;
  }
  .authentication .az-card-signin .az-logo img{
    width: 500px !important;
  }
  .authentication .az-signin-header h3{
    font-size:80px !important;
  }
  .authentication .az-signin-header form .form-group label {
    font-size: 35px !important;
  }
  .authentication .az-signin-header form .form-group input{
    font-size: 40px !important;
    height: 70px !important;
    border-bottom: 2px solid #80808038 !important;
  }
  .valid_info {
    font-size: 30px;
  }
  .authentication .az-signin-header form .form-group {
    margin-bottom: 30px !important;
  }
  .btn, .sp-container button, .form-wizard .container > div:not(.content) > button{
    min-height: 70px !important;
  }
  .authentication .az-signin-header form .button-green{
    font-size: 40px !important;
  }
  .authentication .az-signin-header form .az-signin-footer p a{
    font-size: 35px !important;
  }
  .authentication .az-signin-header form .form-group .showPassword span i{
    font-size: 35px;
  }
  .authentication .az-signin-header .p-dark{
    font-size: 35px !important;
  }
  .back-button {
    margin-right: 20px !important;
    width: 60px;
  }
  .container{
    max-width: 96% !important;
  }
  // .az-sidebar{
  //   width: 500px !important;
  // }
  // .az-header-menu-header .az-logo img{
  //   height: 175px !important;
  // }
  // .menu_heading{
  //   font-size: 50px;
  //   padding: 35px 22px;
  // }
  // .az-header-menu .nav-item > .nav-link{
  //   font-size: 40px !important;
  // }
  // .az-rightbar {
  //   width: calc(100% - 500px);
  // }
  // .dash_heading{
  //   font-size: 70px !important;
  //   padding: 55px 0 52px 0 !important;
  // }
  .card-dashboard-sixteen .card-title{
    font-size: 35px;
  }
  .dashboard .card-title {
    font-size: 16px;
  }
  .card-dashboard-sixteen .card-body h6{
    font-size: 30px;
  }
  .card-dashboard-sixteen .card-body small{
    font-size: 26px;
  }
  .card.h-chart_1 .list-unstyled li{
    font-size: 32px;
  }
  .h-chart_1 {
    height: 430px !important;
  }
  .az-rightbar .gf-primary-btn-small-one{
    height: 100% !important;
    font-size: 40px !important;
    padding: 20px 60px !important;
    margin: 0 10px !important;
    border-radius: 20px !important;
    height: 90px !important;
  }
  .az-rightbar .gf-primary-btn-small-two{
    height: 100% !important;
    font-size: 40px !important;
    padding: 20px 60px !important;
    margin: 0 10px !important;
    border-radius: 20px !important;
    height: 90px !important;
  }
  .brandList .asrt-table-head .table_filter input[type="search"]{
    font-size: 35px !important;
    height: 70px !important;
    border-bottom: 2px solid #80808038 !important;
    padding-left: 50px !important;
  }

  .brandList .asrt-table-head .table_filter input[type="search"]::placeholder{
    font-size: 35px !important;
  }
  .brandList .asrt-table-head .table_filter:before {
    width: 40px !important;
    height: 40px !important;
    top:16px !important;
    left: 6px !important;
  }
  .dashbaord-pageHeadertitle h3 {
    font-size: 60px !important;
  }
  .dashbaord-pageHeadertitle h3 span {
    font-size: 40px !important;
  }

  .brandList .asrt-table-head .table_filter{
    width: 550px !important;
  }
  input[type="search"]::-webkit-search-cancel-button {
    height: 20px !important;
    width: 20px !important;
    background-size: 20px 20px !important;
  }
  .brandList .asrt-table-head .asrt-page-length .input-group-addon .input-group-text{
    font-size: 35px !important;
  }
  .brandList .asrt-table-head .asrt-page-length select{
    height:70px !important ;
    font-size: 35px !important;
  }
  .brandList .asrt-table-body table thead th{
    font-size: 40px !important;
  }
  .brandList .asrt-table-body table tbody tr td{
    font-size: 41px !important;
  }
  .brandList .asrt-table-foot .col-md-6:first-child{
    font-size: 35px !important;
  }
  .brandList .asrt-table-foot .col-md-6:last-child .pagination li{
    font-size: 35px !important;
  }
  .modal .modal-dialog{
    max-width: 900px !important;
  }
  .dashboardRightcard{
    padding: 80px !important;
  }
  .modal .modal-dialog .modal-content .modal-body h3{
    font-size: 80px;
  }
  .asrt-pagination {
    margin: 0;
    padding-top: 18px;
  }
  .authentication .az-signin-wrapper{
    width: calc(100% - 1000px) !important;
  }
  .az-login-rightbar {
    width: 100% !important;
  }
  .btn_view{
    font-size: 28px;
    font-weight: normal;
    padding-top: 5px;
  }
  .dashboardRightcard .form-label{
    font-size:35px !important;
  } 
  .dashboardRightcard .titleLable{
    font-size: 42px !important;
  }
  input{
    font-size: 40px !important;
    height: 70px !important;
    border-bottom: 2px solid #80808038 !important;
  }
  .css-2b097c-container .css-1pahdxg-control{
    height: 70px !important;
  }

  .css-2b097c-container .css-yk16xz-control{
    font-size: 40px !important;
    height: 70px !important;
  }
  .css-g1d714-ValueContainer {
    height: 70px !important;
  }
  .css-g1d714-ValueContainer {
    height: 70px !important;
  }
  .css-1uccc91-singleValue {
    font-size: 40px !important;
  }
  .css-b8ldur-Input{
    height: 70px !important;
    color: #000 !important;
  }
  .css-1wa3eu0-placeholder{
    font-size: 40px !important;
  }
  .css-2613qy-menu{
    font-size: 40px!important;
  }
  .css-1rhbuit-multiValue{
    font-size: 40px !important;
  }
  
  .css-1qoqow7-option{
    font-size: 40px !important;
  }

  .css-yk16xz-control:after{
    right: 10px;
    width: 35px;
    height: 35px;
  }

  .css-1pahdxg-control{
    &:after{
      right: 10px !important;
      width: 40px !important;
      height: 40px !important;
    }
  }
  .az-rightbar .gf-btn-lg-stroke{
    min-width: 350px !important;
    max-width: 350px !important;
    font-size: 40px !important;
    padding: 20px 10px !important;
    margin: 0 10px !important;
    border-radius: 20px !important;
    height: 90px !important;
    border-radius: 20px !important;
  }

  .az-rightbar .gf-btn-lg-outline{
    min-width: 250px !important;
    max-width: 250px !important;
    font-size: 40px !important;
    padding: 20px 0px !important;
    margin: 0 10px !important;
    border-radius: 20px !important;
    height: 90px !important;
    border-radius: 20px !important;
  }

  .dashbaord-pageHeadertitle a{
    font-size: 40px !important;
  }
  .dashbaord-pageHeadertitle a img {
    width: 36px !important  ;
  }
  .az-rightbar .addCat img{
    width: 40px;
  }
  .az-rightbar .deleteCat img{
    width: 50px !important;
  }
  .dashboard-five-stacked-chart {
    height: 500px !important;
  }

  .brandSection .order_form{
    max-width: 800px !important;
    padding: 50px !important; 
  }
  .brandSection .order_form h3{
    font-size: 50px !important;
    margin: 70px 0 !important;
  }
  .brandSection .order_form .back-size{
    font-size: 35px !important;
    line-height: 25pt !important;
    margin: 0 !important;
  }
  .back-size img{
    width: 36px !important;
  }
  .brandSection .order_form label{
    font-size: 35px !important;
  }
  .status-active{
    font-size: 40px !important;
    padding: 5px 23px 5px 50px !important;
  }

  .status-inactive{
    font-size: 40px !important;
    padding: 5px 23px 5px 50px !important;
  }

  .status-active::before{
    width: 15px !important;
    height: 15px !important;
    left: 20px !important;
  }

  .status-inactive::before {
    width: 15px !important;
    height: 15px !important;
    left: 20px !important;
}

  .brandSection .order_form .btn-success-outline{
    font-size: 40px !important;
    min-width: 300px !important;
    max-width: 300px !important;
    height: 90px !important;
    letter-spacing: 2.2px !important;
    border-radius: 20px !important;
  }

  .brandSection .order_form .btn-success{
    height: 90px !important;
    min-width: 300px !important;
    max-width: 300px !important;
    font-size: 40px !important;
    letter-spacing: 2.2px !important; 
    border-radius: 20px !important; 
  }

  .scrolling-carousel .styles-module_sliderBase__swkx1 .styles-module_slider__o0fqa .swipesection .nav-pills .nav-item a{
    font-size: 40px !important;
  }
  
  .scrolling-carousel .styles-module_sliderBase__swkx1 .styles-module_slider__o0fqa .swipesection .nav-pills .nav-item a.active::after{
    border-bottom: 3px solid #272E79 !important;
    height: 3px !important;
  }
  .az-rightbar p{
    font-size: 35px;
  }
  .modal .modal-dialog .modal-content .modal-body h3{
    font-size: 50px !important;
    font-weight: bold !important;
  }

  .modal .modal-dialog .modal-content .modal-body p{
    font-size: 35px !important;
  }

  .modal .modal-dialog .modal-content button.btn-outline-light{
    height: 90px !important;
    min-width: 250px !important;
    max-width: 250px !important;
    font-size: 40px !important;
    letter-spacing: 2.2px !important;  
    margin-top: 30px !important;
    border-radius: 20px !important;
  }

  .modal .modal-dialog .modal-content button.btn-danger{
    height: 90px !important;
    min-width: 250px !important;
    max-width: 250px !important;
    font-size: 40px !important;
    letter-spacing: 2.2px !important;  
    margin-top: 30px !important;
    border-radius: 20px !important;
  }


  .az-rightbar .tableFormatesimple thead th{
    font-size: 40px !important;
  }
  .az-rightbar .tableFormatesimple th{
    font-size: 40px !important;
  }
  .az-rightbar .tableFormatesimple caption{
    font-size: 41px !important;
  }

  .az-rightbar .tableFormatesimple td img{
    width: 50px;
  }

  .az-rightbar .gf-primary-btn-small-two img{
    height: 50px !important;
    padding-right: 15px !important;
    bottom: 0px !important;
  }

  .note_popup_img{
    width: 150px !important;  
    height: 150px !important;
  }

  .brand_list_image{
    width: 120px !important;
    height: 120px !important;
  }

  .file_widget_class img{
    width: 120px;
    height: 120px;
  }

  .az-rightbar button.detlet-text{
    font-size: 35px !important;
  }

  textarea{
    font-size: 40px !important;
  }

  .az-rightbar .detlet-text{
    font-size: 35px !important;
  }

  .az-rightbar .building-btn {
    margin: -110px 0 35px 0 !important;
}
.excluded_box p{
  margin-top: 20px !important;
  font-size: 30px !important;
}
.checkmark{
  width: 50px !important;
  height: 50px !important;
}

.check_cust .checkmark:after {
  left: 14px !important;
  width: 20px !important;
  height: 35px !important;
  border-width: 0 7px 7px 0 !important;
}
.css-xb97g8 svg{
  width: 35px !important;
  height: 35px !important;
}

.css-tlfecz-indicatorContainer svg{
  width: 45px !important;
  height: 45px !important;
  margin-right: 40px;
}



.modal .modal-dialog .modal-content button.btn-success{
  height: 90px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  font-size: 40px !important;
  letter-spacing: 2.2px !important;
  margin-top: 30px !important;
  border-radius: 20px !important;
}
.react-datepicker{
  font-size: 40px !important;
}
.react-datepicker-year-header{
  width: 500px !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  font-size: 40px !important;
}
.react-datepicker__navigation{
    height: 34px !important;
    width: 35px !important;
    border: 1rem solid transparent ;
    top: 20px;
}
.react-datepicker__header{
  width: 500px;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  width: 60px;
  line-height: 50px;
}
.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option{
  line-height: 60px;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown{
  width: 60%;
  left: 20%;
}

.css-voyisq-option{
  font-size: 40px !important;
}
.css-wiygft-option{
  font-size: 40px !important;
  background: gray !important;
}
.modal .modal-dialog .modal-content .modal-body label{
  font-size: 35px !important;
}
.dashbaord-pageHeadertitle{
  margin-bottom: 45px !important;
}
.css-12owms2-option{
  font-size: 40px !important;
}
.az-toggle {
  width: 80px !important;
  height: 40px !important;
}
.az-toggle.on span {
  left: 45px;
}
.az-toggle span{
  width: 31px;
}
.css-gg45go-NoOptionsMessage{
  font-size: 40px !important;
}

.css-1gl4k7y{
  font-size: 40px !important;
}

input[type=file]::file-selector-button {
 font-size: 40px !important;
 padding: 20px !important;
 overflow: hidden;
 height: 100px !important
}
.modal .modal-dialog .modal-content .modal-body b.active{
  font-size: 35px !important;
}
.modal .modal-dialog .modal-content .modal-body b.inactive{
  font-size: 35px !important;
}

.modal .modal-dialog .modal-content .modal-body .az-toggle span {
  width: 30px !important;
}
.modal .modal-dialog .modal-content .modal-body .az-toggle.on span {
  left: 43px !important;
}
.modal .modal-dialog .modal-content .modal-body .delete-text{
  font-size: 35px !important;
}
.h-240 {
  height: 450px !important;
  display: grid;
  place-items: center;
}
.h-380 {
  height: 580px !important;
  display: grid;
  place-items: center;
}
// .text-danger {
//   font-size: 34px;
// }
// .text-danger {
//   font-size: 40px;
// }
.az-rightbar .tableFormatesimple .az-inspection-brown code{
  font-size: 40px !important; 
}
.note_content {
  font-size: 40px !important;
}
.btn_remove{
 min-height: 40px !important;
 width: 40px !important;
 font-size: 26px;
}

.excluded_icon {
  padding: 5px 20px !important;
  font-size: 34px !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 26px !important;
  border-width: 0.8rem !important;
  margin: 0 20px !important;
}
.brandList .asrt-table-body table tbody tr .brand_list_image{
  margin-right:100px;
  width: 80px !important;
  height: 80px !important;
}
.az-rightbar .tableFormatesimple thead th {
  border-bottom: 1px solid #cccccc;
  padding: 0 15px !important;
}

.multiImgsection .list-group-item .progress {
  width: 120px !important;
}
.dash_heading .dash_heading{
  font-size: 60px;
}

.alert{
  font-size: 40px !important;
}

.month_width table tr th{
  width: 200px !important;
}

.month_width table tr th{
  width: 200px !important;
}
.month_width table tr td:first-child{
  width: auto !important;
}

.month_width table tr th:first-child{
  width: auto !important;
}
.spinner-border-sm{
  width: 40px;
  height: 40px;
}
.spinner-border{
  bottom: 7px !important;
  left: -5px;
}
.load_text{
  font-size: 40px !important;
}

.loader_btn .load_text{
  font-size: 40px !important;
}

.lg-mt-50{
  margin-top: 50px;
}
.tracker_btn_new {
  margin: -145px 0 23px 0 !important;
}

.select_width{
  width: 669px !important;
}


}














@media screen 
  and (min-device-width: 2000px) 
  and (max-device-width: 2560px)  {
  // .text-danger {
  //   font-size: 24px;
  // }
  .authentication .az-card-signin{
    width:700px !important;
  }
  .authentication .az-card-signin .az-logo img{
    width: 300px !important;
  }
  .authentication .az-signin-header h3 {
    font-size: 60px !important;
  }
  .authentication .az-signin-header form .form-group input {
    font-size: 30px !important;
    height: 50px !important;
  }
  .valid_info {
    font-size: 20px;
  }
  .authentication .az-signin-header form .form-group label {
    font-size: 25px !important;
  }
  .authentication .az-signin-header form .button-green {
    font-size: 30px !important;
  }
  .az-signin-footer p a {
    font-size: 25px !important;
  }
  .back-button {
    margin-right: 10px !important;
    width: 40px;
  }
  .authentication .az-signin-header .p-dark {
    font-size: 25px !important;
  }
  .authentication .az-signin-header form .az-signin-footer p a {
    font-size: 23px !important;
  }
  .authentication .az-signin-header form .form-group .showPassword span i {
    font-size: 25px;
  }
  .authentication .az-signin-header h3 {
    font-size: 45px !important;
  }
  // .dash_heading {
  //   font-size: 40px !important;
  //   padding: 60px 0 45px 0 !important;
  // }
  // .az-header-menu-header .az-logo img {
  //   height: 135px !important
  // }

  .dashboard .card-title {
    font-size: 16px;
  }
  .btn_view {
    font-size: 16px;
    font-weight: normal;
    padding-top: 0;
  }
  .card.h-chart_1 .list-unstyled li, .nav-tabs + .h-chart_1.tab-content .list-unstyled li {
    font-size: 20px;
  }
  // .menu_heading {
  //   font-size: 40px;
  //   padding: 25px 22px;
  // }
  // .az-header-menu .nav-item > .nav-link {
  //   font-size: 30px !important;
  // }
  .dashbaord-pageHeadertitle h3 {
    font-size: 45px !important;
  }
  .az-rightbar .gf-primary-btn-small-one {
    font-size: 30px !important;
    padding: 10px 50px !important;
    margin: 0 10px !important;
    border-radius: 20px !important;
    height: 70px !important;
  }


.az-rightbar .gf-primary-btn-small-two {
  height: 100% !important;
  font-size: 30px !important;
  padding: 4px 50px !important;
  margin: 0 10px !important;
  border-radius: 20px !important;
  height: 70px !important;
}

.css-voyisq-option {
  font-size: 30px !important;
}
// .az-sidebar {
//   width: 400px !important;
// }
.dashbaord-pageHeadertitle h3 span {
  font-size: 30px !important;
}

.dashbaord-pageHeadertitle h3{
  font-size: 40px !important;
}

.dashbaord-pageHeadertitle{
  padding: 25px 0 0 0 ;
}
.brandList .asrt-table-body table tbody tr td {
  font-size: 31px !important;
}
.brandList .asrt-table-foot .col-md-6:first-child {
  font-size: 25px !important;
}
.brandList .asrt-table-head .asrt-page-length .input-group-addon .input-group-text {
  font-size: 25px !important;
}
.brandList .asrt-table-head .table_filter input[type="search"] {
  font-size: 30px !important;
  height: 50px !important;
}
.brandList .asrt-table-body table thead th {
  font-size: 30px !important;
}
.brandList .asrt-table-foot .col-md-6:last-child .pagination li {
  font-size: 30px !important;
}
.brandList .asrt-table-head .table_filter input[type="search"]::placeholder{
  font-size: 30px !important;
}
.brandList .asrt-table-head .table_filter:before {
  width: 30px !important;
  height: 30px !important;
}
.brandList .asrt-table-head .asrt-page-length select {
  height: 50px !important;
  font-size: 30px !important;
}
.brandSection .order_form .back-size {
  font-size: 30px !important;
  line-height: 25pt !important;
}
.back-size img {
  width: 30px !important;
}
.brandSection .order_form h3 {
  font-size: 40px !important;
  margin: 50px 0 !important;
}
.css-1wa3eu0-placeholder {
  font-size: 30px !important;
}
.css-1qoqow7-option {
  font-size: 30px !important;
}
input {
  font-size: 30px !important;
  height: 50px !important;
}
.css-wiygft-option {
  font-size: 30px !important;
  background-color: gray !important;
}

.css-1uccc91-singleValue {
  font-size: 30px !important;
}

.css-2b097c-container .css-1pahdxg-control{
  height: 50px !important;
}

.css-g1d714-ValueContainer {
  height: 50px !important;
}
.css-b8ldur-Input {
  height: 50px !important;
}
.css-2b097c-container .css-yk16xz-control {
  font-size: 30px !important;
  height: 50px !important;
}
.dashbaord-pageHeadertitle {
  margin-bottom: 35px !important;
}

.css-yk16xz-control:after {
  width: 25px !important;
  height: 25px !important;
}
.css-1pahdxg-control{
  &:after{
    width: 25px !important;
    height: 25px !important;
  }
}
.modal .modal-dialog .modal-content .modal-body h3 {
  font-size: 40px !important;
}
.brandSection .order_form .btn-success-outline {
  font-size: 30px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  height: 70px !important;
}

.dashboardRightcard .form-label {
  font-size: 25px !important;
}

.dashboardRightcard .titleLable {
  font-size: 30px !important;
}

.brandSection .order_form .btn-success {
    height: 70px !important;
    min-width: 300px !important;
    max-width: 300px !important;
    font-size: 30px !important;
    letter-spacing: 1.2px !important;
    border-radius: 20px !important;
}
.brandSection .order_form {
  max-width: 700px !important;
}
.az-rightbar .addCat img {
  width: 25px !important;
}
.az-rightbar .deleteCat img {
  width: 35px !important;
}
.new_btn_add{
  top:5px !important;
}
.az-rightbar .deleteCat {
  position: relative;
  top: 0px !important;
}
.az-rightbar .gf-btn-lg-stroke {
  min-width: 240px !important;
  max-width: 240px !important;
  font-size: 30px !important;
  padding: 20px 10px!important;
  height: 70px !important;
}

.az-rightbar .gf-btn-lg-outline {
    min-width: 150px !important;
    max-width: 150px !important;
    font-size: 30px !important;
    padding: 20px 10px !important;
    height: 70px !important;
}

.dashbaord-pageHeadertitle a {
  font-size: 30px !important;
}

.dashbaord-pageHeadertitle a img {
  width: 30px !important;
}
// .az-rightbar {
//   width: calc(100% - 400px);
// }
textarea {
  font-size: 30px !important;
}
.react-datepicker {
  font-size: 30px !important;
}
.react-datepicker__header {
  width: 400px;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 30px !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 50px;
  line-height: 45px;
}
.react-datepicker__navigation {
  height: 2px !important;
  width: 40px !important;
  border: 12px solid transparent;
  top: 20px;
}
.table th, .table td {
  padding: 9px 27px !important;
}
.az-rightbar .gf-primary-btn-small-two img {
  height: 30px !important;
}
.az-rightbar p {
  font-size: 30px;
}
.az-rightbar .tableFormatesimple caption {
  font-size: 31px !important;
}
.az-rightbar .tableFormatesimple th {
  font-size: 30px !important;
}

.az-rightbar .tableFormatesimple thead th {
  font-size: 30px !important;
}
.scrolling-carousel .styles-module_sliderBase__swkx1 .styles-module_slider__o0fqa .swipesection .nav-pills .nav-item a {
  font-size: 25px !important;
}
.modal .modal-dialog .modal-content .modal-body p {
  font-size: 25px !important;
}
.modal .modal-dialog .modal-content button.btn-outline-light {
  height: 70px !important;
  font-size: 30px !important;
  margin-top: 20px !important;
}
.az-rightbar button.detlet-text {
  font-size: 25px !important;
}

.az-rightbar .detlet-text {
  font-size: 25px !important;
}

.modal .modal-dialog .modal-content button.btn-danger {
  height: 70px !important;
  font-size: 30px !important;
  margin-top: 20px !important;
}
.modal .modal-dialog .modal-content button.btn-success {
  height: 70px !important;
  font-size: 30px !important;
  margin-top: 20px !important;
}
.modal .modal-dialog .modal-content .modal-body b.active{
  font-size: 30px !important;
}
.css-1rhbuit-multiValue {
  font-size: 30px !important;
}
.css-xb97g8 svg {
  width: 25px !important;
  height: 25px !important;
}

.css-tlfecz-indicatorContainer svg {
  width: 35px !important;
  height: 35px !important;
  margin-right: 40px;
}

.status-active {
  font-size: 30px !important;
  padding: 5px 12px 5px 30px !important;
}

.status-inactive {
  font-size: 30px !important;
  padding: 5px 12px 5px 30px !important;
}

.status-active::before {
  width: 10px !important;
  height: 10px !important;
  left: 10px !important;
}

.status-inactive::before {
  width: 10px !important;
  height: 10px !important;
  left: 10px !important;
}

.modal .modal-dialog .modal-content .modal-body label {
  font-size: 25px !important;
}

.css-gg45go-NoOptionsMessage{
  font-size: 30px !important;
}

.css-1gl4k7y{
  font-size: 30px !important;
}

.scroll_mro .tableFormatesimple th{
  padding-right: 0 !important;
  padding-left: 0 !important;
}

input[type=file]::file-selector-button {
  font-size: 30px !important;
  padding: 0px 20px !important;
  height: 50px !important;
 }
 
 .css-12owms2-option {
  font-size: 30px !important;
}
.checkmark {
  width: 35px !important;
  height: 35px !important;
}

.check_cust .checkmark:after {
  left: 12px !important;
  width: 11px !important;
  height: 26px !important;
  border-width: 0 4px 4px 0 !important;
}
.check_cust{
  top: 55% !important;
}
.modal .modal-dialog .modal-content .modal-body .delete-text {
  font-size: 25px !important;
}
.modal .modal-dialog .modal-content .modal-body b.active {
  font-size: 25px !important;
}
.modal .modal-dialog .modal-content .modal-body b.inactive {
  font-size: 25px !important;
}
.brandSection .order_form label {
  font-size: 25px !important;
}

.h-240 {
  height: 390px !important;
}
.h-380 {
  height: 480px !important;
}
.az-rightbar .tableFormatesimple .az-inspection-brown code{
  font-size: 30px !important; 
}
.note_content{
  font-size: 30px !important;
}
.date-time{
  font-size: 25px!important;
}
.excluded_icon {
  padding: 3px 18px !important;
  font-size: 30px !important;
}
.excluded_box p {
  font-size: 20px !important;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 18px !important;
  border-width: 0.6rem !important;
  margin: 0 10px !important;
}
.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  line-height: 45px;
}
.brandList .asrt-table-body table tbody tr .brand_list_image {
  margin-right: 70px;
  width: 70px !important;
  height: 70px !important;
}
.alert{
  font-size: 30px !important;
}
.month_width table tr th{
  width: 130px !important;
}

.month_width table tr th{
  width: 130px !important;
}
.month_width table tr td:first-child{
  width: auto !important;
}

.month_width table tr th:first-child{
  width: auto !important;
}
.spinner-border-sm {
  width: 30px;
  height: 30px;
}
.load_text{
  font-size: 30px !important;
}
.loader_btn .load_text{
  font-size: 30px !important;
}
.lg-mt-50{
  margin-top: 45px;
}
.tracker_btn_new {
  margin: -123px 0 23px 0 !important;
}
.select_width{
  width: 585px !important;
}



}








// Ion Rangeslider Skins
@mixin irs-color-variant($color) {
  .irs-bar,
  .irs-bar-edge,
  .irs-slider::before {
    background-color: $color;
  }

  .irs-from,
  .irs-to,
  .irs-single {
    background-color: $color;
    &::after {
      border-top-color: $color;
    }
  }
}

@mixin irs-modern-slider-variant($color) {
  .irs-slider {
    border-color: $color;
    &::before {
      border-color: $color;
    }
  }
}

@mixin irs-outline-variant($color) {
  .irs-line,
  .irs-slider {
    border-color: $color;
    &:hover,
    &:focus {
      &::before {
        background-color: darken($color, 5%);
      }
    }
  }
}

// $name = class name
// $perc = donut percentage value
// $size = size of donut
// $width = width of chart
// $base = thickness of donut
// $center = background color of the percentage
// $color = background color of donut hole
// $textColor = color of text in center and the percentage
// $textSize = size of text
@mixin donut-chart(
  $name,
  $perc,
  $size,
  $width,
  $base,
  $center,
  $color,
  $textColor: $color,
  $textSize: 40px
) {
  $color2: $color;
  $base2: $base;
  $deg: ($perc/100 * 360) + deg;
  $deg1: 90deg;
  $deg2: $deg;

  // If percentage is less than 50%
  @if $perc < 50 {
    $base: $color;
    $color: $base2;
    $color2: $base2;
    $deg1: ($perc/100 * 360+90) + deg;
    $deg2: 0deg;
  }

  .az-donut-chart {
    &#{$name} {
      width: $size;
      height: $size;
      background: $base;

      .slice {
        &.one {
          clip: rect(0 $size $size/2 0);
          -webkit-transform: rotate($deg1);
          transform: rotate($deg1);
          background: $color;
        }

        &.two {
          clip: rect(0 $size/2 $size 0);
          -webkit-transform: rotate($deg2);
          transform: rotate($deg2);
          background: $color2;
        }
      }

      .chart-center {
        top: $width;
        left: $width;
        width: $size - ($width * 2);
        height: $size - ($width * 2);
        background: $center;

        span {
          font-size: $textSize;
          line-height: $size - ($width * 2);
          color: $textColor;

          &:after {
            content: "#{$perc}%";
          }
        }
      }
    }
  }
}

@mixin checkbox {
  input[type="checkbox"] {
    height: $az-checkbox-height-base !important;
    margin-top: 0 !important;
    margin-bottom: 6px;
    position: relative;
    }
}

@mixin h2 {
  font-weight: 500;
  letter-spacing: .03em;
  color: $az-light-white;
  font-family: $az-heading-one;
  font-size: 36px;
}

@mixin h3 {
  font-weight: bold;
  letter-spacing: 0px;
  color: $gf-primary-color !important;
  font-family: $az-heading-three-bd;
  font-size: $az-font-44;

  @media (max-width: 480px) {
    font-size: $az-font-30;
  }
  @media (max-width: 320px) {
    font-size: $az-font-30;
  }
 
}

@mixin p-dark {
  font-weight: normal;
  color: $gf-md-black;
  font-family: $az-heading-one-rg;
  font-size: $az-font-16;
  font-weight: 400;
}

@mixin p-light {
  font-weight: normal;
  letter-spacing: 0px;
  color: $az-black;
  font-family: $az-heading-one;
  font-size: $az-font-16;
}

@mixin green-button {
  font-family: $az-heading-two;
  background-color: $az-black;
  color: $az-white;
  font-size: 16px;
  border-radius: 10px;
  letter-spacing: normal;
  font-weight: bold;
  text-transform: uppercase;
  
}

@mixin light-gray-font {
  font-family: $az-heading-two;
  color: $az-gray;
}

@mixin p-normal {
  font-weight: normal;
  letter-spacing: 0px;
  color: $az-black;
  font-family: $az-heading-para-light;
  font-size: $az-font-16;
}

@mixin h4 {
  font-weight: 500;
  letter-spacing: 0px;
  color: $az-black;
  font-family: $az-heading-one;
  font-size: 32px;
}

@mixin h5 {
  font-weight: normal;
  letter-spacing: 0px;
  color: $az-black;
  font-family: $az-heading-one;
  font-size: $az-font-18;
}

@mixin navFont {
  font-weight: normal;
  letter-spacing: 0px;
  color: $az-black !important;
  font-family: $az-heading-one;
  font-size: $az-font-18;
}

@mixin welcome-para {
  font-weight: normal;
  letter-spacing: 0px;
  color: $az-gray;
  font-family: $az-heading-two;
}

/*-----------Custom Form elements------------*/

@mixin aside {
  .nav-pills {
    font-size: $az-font-16;
    text-align: left;
    font-family: $az-heading-two;
    .nav-link.active {
      background-color: $az-black; // rgba(50, 27, 99, 0.2);
      color: $az-white;
      border-radius: 2px;
      letter-spacing: 0.19px;
      padding: 14px 20px;
      line-height: 20px;
      &:hover {
        color: $az-white;
      }
    }
    .nav-link {
      padding: 14px 20px;
      letter-spacing: 0.19px;
      line-height: 1.25;
      color : $az-gray;
      &:hover {
        background-color: $az-black; // rgba(50, 27, 99, 0.2);
        color: $az-white;
        border-radius: 2px;
        letter-spacing: 0.19px;
      }
    }
  }
}

@mixin accordion-nav {
  text-align: left;
  font-family: $az-heading-para-light;
  background: none;
  .card {
    background: none;
    margin-bottom: 12px !important;
    .card-header {
      button {
        padding: 14px 20px;
        font-size: $az-font-18;
        color: $az-black;
        border-radius: 2px;
        background: $az-white !important;
        justify-content: space-between;
        display: flex;
        align-items: center;
        font-family: $az-heading-para;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        -o-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        i {
          font-size: $az-font-16;
        }
        a {
          color: $az-black;
        }

        &:hover { 
          background: $az-black !important; 
          color: $az-white;
          a {
            color: $az-white;
          }
        }
      }
    }
    .collapse {
      margin-top: 9px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      background: $az-white;
      border-radius: 2px;
      .card-body {
        border: none;
        padding-top: 24px;
        table {
          th {
            font-size: $az-font-14;
            font-family: $az-heading-two;
            color: $az-gray;
            text-transform: capitalize;
            border-top: 1px solid #cdd4e0;
            padding: 16px 15px;
            font-weight: normal;
            letter-spacing: 0.19px;
          }
          td {
            padding: 16px 15px;
            vertical-align: middle;
            font-size: $az-font-14;
            color: $az-black;
            font-family: $az-heading-two;
            font-weight: normal;

            b {
              @include inactivestatus;
            }
            b.active {
              @include activestatus;
            }

            img {
              background: none !important;
              cursor: pointer;
              border: none;
              outline: none;

              &:first-child {
                margin-right: 0px;
              }
            }
          }
        }

        button.btn {
          @include add-button;
        }
      }
      p {
        font-size: $az-font-16;
        color: $az-black;
        font-family: $az-heading-two;
        font-weight: normal;
      }
    }
    .collapsing {
      margin-top: 9px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      background: $az-white;
      border-radius: 8px;
      .card-body {
        border: none;
        padding-top: 24px;
        table {
          th {
            font-size: $az-font-14;
            font-family: $az-heading-two;
            color: $az-gray;
            text-transform: capitalize;
            border-top: 1px solid #cdd4e0;
            padding: 16px 15px;
            font-weight: normal;
            letter-spacing: 0.19px;
          }
          td {
            padding: 16px 15px;
            vertical-align: middle;
            font-size: $az-font-14;
            color: $az-black;
            font-family: $az-heading-two;
            font-weight: normal;
            b {
              @include inactivestatus;
            }
            b.active {
              @include activestatus;
            }

            img {
              background: none !important;
              cursor: pointer;
              border: none;
              outline: none;

              &:first-child {
                margin-right: 0px;
              }
            }
          }
        }

        button.btn {
          @include add-button;
        }
        p {
          font-size: $az-font-16;
          color: $az-black;
          font-family: $az-heading-two;
          font-weight: normal;
        }
      }
    }
  }
}

@mixin add-button {
  min-width: 160px;
  max-width: 160px;
  height: auto;
  padding: 10px 20px;
  border-radius: 0px;
  border: 2px solid $az-black;
  font-size: $az-font-16;
  font-family: $az-heading-two;
  color: $az-black;
  font-weight: 500;
  letter-spacing: .05em;
  line-height: inherit;

  img {
    padding-right: 0px;
    vertical-align: baseline;
    width: 14px;
    filter: contrast(2);
    margin-right: 8px;
  }

  &:hover {
    color: $az-white;
    font-weight: normal;
    outline: none;
    border: 2px solid $az-black;
    background: $az-black;
    color: $az-white;
    img {
      filter: invert(8) contrast(3);
    }

  }
  &:focus {
    font-weight: normal;
    outline: none;
    border: 2px solid $az-black;
    background: $az-black;
    color: $az-white;
    img {
      filter: invert(8) contrast(3);
    }
  }
  &:active {
    font-weight: normal;
    box-shadow: none;
    outline: none;
    background: none;
    color: $az-black;
    img {
      filter: contrast(2);
    }
  }
}

@mixin dashboard-background {
  // background: $az-light-white !important;
  margin: 0;
  padding: 0 17px;
}

@mixin pageHeadertitle {
  // background: $az-white;
  // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 22px 0px 0px;
  height: 100%;
  margin-bottom: 20px;
  h3 {
    font-size: $az-font-24;
    font-family: $az-heading-para;
    color: $gf-primary-color;
    margin-bottom: 0px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: start;
    text-transform: uppercase;
    letter-spacing: 1.2px;
   
    

    @media (max-width: 767px) {
      font-size: $az-font-24;
      margin-bottom: 0px;
    }

   
    span {
      color: $gf-input-label-color;
      font-size: $az-font-14 ;
      font-family: $az-heading-two !important;
      letter-spacing: 0.72px;
      text-transform: uppercase;
      margin-top: 0px;
      font-weight: normal;
    }
  }

  .btnNavigate {
    .btn-success {
      @include common-green-btn;
    }
    .btn-success-outline {
      @include btn-success-outline;
    }
    .btn-danger-outline {
      @include btn-danger-outline;
    }

    b {
      margin-left: 10px;
      letter-spacing: 0.19px;
      line-height: 1.5;
      padding: 2px 10px;
    }
    b.active {
        margin-left: 10px;
        letter-spacing: 0.19px;
        line-height: 1.5;
        padding: 2px 10px;
    }
    @media(max-width:767px){
      padding: 16px 15px;
    }
  }

  a {
    color: $gf-primary-color !important;
    font-size: $az-font-14;
    font-family: $az-heading-two !important;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    letter-spacing: 1.2px;
    color: $gf-primary-color;
    line-height: 22pt;

    img{
      width: 14px;
      margin-right: 0px;
    }
  }
}

@mixin common-green-btn {
  font-family: $az-heading-two;
  background-color: $az-black !important;
  color: $az-white;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-black !important;
  padding: 10px 20px;
  letter-spacing: .05em;
  display: flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  min-width: 60px;
  max-width: 160px;
  justify-content: center;
  img{
    padding-right: 5px;
    vertical-align: baseline;
  }
  &:focus {
    color: $az-black !important;
    background: none !important;
    border: 2px solid $az-black !important;
  }
  &:active {
    color: $az-black !important;
    background: none !important;
    border: 2px solid $az-black !important;
  }
  &:hover {
    color: $az-black !important;
    background: none !important;
    border: 2px solid $az-black !important;
  }
  span {
    display: flex;
    align-items: center;
  }
}

@mixin activestatus {
  font-weight: normal;
  font-family: $az-heading-one-rg;
  color: $az-active-status;
  font-size: $az-font-12;
  background: $az-active-status-bg;
  padding: 3px 6px 2px 13px;
  border-radius: 4px;
  text-transform: capitalize;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    background: $az-active-status;
    border-radius: 50px;
    left: 5px;
    top: 40%;
  }
}

@mixin inactivestatus {
  font-weight: normal;
  font-family: $az-heading-one-rg;
  color: $az-inactive-status;
  font-size: $az-font-12;
  background: $az-inactive-status-bg;
  padding: 3px 6px 2px 13px;
  border-radius: 4px;
  text-transform: capitalize;

  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    background: $az-inactive-status;
    border-radius: 50px;
    left: 5px;
    top: 40%;
  }
}

@mixin modalbox {
  .modal-dialog {
    margin: auto;
    max-width: 484px;
    .modal-content {
      border-radius: 8px;
      @media(max-width:767px){
        width: 95%;
        margin: auto;
      }
      .modal-body {

        @include dashbaordprofilecontent;

        padding: 32px 24px;
        img {
          margin-bottom: 24px;
          width: 46px;
        }
        h3 {
          font-weight: normal;
          font-family: $az-heading-one-rg;
          color: $gf-primary-color;
          font-size: $az-font-24;
          text-transform: capitalize;
          margin-bottom: 24px;
          letter-spacing: normal;
          
        }
        p {
          font-weight: normal;
          font-family: $az-heading-one-rg;
          color: $az-black;
          font-size: $az-font-16;
          text-transform: inherit;
          margin-bottom: 14px;
          color: $gf-input-label-color;
        }
        
        label {
          font-weight: normal;
          font-family: $az-heading-one-rg;
          color: $gf-gray-secondary;
          font-size: $az-font-15;
          margin-bottom: 5px;
          letter-spacing: 0;
        }

        .react-datepicker-wrapper{
          width: 100% !important;
          height: 42px !important;
          padding: 0 !important;
          border-radius: 4px !important;
      
          input {
              height: 40px !important;
          }
        }
        @include fileUploadeditpage;
        .infosize{
          font-size: $az-font-12;
          color: $az-gray;
          font-family: $az-heading-two;
          line-height: 1.25;
          margin-top: 11px;
         
          img{
            @media (max-width: 767px) { 
              vertical-align: middle;
            }
          }
            p{
             margin: 0;
             font-size: $az-font-12;
             color: $az-gray;
             font-family: $az-heading-two;
             line-height: 1.25;
          }
        }

        .az-toggle {
          width: 36px;
          height: 18px;
          span { width: 12px;}
        }
        .az-toggle.on {
          span {
          left: 21px;
          }
        }
        b.active {
          display: block;
          font-weight: normal;
          font-family: $az-heading-one-rg;
          text-transform: capitalize;
          color: $gf-gray-secondary;
          margin-right: 5px;
        }
        b.inactive {
          display: block;
          font-weight: normal;
          font-family: $az-heading-one-rg;
          text-transform: capitalize;
          margin-right: 5px;
        }
        .delete-text{
          font-weight: normal;
          font-family: $az-heading-two;
          text-transform: uppercase;
          color: $gf-primary-color;
          font-size:$az-font-14;
          letter-spacing: 1.2px;
        }
      }
      button.btn-success {
        @include modalbtn-stroke;
      }
      button.btn-outline-light {
        @include modalbtn-outline;
      }
      button.btn-danger {
        @include modalbtn-delete;
      }
    }
  }
}

@mixin inputfield {
  border-radius: 0px !important;
  color: $az-black !important;
  font-weight: normal !important;
  font-family: $az-heading-one-rg !important;
  font-size: $az-font-16 !important;
  height: 44px ;
  width: 100% !important;
  border: 1px solid $az-black-color !important;
  padding: 0.375rem 0.75rem;
  background-color: $az-white !important;
  &:focus {
    // border: 1px solid $gf-gray-secondary !important;
    box-shadow: none;
    outline: 0px solid $az-border-color !important;
  }
}

@mixin selectfield {
  border-radius: 0px;
  padding: 10px 6px;
  width: 100% !important;
  border: 1px solid $gf-gray-secondary !important;
  color: $az-black !important;
  font-weight: normal !important;
  font-family: $az-heading-two !important;
  font-size: $az-font-16 !important;
  height: 42px !important;
  margin-top: 0px;
  position: relative;
  background-color: $az-white !important;

  // background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
  // background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 14px!important;
  background-repeat: no-repeat !important;
  background-image: url(../../images/down-a.svg) !important;
  background-position: center right 15px !important;
  display: inline-block;
  padding: 0.5em 2.5em 0.5em 1em;
   -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  
  option {
    border-radius: 0px !important;
  }
  &:focus {
    outline: none;
    box-shadow: none;
   border: 1px solid $gf-gray-secondary !important;

   background-size: 14px;
   background-repeat: no-repeat;
   background-image: url(../../images/down-u.svg);
   background-position: center right 15px;
   outline: 0;


  }
  &:hover {
    outline: none;
    box-shadow: none;
   border: 1px solid $gf-gray-secondary !important;
   background-size: 14px;
   background-repeat: no-repeat;
   background-image: url(../../images/down-u.svg) !important;
   background-position: center right 15px;
  }
  &:active {
    outline: none;
    box-shadow: none;
    border: 1px solid $gf-gray-secondary !important;
    background-size: 14px;
    background-repeat: no-repeat;
    background-image: url(../../images/down-u.svg) !important;
    background-position: center right 15px;
  }

  &::before {
    
  }
}

@mixin modalbtn-stroke {
  font-family: $az-heading-para-light;
  background-color: $az-solid-brown;
  color: $az-white;
  font-size: $az-font-16;
  border-radius: 8px;
  font-weight: 500;
  border: none;
  padding: 9px 15px 6px;
  min-width: 145px;
  max-width: 145px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  &:focus, &:hover, &:active {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: $az-gray;
    color: $az-white !important;
  }
 
  @media(max-width:767px){
    width: 42%;
    margin-right: 15px !important;
  }
  span {
      display: flex; justify-content: center; align-items: center;
  }
}
@mixin modalbtn-outline {
  font-family: $az-heading-para-light;
  background:$gf-md-black-secondary;
  color: $az-white;
  font-size: $az-font-16;
  border-radius: 8px;
  font-weight: 500;
  border: none;
  padding: 9px 15px 6px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-right: 7px !important;
  height: 40px;
  &:focus, &:hover, &:active  {
    border: none;
    box-shadow: none;
    outline: none;
    background:   $az-gray !important;
    color: $az-white!important;
  }

  @media(max-width:767px){
    width: 42%;
    margin-left: 15px !important;
  }
}

@mixin modalbtn-delete {
  // font-family: $az-heading-two;
  // background-color: $az-inactive-status-bg;
  // color: $az-white;
  // font-size: $az-font-16;
  // border-radius: 0px;
  // font-weight: 500;
  // border: none;
  // padding: 10px 20px;
  // letter-spacing: .05em;
  // max-width: 160px;
  // min-width: 160px;
  // outline: none;
  // height: auto;
  // &:focus {
  //   border: 2px solid $az-inactive-status-bg;
  //   box-shadow: none;
  //   outline: none;
  //   background: none;
  //   color: $az-inactive-status-bg;
  // }
  // &:hover {
  //   border: 2px solid $az-inactive-status-bg;
  //   box-shadow: none;
  //   outline: none;
  //   background: none;
  //   color: $az-inactive-status-bg;
  // }
  // &:active {
  //   border: 2px solid $az-inactive-status-bg;
  //   box-shadow: none;
  //   outline: none;
  //   background: none;
  //   color: $az-inactive-status-bg;
  // }

  font-family: $az-heading-para-light;
  background-color: $az-inspection-red;
  color: $az-white;
  font-size: $az-font-16;
  border-radius: 8px;
  font-weight: 500;
  border: 2px solid $az-inspection-red;
  padding: 10px 20px;
  // min-width: 227px;
  // max-width: 227px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  height: 40px;
  &:focus {
    border: 2px solid $az-modl_dange_btn_sec !important;
    box-shadow: none;
    outline: none;
    background-color: $az-modl_dange_btn_sec !important;
    color: $az-white !important;
  }
  &:hover {
    border: 2px solid $az-modl_dange_btn !important;
    box-shadow: none;
    outline: none;
    background-color: $az-modl_dange_btn !important;
    color: $az-white !important;
  }
  &:active {
    border: 2px solid $az-modl_dange_btn_sec !important;
    box-shadow: none;
    outline: none;
    background-color: $az-modl_dange_btn_sec !important;
    color: $az-white !important;
  }
  @media(max-width:767px){
    width: 42%;
    margin-right: 15px !important;
  }
  span {
      display: flex; justify-content: center; align-items: center;
  }
}

@mixin pagetabNavigation {
  .nav-tabs {
    background: none;
    padding: 0;
    font-size: $az-font-16;
    font-family: $az-heading-one;
    border-bottom: 1px solid $az-border-color;
    a {
      background: none;
      padding-left: 0;
      padding-right: 0;
      margin-right: 30px;
      color: $az-gray;

      @media (max-width: 767px) {
      border: 1px solid $az-border-color;
      width: 100%;
      margin: 0 0 15px !important;
      text-align: center;
      }

      &:hover {
        background: none;
        color: $az-black;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
    .nav-link.disabled {
      color: $az-disbled;
    }
    a.active {
      letter-spacing: 0;
      border-bottom: 1px solid $az-black;
      background: none;
      padding-left: 0;
      padding-right: 0;
      color: $az-black;

      @media (max-width: 767px) {
        border: 1px solid $az-black;
        width: 100%;
        margin: 0 0 15px !important;
        text-align: center;
        }

      &:hover {
        background: none;
      }
    }
  }
  .tab-content {
    background: none;
    border: none;
    padding: 0;
    .tab-pane {
      .row {
        border-bottom: 1px solid $az-border-color;
        margin-left: 0;
        margin-left: 0;
        &:last-child {
          border-bottom: none;
        }
        @include fileUploadeditpage;
        .d-flex {
          justify-content: space-between;
          @media (max-width: 767px) {
            justify-content: center !important;
          }
          @include activetoggle;
        }

        // select option[value=""] {
        //   color: $az-input-placeholder;
          
        // }

        .infosize{
          font-size: $az-font-12;
          color: $az-gray;
          font-family: $az-heading-two;
          line-height: 1.25;
          margin-top: 11px;
         
          img{
            @media (max-width: 767px) { 
              vertical-align: middle;
            }
          }
            p{
             margin: 0;
          }
        }

        label {
          font-weight: normal;
          font-family: $az-heading-two;
          color: $az-black;
          font-size: $az-font-14;
          text-transform: inherit;
          margin-bottom: 0px;

        }
        button.btn-success {
          @include modalbtn-stroke;
        }
      }
    }
  }
}

@mixin spinnerloaderonpage {
  display: block;
}

@mixin mastertable {
  tbody {
    tr {
      background: $az-white;
      &:hover {
        // background: $az-light-gray !important;
       // background: $az-solid-brown !important;
        background: $az-gray !important;
        
        td {
         // color: $az-white !important;
          color: $az-white !important;
        }
      }
      th, td {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }
}


@mixin maincontainer {
 max-width: 1580px;
  margin: auto;
}

@mixin dashboardLeftcard {
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 16px 24px;
    height: 100%;

    @include dashbaordprofilecontent;
    
}

@mixin dashboardRightcard {
  
  background: #ffffff;
  box-shadow: none;
  border-radius: 0px;
  padding: 46px 22px;
  height: 100%;

  @include dashbaordprofilecontent;
  .az-profile-name-text { 
    padding-left: 15px;
    .az-profile-name-text { 
      padding-left: 0px;
    }
  }
  .form-label {
    text-align: left;
    color: $gf-gray-secondary;
    font-family: $az-heading-one-rg;
    font-size: $az-font-15;
    font-weight: normal;
    text-transform: capitalize;
    letter-spacing: 0px;

    @media (max-width:991px){
      text-align: left;
    }
    @media (max-width:767px){
      text-align: left;
    }
  }
  hr {
    max-width: 97%;
  }

  .infosize{
    font-size: $az-font-12;
    color: $az-gray;
    font-family: $az-heading-two;
    line-height: 1.25;
    margin-top: 11px;
   
    img{
      @media (max-width: 767px) { 
        vertical-align: middle;
      }
    }
      p{
       margin: 0;
    }
  }

  label {
    font-weight: normal;
    font-family: $az-heading-para;
    color: $az-black;
    font-size: $az-font-14;
    text-transform: inherit;

  }
  .titleLable {
    padding: 0 29px 2px;
    color: $gf-primary-color;
    font-family: $az-heading-two;
    font-size: $az-font-16;
    margin-bottom: 15px;
    letter-spacing: 1.2px;
  }
}


@mixin dashbaordprofilecontent {
  
  .az-profile-name-text {
      font-size: $az-font-12 !important;
      color: $az-light-gray !important;
      letter-spacing: 1.01px;
      font-family: $az-heading-para;
      font-weight: normal;
      margin-bottom: 1rem;
  }
  .media-body { 
      font-size: $az-font-14;
      color: $az-black;
      letter-spacing: 0.32px;
      font-family: $az-heading-para;
      font-weight: normal;
      padding-bottom: 6px;
      margin-bottom: 1rem;

    span{
      font-size: $az-font-13;
      color: $az-update-txt-color;
      letter-spacing: 0.16px;
      font-family: $az-heading-two;
      margin-right: 15px;
    }  

    button{
      font-size: $az-font-14;
      color: $az-black;
      letter-spacing: 0.16px;
      font-family: $az-heading-two;
      background: none;
      box-shadow: none;
      padding: 7px 17px 6px;
      border-radius: 4px;
      border: solid 1px #4a4a4a;
      line-height: 1.36;
      font-weight: 500;
    } 
  }
  .col-md-12, .d-flex {
    .az-profile-name-text {
      font-size: $az-font-14 !important;
      color: $az-gray !important;
      letter-spacing: 0.22px;
      font-family: $az-heading-two;
      font-weight: normal;
      margin-bottom: 0;
  }
  .para{
    font-size: $az-font-14;
    color: $az-gray;
    letter-spacing: 0.28px;
    font-family: $az-heading-two;
    font-weight: normal;
    margin: 9px 0;

    a {
      color: $az-purple;
    }
  }
  .brand-sm-logo{
    width: 44px;
    height: 44px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
 }
}

@mixin uploaladfilechange {
  
  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    background: $az-white !important;
    height: 20px !important;
  }
  
}


@mixin breadcrumbs {
  
  a {
    font-family: $az-heading-two;
    color: $az-black;
    font-size: $az-font-13;
    text-decoration: none;
    margin: 0 0px 0 0;
    text-transform: uppercase;
  }
  span {
    font-size: $az-font-13;
    color: $az-gray;
    font-family: $az-heading-two;
    margin: 0 0 0 3px;
    text-transform: uppercase;
    &:hover {
      color: $az-gray;
    }

    &:first-child {
      margin: 0;
    }
  }
}


@mixin fileUploadeditpage {
  .fileUpload{
    span {
      width: 139px;
      height: 139px;
      border-radius: 50%;
      background: $az-white;
      border-bottom: 1px solid $az-border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 0px;
      img {
        max-width: 139px;
        min-width: 139px;
        max-height: 139px;
        min-height: 139px;
        object-fit: cover;
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
      label {
        margin-bottom: 0;
      }
    }
    ul.ruLink {
        display: inline-flex;
        li {
          list-style: none;
          margin-right: 15px;
          padding-right: 15px;
          position: relative;
          text-transform: uppercase;

          color: $az-gray;
          font-family: $az-heading-para;
          text-decoration: none;
          font-size: $az-font-14;
          letter-spacing: 1.18px;
          display: none;

          &::after {
            position: absolute;
            content: "";
            background: $az-border-color;
            height: 10px;
            width: 1px;
            right: 0;
            top: 5px;
          }
          &:last-child {
            margin-right: 0px;
            padding-right: 0px;
            &::after {
              display: none;
            }

           
          }

          a {
            color: $az-inactive-status-bg;
            text-decoration: none;
            font-family: $az-heading-para;
            font-size: $az-font-14;
            letter-spacing: 1.18px;
            &:hover {
              color: $az-inactive-status-bg;
              text-decoration: none;
            }
          }
        }
      }
 } 
}

@mixin btn-danger-outline {
    font-family: $az-heading-two;
    background: none;
    color: $az-inactive-status-bg;
    font-size: $az-font-16;
    border-radius: 0px;
    font-weight: 500;
    border: 2px solid $az-inactive-status-bg !important;
    padding: 10px 20px;
    letter-spacing: .05em;
    min-width: 60px;
    max-width: 160px;
    outline: none !important;
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: 40px;
    img{
      padding-right: 0px;
      vertical-align: baseline;
      padding-top: 1px;
      filter: contrast(2);
      margin-right: 8px;
     
    }
  &:focus {
    box-shadow: none;
    outline: none;
    border: 2px solid $az-inactive-status-bg !important;
    background: $az-inactive-status-bg;
    color: $az-white;
    img {
      filter: invert(0) contrast(1) brightness(5);
    }
  }
  &:active {
    box-shadow: none;
    outline: none;
    border: 2px solid $az-inactive-status-bg !important;
    background: $az-inactive-status-bg;
    color: $az-white;
    img {
      filter: invert(0) contrast(1) brightness(5);
    }
  }
  &:hover {
    box-shadow: none;
    outline: none;
    border: 2px solid $az-inactive-status-bg !important;
    background: $az-inactive-status-bg;
    color: $az-white;

    img {
      filter: invert(0) contrast(1) brightness(5);
    }
  }
 
}

@mixin btn-success-outline {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-black;
  padding: 10px 20px;
  letter-spacing: .05em;
  min-width: 60px;
  //max-width: 160px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  height: 40px;
  img{
    padding-right: 0px;
    vertical-align: baseline;
    width: 14px;
    filter: contrast(2);
    margin-right: 8px;
  }
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: none;
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background: $az-gray;
    color: $az-white;

    img {
      filter: invert(8) contrast(3);
    }
  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: none;
  }
  @media (max-width: 320px) {
    min-width: 137px;
    max-width: 137px;
    margin: 5px 0 !important;
  }

}
@mixin common-green-btn-small {
  font-family: $az-heading-two;
  background-color: $az-black !important;
  color: $az-white;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: none !important;
  padding: 10px 20px;
  letter-spacing: .05em;
  min-width: 160px;
  max-width: 160px;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    padding-right: 5px;
    vertical-align: baseline;
  }
  &:focus {
    box-shadow: none ;
    outline: none;
    border:none;
  }
  &:active {
    box-shadow: none;
    outline: none;
    border:none;
  }
  &:hover {
    box-shadow: none;
    outline: none;
    border:none;
  }
  
}

@mixin btn-success-outline-small {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-black;
  padding: 10px 20px;
  letter-spacing: .05em;
  min-width: 160px;
  //max-width: 160px;
  outline: none;
  display: flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  align-items: baseline;
  justify-content: center;

  img{
    padding-right: 0px;
    vertical-align: baseline;
    filter: contrast(2);
    width: 14px;
    margin-right: 8px;
  }
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;

    img{
      filter: invert(8) contrast(3);
    }
  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
  }
}

@mixin scrolling-carousel-swiper {
  h3{
    font-family: $az-heading-one;
    color: $az-black;
    font-size: $az-font-24;
    text-align: left;
    margin-bottom: 22px;
  }
  .styles-module_sliderBase__swkx1 {
     width: 80%;
   .styles-module_slider__o0fqa {
     position: relative;
     z-index: 99;
     .swipesection{
       position: relative;
       .nav-pills {
        // border-bottom: 1px solid $az-border-color;
        // border-top: 1px solid $az-border-color;
          
        .nav-item{ 
         
          a{
            background: none;
            border-radius: 0 !important;
            font-family: $az-heading-two;
            color: $az-gray;
            font-size: $az-font-16;
            padding: 2px 0px 5px !important;
            margin-right: 22px !important;
            text-transform: uppercase;
           

            &:hover {
              color: $az-black;
            }
           
          }
          a.active{
            background: none;
            border-radius: 0 !important;
            font-family: $az-heading-two;
            color: $gf-primary-color;
            font-size: $az-font-16;
            position: relative;
            
           
            &::after {
              border-bottom: 1px solid $gf-primary-color;
              width: 100%;
              height: 1px;
              content: '';
              position: absolute;
              bottom: 0px;
              left: 50%;
              text-align: center;
              transform: translate(-50%, 0%);
            }
         }

         &:first-child {
          a{
           padding: 11px 15px 11px 0;
           margin-right: 5px;
          }
         }
         &:last-child {
          a{
           padding: 11px 35px 11px 15px;
           margin-right: 0px;
          }
         }
        }
       }
     }
   }
  }
   .dropdown {
    button { 
      display: block;
      padding: 0;
      position: absolute;
      right: 0;
      background: $az-light-white !important;
      width: 27px;
      height: 44px;
      z-index: 2;
      top: 1px;
      text-align: right;
      &:focus {
        border: none;
        box-shadow: none !important;
        outline: none !important;
      }
      &:after {
        display: none;
      }
   }
  }
  .show {
    z-index: 9;
   .show {
    display: block;
    opacity: 1;
    transform: inherit !important;
    right: 0 !important;
    left: auto !important;
    top: 0px !important;
    border-radius: 8px !important;
    border: none;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    -o-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 6px 6px;
    a {
      font-family: $az-heading-one;
      color: $az-light-gray;
      font-size: $az-font-14;
      letter-spacing: 0.35px;
      text-transform: capitalize;

      &:hover{
        background-color: rgba(50, 27, 99, 0.2);
        color: $az-purple;
      }
      &:active {
        background-color: rgba(50, 27, 99, 0.2);
        color: $az-purple;
      }
      &:focus {
        background-color: rgba(50, 27, 99, 0.2);
        color: $az-purple;
      }
      &:last-child {
        border-radius: 0px 0px 6px 6px;
      }
    }
    }
  }
  .styles-module_sliderBase__swkx1 {

    div[data-arrow=right] {
        bottom: 0;
        height: 48px;
        margin-bottom: auto;
        margin-top: auto;
        width: 88px;
        position: absolute;
        top: -1px;
        opacity: 1;
        cursor: pointer;
        background: linear-gradient(270deg,$az-light-white 10%,hsla(0,0%,100%,0));
        right: 0;
        z-index: 1;
        button{
            display: none;
        }
      }
      div[data-arrow=left] {
          bottom: 0;
          height: 48px;
          margin-bottom: auto;
          margin-top: auto;
          width: 88px;
          position: absolute;
          top: -1px;
          opacity: 1;
          cursor: pointer;
          background: linear-gradient(90deg,$az-light-white 10%,hsla(0,0%,100%,0));
          left: 0;
          z-index: 1;
          button{
            display: none;
        }
     }
  }
}

@mixin activetoggle {

      b {
        font-family: $az-heading-para;
        font-size: $az-font-14;
        color: $az-gray;
        text-transform: capitalize;
        margin-right: 10px;
        font-weight: normal;
        display: none;
    }
    b.active {
        font-family: $az-heading-para;
        font-size: $az-font-15;
        color: $az-active-status-bg;
        text-transform: capitalize;
        margin-right: 10px;
        font-weight: normal;
        display: none;
        
    }
  
}

@mixin aminitiescard {
  .left {
    @media(max-width:991px) {
      margin-bottom: 58px;
    }

    @media(max-width:767px) {
      margin-bottom: 58px;
    }
  
    ul{
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      margin: 0;
      position: relative;
    li{
        width: 100%;
        background: $az-light-white;
        padding: 0px 58px 0 31px;
  
       @include selectcard;
  
       @media(max-width:991px) {
        width: 100%;
        padding: 0px 31px 58px;
       }
  
       @media(max-width:767px) {
        width: 100%;
        padding: 0px 31px 58px;
      }
    }
  }
  .move-btn {
    width: 100px;
    position: absolute;
    top: 27%;
    right: 0%;
    transform: translate(50%, 0);
    z-index: 1;

    @media(max-width:991px) {
      left: 50%;
      transform: translate(-50%, 2%);
      top: 89%;
    }

    @media(max-width:767px) {
      left: 50%;
      transform: translate(-50%, 6%);
      top: 89%;
    }

    button.green-btn {
      background: $az-black;
      border-radius: 4px;
      padding: 13px 15px 12px 15px;
      color: $az-white;
      font-weight: 500;
      margin-bottom: 12px;
      border: none;
      width: 100px;
    }
    button.basic-btn {
      background: $az-gray;
      border-radius: 4px;
      padding: 13px 15px 12px 15px;
      color: $az-white;
      font-weight: 500;
      border: none;
      width: 100px;
    }
  }
}

  .right{
  
    ul{
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      margin: 0;
      position: relative;
    li{
       width: 100%;
       padding: 0px 31px 0 58px;
  
       @include selectcard;
  
       @media(max-width:991px) {
        width: 100%;
        padding: 0px 31px 0;
       }
  
       @media(max-width:767px) {
        width: 100%;
        padding: 0px 31px 0px;
       }
     }
    }
  }  
}

@mixin selectcard {

  .selectcard {
    background: $az-white;
    padding: 13px 16px;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    position: relative;
    margin: 16px 0;

    @media(max-width:991px) {
      padding: 13px 33px 13px 16px;
     }

     @media(max-width:767px) {
      padding: 13px 33px 13px 16px;
     }

    label {
      font-size: $az-font-16;
      letter-spacing: 0.32px;
      font-family: $az-heading-two;
      color: $az-black;
      padding-left: 15px;
      padding-bottom: 2px;
     
     
    }
    span{
      margin-left: 30px;
      font-size: $az-font-14;
      letter-spacing: 0.22px;
      font-family: $az-heading-two;
      color: $az-gray;
      padding-bottom: 2px;
    }
    h6{
      margin-left: 30px;
      font-size: $az-font-12;
      letter-spacing: 0.19px;
      font-family: $az-heading-two;
      color: $az-gray;
      margin-bottom: 0;
    }
    img { 
      position: absolute; 
      right: 16px; 
      top: 16px;
      z-index: 1;
      cursor: pointer;
      @media(max-width:991px) {
         right: 8px; 
         top: 8px;
       }
  
       @media(max-width:767px) {
          top: 8px;
          top: 8px;
       }
    }
  }
  
}


// GF Hotels

@mixin gf-primary-btn-small-one {
  font-family: $az-heading-para;
  background-color: $gf-primary-color !important;
  color: $az-white;
  font-size: $az-font-16;
  border-radius: 8px;
  font-weight: 500;
  border: none !important;
  padding: 9px 15px 6px;
  // min-width: 155px;
  // max-width: 155px;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  height: 40px;
  img{
    padding-right: 9px;
    vertical-align: baseline;
    // filter: invert(10) contrast(5);
    
  }
  &:focus,  &:active {
    box-shadow: none ;
    outline: none;
    border:none;
    background-color: $gf-primary-color!important;
  } &:hover  {
    box-shadow: none ;
    outline: none;
    border:none;
    background-color: $az-gray !important;
  }
}
@mixin gf-primary-btn-small-two {
  font-family: $az-heading-para;
  // background-color: $gf-md-black !important;
  // color: $az-white;

  background: $gf-md-black-secondary;
  color: $az-white;
  letter-spacing: 1.2px;

  font-size: $az-font-16;
  border-radius: 8px;
  font-weight: 500;
  border: none !important;
  padding: 9px 15px 6px;
  // min-width: 114px;
  // max-width: 114px;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: 40px;
  img{
    padding-right: 5px;
    vertical-align: baseline;
    height: 18px;
    position: relative;
    bottom: 2px;
  }
  &:focus, &:active,  &:hover,  &:visited  {
    box-shadow: none ;
    outline: none;
    border:none;
    background-color: $az-gray ;
  }
  
}


@mixin gf-btn-lg-outline {
  font-family: $az-heading-para-light;
  background: $gf-md-black-secondary;
  color: $az-white;
  font-size: $az-font-16;
  border-radius: 8px;
  font-weight: 500;
  border: none !important;
  padding: 9px 15px 6px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  height: 40px;
  img{
    padding-right: 0px;
    vertical-align: baseline;
    filter: contrast(2);
    margin-right: 8px;
  }
  &:focus,  &:hover,  &:active {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: $az-gray;
  }
  
  
  @media (max-width: 320px) {
    min-width: 137px;
    max-width: 137px;
    margin: 5px 0 !important;
  }

}

@mixin gf-btn-lg-stroke {
  font-family: $az-heading-para-light;
  background: $az-solid-brown;
  color: $az-white;
  font-size: $az-font-16;
  border-radius: 8px;
  font-weight: 500;
  border: none !important;
  padding: 9px 15px 6px;
  min-width: 145px;
  max-width: 145px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  height: 40px;
  img{
    padding-right: 0px;
    vertical-align: baseline;
    filter: contrast(2);
    margin-right: 8px;
  }
  &:focus {
    border: none !important;
    box-shadow: none;
    outline: none;
    background-color: none;
  }
  &:hover {
    border: none !important;
    box-shadow: none;
    outline: none;
    background: $az-gray;
    color: $az-white;

    img {
      filter: contrast(2);
    }
  }
  &:active {
    border: none !important;
    box-shadow: none;
    outline: none;
    background-color: none;
  }
  @media (max-width: 320px) {
    min-width: 137px;
    max-width: 137px;
    margin:5px  0 !important;
  }

}

@mixin detlet-text {
  color: $az-delele-text;
  font-family: $az-heading-two;
  font-size: $az-font-14;
  text-transform: uppercase;
}


@mixin add {
  
  background: none;
  border: none;
  outline: none;
  box-shadow: none;

  &:hover, &:active, &:focus {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
}

@mixin delete {
  
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  
  &:hover, &:active, &:focus {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
}

@mixin tableFormatesimple {

  thead {
    th {
      border-bottom: 1px solid $az-border-md;
    }
  }

  caption {
    font-family: $az-heading-two;
    text-transform: uppercase;
    font-size: $az-font-14;
    color: $gf-input-label-color;
    font-weight: normal;
    background: $az-xl-light-white;
    width: 100%;
    padding: 10px 15px;
    display: block;
  }
  
  th {
    border-top: 0;
    font-family: $az-heading-two;
    text-transform: uppercase;
    font-size: $az-font-14;
    color: $gf-input-label-color;
    font-weight: normal;
    vertical-align: inherit;
    padding: 20px 15px;
    border-bottom: 1px solid $az-xl-light-white;
    border-left: 1px solid $az-xl-light-white;
    border-right: 1px solid $az-xl-light-white;
    &:first-child {
      border-left: 0;
      
    }
    &:last-child {
      border-right: 0;
     
     
    }
  }
  td {
    border-top: 0;
    vertical-align: inherit;
    padding: 20px 15px;
    border-bottom: 1px solid $az-xl-light-white;
    border-left: 1px solid $az-xl-light-white;
    border-right: 1px solid $az-xl-light-white;

    &:first-child {
      border-left: 0;
     
    }
    &:last-child {
      border-right: 0;
     
    }
  }

  .az-inspection-red {
       color: $az-inspection-red;
  }
  .az-inspection-blue {
       color: $az-inspection-blue;
  }
  .az-inspection-black {
      color: $az-inspection-black;
  }
  .az-inspection-brown {
    color: $az-inspection-brown;
    code {
      color: $az-inspection-brown;
      font-size: $az-font-14;
      font-family: $az-heading-two;
    }
  }

  tr {
    background-color:#fff !important ;
  }

}





@media (max-width:1440px){
  table thead tr th{
    padding-left: 16px !important;
    &:first-child{
      padding-left: 0px !important;
    }
  }
  .table tbody tr td {
    padding-left: 16px !important;
    &:first-child{
      padding-left: 7px !important;
    }
  }
  .mro_btn{
    margin-right: -15px !important;
  }
  .dash_heading{
    // padding: 35px 0 20px 0 !important;
    margin: 0;
    font-size: 30px;
}
.brandList .asrt-table-body table thead th{
  padding: 14px !important;
}

.month_width table tr th{
  width:70px !important;
}

.month_width table tr th{
  width: 70px !important;
}



}

/* ----------- Non-Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .brandSection .order_form h3{
      font-size: 20px !important;
      margin-bottom: 20px !important;
    }
    .order_form .form-label {
      font-size: 14px !important;
      margin-top: 10px;
  }
    .gf-primary-btn-small-one{
      font-size: 15px !important;
      padding: 7px 13px 3px !important;
      height: 40px !important;
    }
    .gf-primary-btn-small-two{
      font-size: 15px !important;
      padding: 7px 15px 6px !important;
      height: 40px !important;
    }
    // .az-header-menu .nav-item > .nav-link{
    //   font-size: 15px !important;
    // }
    .az-header-menu .media .media-body p{
      font-size: 15px !important;
    }
    .az-rightbar .gf-btn-lg-outline{
      font-size: 15px !important;
    }
    .az-rightbar .gf-btn-lg-stroke{
      font-size: 15px !important;
    }
    .modal .modal-dialog .modal-content button.btn-outline-light{
      font-size: 15px !important;
      padding: 7px 15px 6px !important;
      height: 40px !important;
    }
    .modal .modal-dialog .modal-content button.btn-success{
      font-size: 15px !important;
      padding: 7px 15px 6px !important;
      height: 40px !important;
    }
    .modal .modal-dialog .modal-content button.btn-danger{
      font-size: 15px !important;
      padding: 7px 15px 6px !important;
      height: 40px !important;
    }
    .dashbaord-pageHeadertitle h3{
      font-size: 20px !important;
    }
    .dashbaord-pageHeadertitle h5{
      font-size: 20px !important;
    }
    .brandList .asrt-table-body table tbody tr td{
      font-size: 15px !important;
    }
    input {
      font-size: 15px !important;
      height: 28px !important;
    }
    select{
      font-size: 15px !important;
      height: 28px !important;
    }
    .css-2b097c-container .css-1pahdxg-control{
      font-size: 15px;
    }
    .css-voyisq-option {
      font-size: 15px !important;
  }
  .css-1qoqow7-option {
    font-size: 15px !important;
}
    .css-yk16xz-control{
      font-size: 15px !important;
    }
    .brandSection .order_form .back-size{
      font-size: 15px !important;
    }
    .az-toggle {
      width: 47px !important;
      height: 20px !important;
    }
    .az-toggle.on span {
      left: 30px !important;
  }
  .az-toggle span{
    width: 14px !important;
  }
    .brandSection .order_form .back-size img{
      width: 13px;
      padding-top: 2px;
    }
    .brandSection .order_form .btn-success-outline{
      font-size: 15px !important;
      height: 40px !important;
    }
    .mg-tneg-6 {
      margin-top: -10px !important;
    }
    .brandSection .order_form .btn-success{
       font-size: 15px !important;
       height: 40px !important;
    }
    .scrolling-carousel .styles-module_sliderBase__swkx1 .styles-module_slider__o0fqa .swipesection .nav-pills .nav-item a{
      font-size: 15px !important;
    }
    .css-yk16xz-control{
      min-height: 40px !important;
    }
    .css-yk16xz-control input{
      font-size: 15px !important;
    }
    .css-2b097c-container .css-yk16xz-control{
      height: 40px !important;
    }
    .css-g1d714-ValueContainer{
      padding: 0 8px !important;
    }
    .mg-t-18 {
      margin-top: 11px !important;
    }
    .dash_icons {
      float: right;
      width: 120px !important;
  }
}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
    .mro_btn{
      margin: 0 !important;
    }
 
}
.adminHeadertop{
  display: none;
}


@media  screen and (max-width:1366px) {
  .dash_heading{
    // padding: 35px 0 20px 0 !important;
    margin: 0;
    font-size: 30px;
}
.card-dashboard-ratio .card-icon {
  font-size: 50px;
}
.card-dashboard-ratio {
  height: 185px!important;
}
.month_width table tr th{
  width: 70px !important;
}

.month_width table tr th{
  width: 70px !important;
}



}

@media  screen and (max-width:1280px) {
  .dash_heading{
    // padding:45px 0 15px 0 !important;
    margin: 0;
    font-size: 26px;
}
.card-dashboard-ratio .card-icon {
  font-size: 50px;
}
.card-dashboard-ratio {
  height: 185px!important;
}

input[type=file]::file-selector-button {
  font-size:16px !important;
  padding:0 10px !important;
  overflow: hidden;
  height: 40px !important
 }

 .month_width table tr th{
  width: 60px !important;
}

.month_width table tr th{
  width: 60px !important;
}
.month_width table tr td:first-child{
  width: auto !important;
}

.month_width table tr th:first-child{
  width: auto !important;
}




}

@media screen and (max-width:1024px) {
    .scrolling-carousel .btnNavigate  .gf-primary-btn-small-one{
      position: relative;
      top: -50px;
    }
}

@media screen and (max-width:1050px) {
  .scrolling-carousel .styles-module_sliderBase__swkx1 {
    width: 100% !important;
}
}

@media screen and (max-width:950px) {
  .adminHeadertop{
    display: block ;
  }
  .adminHeadertop .rightNavbar{
    display: none;
  }

  .adminHeadertop{
    background: #fff !important;

  }
  .adminHeadertop img{
    position: absolute;
    right: 0;
    width: 16%;
    background: #fff;
    padding: 10px;
  }
  .az-header-menu > .nav{
    padding: 0 !important;
    padding-top: 20px !important;
  }
}

@media  screen and (max-width:600px) {
  .authentication .az-card-signin{
    width: 100% !important;
  }
  .adminHeadertop img{
    width:33%;
  }
  .tracker_btn_new .gf-primary-btn-small-two{
    position: absolute;
    top: -35px;
    right: 0;
  }
  .scrolling-carousel .dashboardRightcard {
    padding: 30px 13px;
    margin-top: 10px;
}
  .excluded_box p{
    display: flex;
    left: 5px;
    position: relative;
  }
    #register_from .pd-r-70 {
      padding-right: 0px !important;
  }
  .dashboardRightcard .pd-r-70 {
    padding-right: 0px !important;
}
  #register_from  .pd-l-70 {
      padding-left: 0px !important;
  }

  .dashboardRightcard  .pd-l-70 {
    padding-left: 0px !important;
}
.pd-l-140 {
  padding-left: 0px !important;
}

.pd-r-140 {
  padding-right: 0 !important;
}
.padding-right-100 {
  padding-right: 0 !important;
}
  #register_from .pd-l-80 {
    padding-left: 0px;
  }

  #register_from .pd-r-80 {
    padding-right: 0px;
  }
 
  .dash_heading {
    // padding: 24px 0 0px 0;
    margin: 0;
    font-size: 24px;
}
.card-dashboard-ratio .card-body h2, .card-dashboard-ratio .nav-tabs + .tab-content h2 {
  font-size: 24px;
}
  .dashbaord-pageHeadertitle .btnNavigate{
    display: block !important;
    width: 100%;
  }

  .dashbaord-pageHeadertitle .d-flex {
    display: block !important;
  }
  .btnNavigate{
    display: block !important;
  }
  .az-rightbar .gf-primary-btn-small-one{
    margin: 5px 0;
  }
  .mg-l-7 {
    margin-left: 0 !important;
}
}

.toploader img{
  fill:red
}

.scrolling-carousel .styles-module_sliderBase__swkx1 div[data-arrow=right]{
  background: transparent !important; 
}



@media screen and (max-width:600px) {
  .brandSection .order_form {
    height: auto;
  }
  .pagination {
    width: 100%;
    overflow: auto;
    float: left;
}
.dashbaord-pageHeadertitle h3 {
  font-size: 19px;
  margin-bottom: 20px;
}
.dashboardRightcard .as-react-table .asrt-table-head .col-md-6:first-child{
  margin-bottom: 10px;
  text-align: center !important;
}
.dashbaord-pageHeadertitle h3 {
  font-size: 20px !important;
}
.pd-l-80 {
   padding-left: 0px !important;  
}
.pd-r-80 {
  padding-right: 0px !important;
}

.detlet-text{
  display: inherit;
  padding-top: 20px !important;
}
.btnNavigate .d-flex{
  display: block !important;
}
.az-rightbar .gf-btn-lg-outline{
  margin-bottom: 10px !important;
}

.excluded_icon {
  padding: 9px 12px !important;
  border-radius: 5px;
  font-size: 16px;
  margin-right:10px ;

}

}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{
  background: #272E79 !important;
}

.az-header-menu-header{
  display: block !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
  top: 8px;
  border-width:4px;
}

.spinner-border{
  position: relative;
  bottom: 3px;
  border: 0.25em solid #fff ;
  border-right-color: transparent;
}

.css-1uccc91-singleValue{
  top: 65%;
}

.az-rightbar .addCat{
  padding-left: 9px;
}

.wid_50{
  width: 50%;
}

.pdf_gen:hover{
  background: gray !important;
} 
.pdf_gen:focus{
  background: #333333 !important;
} 


.wid_25{
  width: 25.2%;
}

.css-1wa3eu0-placeholder{
  margin-left: 0 !important;
}
.css-yk16xz-control input{
  width: 2px !important;
}

.btn-primary:not(:disabled):not(.disabled):active{
  background-color: #333333 !important;
}

.select_width{
  width: 300px;
}

.az-rightbar .gf-primary-btn-small-two:focus{
  background-color: #333333 !important;
}

.btn-info:not(:disabled):not(.disabled):active:focus{
  box-shadow: none !important;
}

.btn-success:not(:disabled):not(.disabled):active:focus{
  box-shadow: none !important;
}

.az-rightbar .gf-primary-btn-small-two:hover{
  background-color: gray !important;
}