/* ###### 8.3 Dashboard Three  ###### */

.az-content-dashboard-three {
  position: relative;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fdfdfd;

  @include media-breakpoint-up(lg) {
    border-left: 1px solid $gray-300;
    margin-left: $az-height-header;
  }
}

.az-header-dashboard-three {
  height: auto;

  .container-fluid {
    padding: 15px 20px;
  }

  .az-header-left {
    @include media-breakpoint-up(lg) { display: none; }
  }

  .az-header-center { margin-left: 0; }
  .az-header-right { margin-left: auto; }
}

.az-content-body-dashboard-three {
  padding: 20px;
  position: relative;
  width: 100%;

  .az-content-title { letter-spacing: -.5px; }
}

.card-dashboard-nine {
  border-color: $gray-300;
  background-color: rgba(#fff, .5);
  position: relative;
  padding: 20px 20px 15px;

  .card-header {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    padding: 0;
    background-color: transparent;
    z-index: 5;

    h1 {
      font-size: 32px;
      font-family: 'Archivo', $font-family-base;
      margin-bottom: 8px;
      line-height: 1;
      letter-spacing: -.5px;
      color: $gray-900;
    }
  }

  .az-content-label {
    font-size: 13px;
    margin-bottom: 10px;
    color: $body-color;
  }

  .card-title { font-weight: 600; }

  .az-content-text { color: $gray-600; }

  .bar-chart {
    position: relative;
    width: auto;
    height: 200px;

    @include media-breakpoint-up(lg) { height: 210px; }
  }
}

.card-dashboard-ten {
  border-width: 0;
  padding: 20px;

  @include media-breakpoint-up(sm) {
    width: calc(50% - 11px);
    display: inline-block;
  }

  @include media-breakpoint-up(lg) {
    width: auto;
    display: block;
  }

  + .card-dashboard-ten {
    margin-top: 20px;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
      margin-left: 18px;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .az-content-label {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1.05;
    color: #fff;
  }

  .card-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      flex: 1;
      @include media-breakpoint-up(lg) { flex: none; }
      @media (min-width: 1360px) {
        &:first-child { flex-basis: 60%; }
        &:last-child { flex-basis: 40%; }
      }

      label {
        font-size: 13px;
        margin-bottom: 0;
        color: rgba(#fff, .75);
      }

      h6 {
        margin-bottom: 2px;
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        line-height: .975;
        font-family: 'Archivo', $font-family-base;

        @include media-breakpoint-up(lg) { font-size: 24px; }

        span { font-size: 13px; }
        .percent { font-size: 20px; }
      }
    }
  }
}

.card-dashboard-eleven {
  border-color: $gray-300;
  position: relative;
  overflow: hidden;

  .card-header {
    padding: 20px;
    background-color: transparent;
    position: relative;
    z-index: 200;
  }

  .az-content-label {
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
    color: $gray-800;
    line-height: 1.4;
  }

  .card-text {
    color: $gray-600;
  }

  .card-body {
    padding: 0 0 5px;
    margin-left: 20px;
    position: relative;
    z-index: 200;
  }

  .flot-chart {
    width: 100%;
    height: 250px;
  }

  .card-footer {
    z-index: 200;
    background-color: transparent;
    padding: 0;
    border-width: 0;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: stretch;
    }

    > div {
      flex: 1;
      padding: 15px 20px;
      border-top: 1px solid $gray-300;
      position: relative;

      + div {
        @include media-breakpoint-up(md) { border-left: 1px solid $gray-300; }
      }
    }

    label {
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: $gray-700;
      display: block;
    }

    h6 {
      font-size: 28px;
      font-weight: 600;
      font-family: 'Archivo', $font-family-base;
      color: $gray-900;
      line-height: .7;
      letter-spacing: -1px;
      margin-bottom: 10px;
      display: flex;
      align-items: baseline;

      span {
        margin-left: 5px;
        font-size: 11px;
        font-weight: 500;
        display: block;
        padding: 4px 6px;
        color: #fff;
        line-height: .8;
        position: relative;
        bottom: 3px;
        letter-spacing: normal;

        &.up { background-color: $green; }
        &.down { background-color: $red; }
      }
    }

    small {
      display: block;
      font-size: 13px;
      color: $gray-700;
    }
  }

  .jqvmap {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 300px;

    @include media-breakpoint-up(lg) { height: 80%; }
  }
}
