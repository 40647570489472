main { @include dashboard-background; }
.mg-t-46 { margin-top: $az-dash-mt;}
aside { @include aside;}
.accordion{ @include accordion-nav;}

table{ @include mastertable;}
button.btn-block {
    @include add-button;
  }

span.text-danger {
color: $az-black !important;
}





