/* ###### 3.10 Grid ###### */

.row-sm {
  margin-left: -10px;
  margin-right: -10px;

  > div {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row-xs {
  margin-left: -5px;
  margin-right: -5px;

  > div {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@mixin create-row($padding) {
  margin-left: -$padding;
  margin-right: -$padding;

  > div {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@include media-breakpoint-up(sm) {
  .row-xs--sm { @include create-row(5px); }
  .row-sm--sm { @include create-row(10px); }
  .row--sm { @include create-row(15px); }
}

@include media-breakpoint-up(md) {
  .row-xs--md { @include create-row(5px); }
  .row-sm--md { @include create-row(10px); }
  .row--md { @include create-row(15px); }
}

@include media-breakpoint-up(lg) {
  .row-xs--lg { @include create-row(5px); }
  .row-sm--lg { @include create-row(10px); }
  .row--lg { @include create-row(15px); }
}

@include media-breakpoint-up(xl) {
  .row-xs--xl { @include create-row(5px); }
  .row-sm--xl { @include create-row(10px); }
  .row--xl { @include create-row(15px); }
}
