aside {
  text-align: left;
  font-family: $az-heading-para-light;
  background: none;
  .topHead {
    padding: 14px 20px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    background: $az-white;
    border-radius: 8px;
    margin-bottom: 22px;
    .az-img-user {
      width: 42px;
      height: 42px;
      &::after {
        right: 0px;
        top: 5px;
        display: none;
      }
    }
    .propname {
      margin-left: 8px;
      h5 {
        margin: 0;
        font-size: $az-font-16 !important;
        color: $az-black;
        letter-spacing: 0.19px;
        font-family: $az-heading-two;
      }
      ul {
        margin: 0;
        list-style: none;
        display: inline-flex;
        padding: 0;
        align-items: center;
        li {
          font-family: $az-heading-two;
          font-size: $az-font-14 !important;
          color: $az-gray;

          &:nth-child(2) {
            margin: 0 9px;
            background: $az-gray;
            width: 4px;
            height: 4px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .card {
    background: none;
    margin-bottom: 12px !important;
    .card-header {
      button {
        font-size: $az-font-16 !important;
        letter-spacing: 0.32px;

        i {
          font-size: $az-font-16;
        }
      }
    }
    .collapse {
      margin-top: 9px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      background: $az-white;
      border-radius: 8px;
      .card-body {
        border: none;
        padding-top: 24px;
        .nav-link {
          font-size: $az-font-16 !important;
          font-weight: normal;
          padding: 14px 34px;
          font-family: $az-heading-two;
          &:focus {
            color: $az-white;
          }
        }
      }
    }
    .collapsing {
      margin-top: 9px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      background: $az-white;
      border-radius: 8px;
      .card-body {
        border: none;
        padding-top: 24px;
        .nav-link {
          font-size: $az-font-16 !important;
          font-weight: normal;
          padding: 14px 34px;
          font-family: $az-heading-two;
          &:focus {
            color: $az-purple;
          }
        }
      }
    }
  }
}

.scrolling-carousel {
  @include scrolling-carousel-swiper;
  .btn-success-outline-small {
    @include btn-success-outline-small;
  }
  .tab-content {
    margin-top: 23px !important;
    .btn-success-outline-small {
      @include btn-success-outline-small;
    }
    .demo-avatar-group {
      @media (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center !important;
      }

      .avatar-md {
        width: 138px;
        height: 138px;

        &:after {
          width: 14px;
          height: 14px;
          right: 20px;
          top: 8px;
          display: none;
        }
      }
      .avatar-round {
        margin-right: 186px;
        @media (max-width: 767px) {
          margin: 30px auto;
          width: 100%;
          text-align: center;
        }

        figure {
          margin: 0;
          width: 138px;
          height: 138px;
          border-radius: 50%;
          overflow: hidden;
          @media (max-width: 767px) {
            margin: auto;
          }
          img {
            max-width: 100%;
            width: 138px;
            height: 138px;
            object-fit: cover;
          }
        }
      }
      .avatar-squre {
        @media (max-width: 767px) {
          margin: auto;
          width: 100%;
          text-align: center;
        }

        figure {
          margin: 0;
          width: 138px;
          height: 138px;
          border-radius: 8px;
          overflow: hidden;
          @media (max-width: 767px) {
            margin: auto;
          }
          img {
            max-width: 100%;
            width: 138px;
            height: 138px;
            object-fit: cover;
          }
        }
      }

      b {
        @include inactivestatus;
      }
      b.active {
        @include activestatus;
      }
    }
    .avatar-squre-listing {
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      a.overlay {
        figure {
          margin: 0;
          width: 100%;
          height: 316px;
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          img {
            max-width: 100%;
            width: 304px;
            height: 316px;
            object-fit: cover;
          }

          &:hover {
            span {
              height: 100%;
              width: 100%;
              position: absolute;
              background: rgba(0, 0, 0, 0.6);
              z-index: 1;
              left: 0;
              top: 0;
              display: block;
              opacity: 1;
              img {
                width: 24px;
                height: 24px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

          span {
            height: 100%;
            width: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.6);
            z-index: 1;
            left: 0;
            top: 0;
            display: block;
            opacity: 0;
            img {
              width: 24px;
              height: 24px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
  .dashboardRightcard {
    padding: 46px 36px;
    margin-top: 10px;
    .propname {
      margin-left: 15px;
      margin-bottom: 32px;
      h5 {
        margin: 0;
        font-size: $az-font-18 !important;
        color: $az-black;
        letter-spacing: 0.19px;
        font-family: $az-heading-one;
      }
      ul {
        margin: 0;
        list-style: none;
        display: inline-flex;
        padding: 0;
        align-items: center;
        li {
          font-family: $az-heading-two;
          font-size: $az-font-14 !important;
          color: $az-gray;
          &:first-child {
            color: $az-gray;
          }
          &:nth-child(2) {
            margin: 0 9px;
            background: $az-gray;
            width: 4px;
            height: 4px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.propSection {
  h3 {
    font-family: $az-heading-one;
    color: $az-black;
    font-size: $az-font-24;
    text-align: left;
    margin-bottom: 22px;
  }
  .dashboardRightcard {
    padding: 24px 34px;
    margin-top: 22px;
    .btn-success-outline-small {
      @include btn-success-outline-small;
      font-size: 18px;
      padding: 8px 15px;
    }
  }
}

.aminitesSection {
  .dashboardRightcard {
    padding: 24px 6px;
    hr {
      width: 100%;
      max-width: 100%;
    }
    h5 {
      margin: 0;
      font-size: $az-font-16;
      letter-spacing: 0.19px;
      font-family: $az-heading-two;
      color: $az-gray;
    }
    @include aminitiescard;
  }
}

.ck-editor {
  .ck-editor__top {
    .ck-toolbar_grouping {
      border: 1px solid $az-border-color !important;
      border-bottom: none !important;
    }
  }
  .ck-editor__main {
    .ck-editor__editable {
      border: 1px solid $az-border-color !important;
    }
  }
}

.az-rightbar {
  .gf-primary-btn-small-one {
    @include gf-primary-btn-small-one;
  }
  .gf-primary-btn-small-two {
    @include gf-primary-btn-small-two;
  }

  .gf-btn-lg-outline {
    @include gf-btn-lg-outline;
  }
  .gf-btn-lg-stroke {
    @include gf-btn-lg-stroke;
  }
  .btn-danger-outline {
    @include btn-danger-outline;
  }
  .detlet-text {
    @include detlet-text;
  }
  button.detlet-text {
    @include detlet-text;
    border: none;
    background: none;
    box-shadow: none;
    outline: none;
    &:hover,
    &:active,
    &:focus {
      border: none;
      background: none;
      box-shadow: none;
      outline: none;
    }
    &:first-child {
      border-right: 1px solid #ccc;
      padding-right: 25px;
    }
    &:last-child {
      border-right: 0px;
      // padding-left: 25px;
    }
  }
  .building-btn {
    margin: -57px 0 23px 0;
  }

  .addCat {
    @include add;
  }
  .deleteCat {
    @include add;
    img {
      width: 20px;
    }
  }
  p {
    font-family: $az-heading-one-rg;
    color: $gf-md-black;
  }

  .tableFormatesimple {
    @include tableFormatesimple;
  }
}
.back-size {

  color: $az-solid-brown ;
  //font-family: $az-heading-two;
  font-family: $az-heading-para;
  font-size: $az-font-16 ;
  &:hover{color: #333;}
  img{
    fill: #808080 !important ; 
    width: 14px;
    padding-top: 1px;
    &:hover{fill: #333 !important;}
  }

}
.order_form .form-label {
  font-family: $az-heading-two;
  font-size: $az-font-15 ;
  margin-top: 15px;
  // &:first-child{
  //   margin-top: 0;
  // }
}

.dashboardRightcard {
  .as-react-table {
    .asrt-table-head {
      display: flex;
      flex-direction: row-reverse;
      .col-md-6 {
        &:first-child {
          text-align: right !important;
        }
        &:last-child {
          text-align: left !important;
        }
      }
    }
    .asrt-table-body {
      overflow-x: auto;
    }

  }
}
.prp_mb-10{
  margin-bottom: 10px!important;
}

.mt-top-7neg{
  margin-top: -7px !important;
}

.css-1wa3eu0-placeholder{
  color: #000 !important;
}
.css-2b097c-container span{
  height: 0 !important;
}

.brandSection .order_form input::placeholder{
  color: #000 !important;
}

input:read-only{
  color:#000 !important;
}


.css-62g3xt-dummyInput{
  max-height: 0!important;
}

input::placeholder{
  color: #000 !important;
}
